import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../style.css';


const ErrorPage = () => {
    useEffect(() => {
        document.title = 'Page Not Found | ServiceAreaHub';
    }, []);
    const navigate = useNavigate();
    const navigation = (url) => {
        window.scrollTo(0, 0);
        if (url === "home"){
            navigate("/");
        }
        else if (url === "contact"){
            navigate("/contact");
        }
    }
    return(
        <div>
            {/* Error */}
            <section className="page-section bg-light" id="error">
                <div className="container">
                    <div className="text-center">
                        {/* <h2 className="section-heading text-uppercase sectionHead"> */}
                        <h2 className="section-heading sectionHead">
                            Page Not Found {' '}
                        </h2>
                        <span style={{fontSize: "30px", fontWeight: "bold"}}>Sorry, the page you tried cannot be found.</span>
                        <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                            <p>
                                The requested page could not be found. It often occurs due to mistyped URLs, moved, or deleted pages. A custom 404 page improves user experience by guiding visitors back to your site, maintaining brand consistency, and reducing frustration. Key elements include a clear message, navigation options, consistent design, and contact information. Optionally, add humor or creativity to make it memorable. This enhances user engagement and aids SEO by keeping visitors on your site. An effective 404 page turns a potential setback into a positive interaction with your brand.
                            </p>
                        </h3>
                    </div>
                    <div>
                        <ul className="custom-list" style={{textAlign: 'left', fontSize: 20}}>
                            <li>We have redesigned our web site and many URLs have changed. You can go to our Home Page to find the topic you are looking for, or use the site search or the site map to find the topic you are interested in.</li>
                            <br/>
                            <li>In case you have typed the URL, please check if the spelling is correct.</li>
                            <br/>
                            <li>If you suspect a site problem, kindly report the problem. We appreciate your support.</li>
                            <br/>
                            <li 
                                style={{color: '#bf0a30', cursor: 'pointer', fontWeight: 'bold'}}
                                onClick={() => navigation("home")}
                            >
                                    Home
                                
                            </li>
                            <br/>
                            <li 
                                style={{color: '#bf0a30', cursor: 'pointer', fontWeight: 'bold'}}
                                onClick={() => navigation("contact")}
                            >
                                    Contact us
                                
                            </li>
                            <br/>
                            <li style={{color: '#bf0a30'}}>
                                <a 
                                    href="mailto:support@serviceareahub.com?subject=Enquiry%20for%20services&body=Dear%20SAH%20team,%0D%0A%0D%0AI%20would%20like%20to%20avail%20some%20services%20from%20ServiceAreaHub%20regarding...%0D%0A%0D%0AThanks%20with%20regards,%0D%0A" 
                                    style={{color: '#bf0a30', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none'}}
                                    // className="contactEmail"
                                >
                                    Write to us
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ErrorPage;