import React, {useEffect } from "react";
import Navbar from "./navbar";
import Blog1Img from "../assets/blog1Img.jpg";
import Footer from "../footer";
import "./blogstyle.css";

const Blog1 = () => {
    useEffect(() => {
        document.title = 'How to Start a Business - Blogs | ServiceAreaHub';
    }, []);
    return(
        <div>
            <Navbar/>
            <div className="blogBody">
                <h1 className="blogHead">
                    How to Start a Business
                </h1>
                <img src={Blog1Img} alt="" className="blogImg"/>
                <div className="blogMeterial">
                    <div>
                        We need to research in a few different fields, like what you are good at, what is your aspiration, what is your hobby, what is your passion, or what you want to be in life. One thing to keep in mind that in any field you can start a business and you can earn money easily from it, just you need to know the business model, and the way to earn money from that field.
                    </div>
                    <div>
                        Any business has two phase, loss and gain. No business has only gain or only loss. So, you need to maintain consistency even if you experience a good loss, cause it’s a part of business, you shouldn’t run away.
                    </div>
                    <div>
                        Now if you are okay with all these, then it’s time to discuss about a few points to start a Start-up:
                    </div>
                    <br/>
                    <br/>
                    <p>
                        <b>1. Self-realization/observation:{" "}</b>
                        At first try to find out all your likes and dislikes. Then figure out what are those two to three things you like the most and which won’t hamper your ethics. That’s a very important cause if it’s needed to go against your ethics, it may affect your business. Now just choose any one to item which will be most appropriate for you to go with.
                    </p>
                    <p>
                        <b>2. Make a business model:{" "}</b>
                        Now if you have determined which type of business you want to start, you need to understand your business model. Check out all the other existing business of same type, how they are dealing with customers and how they are making money of it. Understand their business model if you can’t make of your own. You may understand their model and try to build a bit different and advance one if possible. By doing so, you may attract more customers.
                    </p>
                    <p>
                        <b>3. Start to check:{" "}</b>
                        After building your business model, start your business in a small scale, just to check its going good or not. If it’s not, try to understand where the problem is? After figuring out the problem, try to solve it and relaunch it, with a rectified model. The model may be wrong but you need to maintain consistency. Many people think business is not for them because they are experiencing a huge loss. The people who take the loss, learn from it and come back with a right strategy, those people can achieve success in business.
                    </p>
                    <p>
                        <b>4. Registration:{" "}</b>
                        If you feel the business is running good in the test period, then you check with the companies who help to register your company. Check that which company is coming up with the best deal to register your company, or you may apply directly of your own.
                    </p>
                    <p>
                        <b>5. Advertising:{" "}</b>
                        Advertising plays a vital roll to grow your business. Try to reach out customers as much as you can, with the help of various media. There are various medias like newspapers, television, online etc. Now a days people are using digital marketing which refers to a type of marketing where a company sells and promote its products via various online channels, on the other hand affiliate marketing involves promoting other businesses’ products in exchange for a cut of sales. You may also opt for Targeted marketing which helps you to reach your targeted customers in an efficient way.
                    </p>
                    <p>
                        <b>6. Attract customers:{" "}</b>
                        Attract customers by means of giving offers and various other methods. If you. Customers should be treated in such a way that they should return to you after they purchase a product from you. After sales service should be great.
                    </p>
                    <p>
                        <b>7. Targeted customers:{" "}</b>
                        Make some targeted customer, like if you are targeting low budget customers build value for money product, if you are targeting high end customers then build premium and uncompromised product. Build product for targeted customers.
                    </p>
                    <p>
                        <b>8. Building brand:{" "}</b>
                        Don’t let your customers to shift to another brand other than you. Show case your brand as a premium one, add value to your brand. Treat it as it’s the best one at that range, and people who are choosing your brand make them feel the premium.
                    </p>
                    <p>
                        <b>9. Choice:{" "}</b>
                        Creating a brand is the last thing to build about a business, it will move smoothly from that point. Now you have two options, either maintaining the position and make it better, or along with this you may start another business just by following the same simple steps. If you want to build another business, make sure your previous businesses are running well, and you have enough time to build up your new business.
                    </p>
                    <p>
                        <b>Conclusion:{" "}</b>
                        Never focus on earning, focus on serving people, you can earn automatically. Your business model should be proper. Reach people but make sure customers are not getting disturb by your approach. Never stop improving your service to your client or customer. Reach out your customers and try to get make a note on the problem they are facing, and work on those issues. If you can make those things improve no one can stop you from achieving success.
                    </p>
                </div>
                </div>
            <Footer/>
        </div>
    )
};

export default Blog1;