import React, { useState, useRef, useEffect } from "react";
import "./careersstyle.css";
import "../home/homestyle.css";
// import styled from 'styled-components';

const FullTimerCareerForm = () => {
    const [skill, setSkill] = useState("");
    const [location, setLocation] = useState("");
    const [chkOpeningMsg, setChkOpeningMsg] = useState("");

    // Use refs to access the input elements
    const skillRef = useRef("");
    const locationRef = useRef("");
    const msgRef = useRef("");

    useEffect(() => {
        
        if ((skill === "") || (location === "")) {
            setChkOpeningMsg('');
        }
    }, [skill, location]);

    const checkOpening = (e) => {
        e.preventDefault();

        // Handle form submission logic
        if((skill !== "") && (location !== "")){
            setChkOpeningMsg("Sorry, there is no match for the selected post at your location.");
            msgRef.current.focus();
        }
        else if((skill === "") && (location !== "")){
            skillRef.current.focus();
        }
        else if((skill !== "") && (location === "")){
            locationRef.current.focus();
        }
        else{
            skillRef.current.focus();
        }
      };

    return(
        <div className="formBody">
            <div className="formHead">
                Explore Openings
            </div>
            <div>
                <form id="checkOpeningForm" style={{marginTop: "50px"}} data-sb-form-api-token="API_TOKEN">
                    <div className="row align-items-stretch mb-5">
                        <div className="col-md-6">
                            <div className="form-group">
                                {/* Skill or keyword */}
                                <input 
                                    className="form-control" 
                                    id="skill" type="text" 
                                    placeholder="Search for jobs by skill or keyword"  
                                    data-sb-validations="required" 
                                    required 
                                    value={skill} 
                                    onChange={(e) => {
                                        setSkill(e.target.value);
                                        setChkOpeningMsg('');
                                    }} 
                                    ref={skillRef}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 domain-message-div">
                            <div className="form-group">
                                {/* Location */}
                                <input 
                                    className="form-control" 
                                    id="location" type="text"  
                                    placeholder="Location" 
                                    required value={location} 
                                    onChange={(e) => {
                                        setLocation(e.target.value); 
                                        setChkOpeningMsg('');
                                    }} 
                                    ref={locationRef} />
                            </div>
                        </div>
                    </div>

                    <div style={{color: '#bf0a30', fontWeight: 'bold', fontSize: '20px'}} ref={msgRef}>
                        {chkOpeningMsg}
                    </div>
                    <br/>
                    <br/>

                    <div className="text-center">
                        <button 
                            style={{
                                backgroundColor: "#bf0a30", 
                                borderColor: "transparent", 
                                width: "170px", 
                                height: "50px", 
                                borderRadius: "10px"
                            }} 
                            className="btn btn-primary text-uppercase" 
                            id="formSubmitButton"
                            // disabled={formSubmitBtn}
                            onClick={checkOpening}
                        >
                            Search
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FullTimerCareerForm;