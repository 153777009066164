import React, { useEffect, useRef } from "react";
import "./templetstyle.css";

function Template() {
  const categoriesRef = useRef([]);

  useEffect(() => {
    const isTouchDevice = 'ontouchstart' in document.documentElement;
    if (isTouchDevice) {
      document.body.classList.add('touch');
    }

    const resetStyles = () => {
      const boxes = document.querySelectorAll(".box");
      boxes.forEach(box => {
        box.style.transform = "scale(1)";
      });
    };

    window.addEventListener("load", resetStyles);
    window.addEventListener("pageshow", resetStyles);

    if (window.innerWidth > 768) {
      const options = {
        threshold: 0.1
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const boxes = entry.target.querySelectorAll(".box");
            boxes.forEach((box, index) => {
              box.style.animationDelay = `${index * 0.2}s`;
              box.classList.add("animate-fade-in");
            });
            observer.unobserve(entry.target);
          }
        });
      }, options);

      categoriesRef.current.forEach(category => {
        if (category) {
          observer.observe(category);
        }
      });

      return () => {
        if (categoriesRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          categoriesRef.current.forEach(category => {
            if (category) {
              observer.unobserve(category);
            }
          });
        }
      };
    }
  }, []);

  return (
    <div>
      {/* <div>
        <h2 className="headingMain">Templates</h2>
        <h1 className="subHeading">
          Choose Your Professional Template Website Demos
        </h1>
      </div> */}
      <div className="category-container" ref={el => categoriesRef.current[0] = el}>
        <div className="templateCategory">Start-up</div>
        <div className="templetContainer">
          <div className="box">
            <a href="https://demo-sah.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/img-sah.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-office.serviceareahub.com/" target="_blank" rel="noopener noreferrer">
              <img src="/office.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-softtech.serviceareahub.com/" target="_blank" rel="noopener noreferrer">
              <img src="/softtech.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-elevato.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/elevato.png" alt='' className="resized-image" />
            </a>
          </div>
        </div>
      </div>
      <div className="category-container" ref={el => categoriesRef.current[1] = el}>
        <div className="templateCategory">E-commerce</div>
        <div className="templetContainer">
          <div className="box">
            <a href="https://demo-furniture.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/img-fur.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-foodking.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/food.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-storez.serviceareahub.com/" target="_blank" rel="noopener noreferrer">
              <img src="/storez.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-mall.serviceareahub.com/" target="_blank" rel="noopener noreferrer">
              <img src="/mall.jpg" alt='' className="resized-image" />
            </a>
          </div>
        </div>
      </div>
      <div className="category-container" ref={el => categoriesRef.current[2] = el}>
        <div className="templateCategory">Blogs, Personal and Portfolio Websites</div>
        <div className="templetContainer">
        <div className="box">
            <a href="https://demo-resume.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/resume.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-graphicdesigner.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/graphicdesigner.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-blog.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/blog.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-uiux.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/uiux.png" alt='' className="resized-image" />
            </a>
          </div>
        </div>
      </div>
      <div className="category-container" ref={el => categoriesRef.current[3] = el}>
        <div className="templateCategory">Corporate Websites</div>
        <div className="templetContainer">
        <div className="box">
            <a href="https://demo-corporate.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/corporate.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-logistics.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/logistics.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-hosting.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/hosting.png" alt='' className="resized-image" />
            </a>
          </div>
          <div className="box">
            <a href="https://demo-business.serviceareahub.com" target="_blank" rel="noopener noreferrer">
              <img src="/business.png" alt='' className="resized-image" />
            </a>
          </div>
        </div>
      </div>
      <div className="side-by-side">
        <div className="category-container" ref={el => categoriesRef.current[4] = el}>
          <div className="templateCategory">Educational Websites</div>
          <div className="templetContainer" style={{ marginRight: 'auto'}}>
            <div className="box">
              <a href="https://demo-college.serviceareahub.com/" target="_blank" rel="noopener noreferrer">
                <img src="/college.png" alt='' className="resized-image" />
              </a>
            </div>
            <div className="box">
              <a href="https://demo-courseguru.serviceareahub.com/" target="_blank" rel="noopener noreferrer">
                <img src="/courseguru.png" alt='' className="resized-image" />
              </a>
            </div>
          </div>
        </div>
        <div className="category-container wordpressDesktop" ref={el => categoriesRef.current[5] = el}>
          <div className="templateCategory">Wordpress</div>
          <div className="templetContainer">
            <div className="box">
              <a href="https://demo-wordpress1.serviceareahub.com/" target="_blank" rel="noopener noreferrer">
                <img src="/wp1.png" alt='' className="resized-image" />
              </a>
            </div>
            <div className="box">
              <a href="https://demo-wordpress2.serviceareahub.com/" target="_blank" rel="noopener noreferrer">
                <img src="/wp2.png" alt='' className="resized-image" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="category-container wordpressMobileOrTab" ref={el => categoriesRef.current[5] = el}>
            <div className="templateCategory">Wordpress</div>
            <div className="templetContainer">
            <div className="box">
                <a href="https://demo-wordpress1.serviceareahub.com/" target="_blank" rel="noopener noreferrer">
                <img src="/wp1.png" alt='' className="resized-image" />
                </a>
            </div>
            <div className="box">
                <a href="https://demo-wordpress2.serviceareahub.com/" target="_blank" rel="noopener noreferrer">
                <img src="/wp2.png" alt='' className="resized-image" />
                </a>
            </div>
            </div>
        </div>
    </div>
  );
}

export default Template;
