import React, { useEffect } from "react";
import Navbar from "./navbar";
import WebDesignPic from "../assets/web-design.jpg";
import GraphicDesignPic from "../assets/graphic-design.jpg";
import Footer from "../footer/index";

const WebGraphicDesign = () => {
    useEffect(() => {
        document.title = 'Web and Graphic Designing Services - Designing Digital Dreams and Ideas to Life | ServiceAreaHub';
    }, []);
    return(
        <div>
            <Navbar/>
            <div className="serviceContainer">
            <section className="page-section bg-light" id="services">
                    <div className="container">
                        <div className="text-center">
                            {/* <h2 className="section-heading text-uppercase sectionHead"> */}
                            <h2 className="section-heading sectionHead">
                                {/* <span style={{"fontWeight": "lighter"}}>Boost Your Brand with Our Professional</span> */}
                                {/* {' '} Services */}
                                Web and Graphic Designing {' '}
                            </h2>
                            <span style={{fontSize: "30px", fontWeight: "bold"}}>Designing Digital Dreams and Ideas to Life</span>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <p>
                                    We excel in providing exceptional Web and Graphic Designing services that bring your brand to life. Our team of skilled designers and developers collaborates to create websites that are not only visually appealing but also highly functional and user-centric. By focusing on responsive design, mobile optimization, and SEO best practices, we ensure that your website not only looks great but also performs excellently across all devices and search engines.
                                </p>
                                <p>
                                    Our graphic designing services are crafted to enhance your brand’s visual identity and make a lasting impression. From eye-catching logos to cohesive brand materials and engaging social media graphics, we leverage cutting-edge tools and creative techniques to produce designs that captivate your audience. We understand that strong visuals are a cornerstone of effective communication, and we strive to deliver graphics that reflect your brand’s unique personality and values.
                                </p>
                                <p>
                                Choosing ServiceAreaHub means partnering with a team dedicated to your success. We believe in a client-centric approach, where your vision and goals are at the forefront of our design process. Let us help you create a powerful digital presence that not only attracts but also retains your target audience. Get in touch with us today to start your journey towards a standout online presence!
                                </p>
                            </h3>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <div style={{fontSize: "30px", fontWeight: "bold"}}>Why should you choose us for your Web and Graphic Design company?</div>
                                <br/>
                                <p>
                                    Choosing ServiceAreaHub for your web and graphic design needs means partnering with a team dedicated to elevating your brand's visual and digital presence. Our expert designers and developers work closely with you to create stunning, functional websites and eye-catching graphics that resonate with your target audience. We blend creativity with cutting-edge technology to deliver designs that not only look great but also perform exceptionally well across all devices.
                                </p>
                                <p>
                                    Our comprehensive approach ensures that every element of your web and graphic design is meticulously crafted to align with your brand identity and business goals. From user-friendly website interfaces to compelling visual content, we prioritize both aesthetics and usability. This results in a cohesive, professional online presence that sets you apart from the competition and engages your customers effectively.
                                </p>
                                <p>
                                    We are committed to excellence and client satisfaction. We take the time to understand your unique needs and tailor our services accordingly. Our dedication to innovation and quality ensures that your web and graphic design projects are executed to the highest standards. Choose us to transform your ideas into visually captivating and highly functional digital assets that drive your business forward.
                                </p>
                            </h3>
                        </div>
                        <div>
                            {/* Website Designing */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Website Designing</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    {/* <div className="portfolio-item imageEffect"> */}
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={WebDesignPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Website designing encompasses the process of creating visually appealing and functional websites. It involves a blend of technical skills and creative design principles to craft websites that are both user-friendly and engaging. Designers use various tools and technologies to conceptualize layouts, choose color schemes, and integrate interactive elements like navigation menus and multimedia content.
                                    </p>
                                    <p>
                                        Effective website designing begins with understanding the client's needs and target audience. Designers must ensure that the website not only looks attractive but also aligns with the client's brand identity and business goals. User experience (UX) is a critical aspect, focusing on how easily visitors can navigate and interact with the site. This includes responsive design for compatibility across different devices and accessibility considerations for users with disabilities. Overall, successful website designing combines aesthetic appeal with functionality to create a seamless online experience for visitors.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Graphics Designing */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Graphics Designing</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        Graphic design is an art form that merges creativity with functionality, aiming to visually communicate concepts and messages. It encompasses a wide range of disciplines, including typography, illustration, and layout design, all orchestrated to create visually appealing and impactful materials. From designing logos that embody a brand's identity to crafting user interfaces that enhance digital experiences, graphic designers employ specialized software and techniques to bring ideas to life in both print and digital formats.
                                    </p>
                                    <p>
                                        Beyond aesthetics, graphic design is about problem-solving and effective communication. Designers must understand client needs, target audiences, and market trends to create designs that resonate and fulfill strategic objectives. Whether it's designing sleek corporate brochures or engaging social media graphics, graphic design is essential for conveying information efficiently and leaving a lasting impression on viewers. In today's visually-driven world, the role of graphic designers continues to evolve, influencing how we perceive and interact with information across various media platforms.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={GraphicDesignPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Graphic design is an art form that merges creativity with functionality, aiming to visually communicate concepts and messages. It encompasses a wide range of disciplines, including typography, illustration, and layout design, all orchestrated to create visually appealing and impactful materials. From designing logos that embody a brand's identity to crafting user interfaces that enhance digital experiences, graphic designers employ specialized software and techniques to bring ideas to life in both print and digital formats.
                                    </p>
                                    <p>
                                        Beyond aesthetics, graphic design is about problem-solving and effective communication. Designers must understand client needs, target audiences, and market trends to create designs that resonate and fulfill strategic objectives. Whether it's designing sleek corporate brochures or engaging social media graphics, graphic design is essential for conveying information efficiently and leaving a lasting impression on viewers. In today's visually-driven world, the role of graphic designers continues to evolve, influencing how we perceive and interact with information across various media platforms.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    )
};

export default WebGraphicDesign;