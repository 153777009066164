import React from "react";
import NNH from "../assets/NNH.jpg";
import SC from "../assets/SC.jpg";
import ARC from "../assets/ARC.jpg";
import '../style.css';

const AboutPage = () => {
    return(
        <div>
            {/* About */}
            <section className="page-section bg-light" id="" style={{paddingLeft: "10px", paddingRight: "10px"}}>
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading sectionHead">
                            About SAH
                        </h2>
                        <div className="aboutPara">
                            <span style={{fontSize: "30px", fontWeight: "bold"}}>
                                Welcome to ServiceAreaHub
                            </span>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <p>
                                    Welcome to ServiceAreaHub. We specialize in providing comprehensive services that cover a wide spectrum of the digital world. Our team of experts are dedicated to helping businesses achieve their goals through innovative and customized solutions. The detailed information about our key services: Web Development, Mobile Application Development, Digital Marketing and SEO, Web and Graphic Designing, Content Writing, and E-commerce, are mentioned below.
                                </p>
                            </h3>

                            <span style={{fontSize: "30px", fontWeight: "bold"}}>
                                Who We Are?
                            </span>
                            <p>
                                ServiceAreaHub is a leading IT services and consultancy provider dedicated to helping businesses achieve their goals through innovative technology solutions and expert guidance. With a team of experienced professionals and a commitment to excellence, we deliver results that matter.
                                Our Mission is to provide top-tier IT and consultancy services that drive business success.
                                To be the trusted partner for businesses worldwide, known for our expertise, reliability, and innovation.
                                Our comprehensive range of IT and consultancy services are designed to meet the business needs of our clients. 
                                Discover what sets us apart and how we can drive the business forward.
                                Hear from our satisfied clients and learn about their success stories.
                            </p>
                            <br/>
                            <div style={{textAlign: "justify"}}>
                                <p>
                                <b>
                                    Success Stories - {" "}
                                </b>
                                    Our Approach is to Understand the needs of our clients in order to provide better solutions which will help our clients become successful. We start by understanding business goals and requirements of our clients. This allows us to tailor our services to meet their specific needs.
                                </p>
                                <p>
                                <b>
                                    Planning and Strategy - {" "}
                                </b>
                                    We develop a comprehensive plan and strategy that aligns with specific objectives according to the need of our clients. Our team works closely with our clients to ensure we are on the right track.
                                </p>
                                <p>
                                <b>
                                    Testing and Quality Assurance - {" "}
                                </b>
                                    We conduct thorough testing to ensure that our solutions are bug-free and meets the highest standards of quality.
                                </p>
                                <p>
                                <b>
                                    Launch and Support - {" "}
                                </b>
                                    We assist with the launch of the given projects, provide ongoing support and maintenance to ensure it's continued success.
                                </p>
                                <p>
                                <b>
                                    Expertise - {" "}
                                </b>
                                    Our team consists of experienced professionals who are experts in their respective fields. We stay updated with the latest trends and technologies to provide our clients with cutting-edge solutions.
                                </p>
                                <p>
                                <b>
                                    Customization - {" "}
                                </b>
                                    We understand that every business is unique and needs special attention, that's why we offer customized solutions tailored to the specific needs and goals of our clients.
                                </p>
                                <p>
                                <b>
                                    Results-Driven - {" "}
                                </b>
                                    We are committed to delivering results that helps our clients achieve their business objectives. Our focus is on driving growth and success for their business.
                                </p>
                                <p>
                                <b>
                                    Customer-Centricity - {" "}
                                </b>
                                    Our clients are at the heart of everything we do. We prioritize the satisfaction of our clients and work tirelessly to exceed their expectations.
                                </p>
                                <p>
                                <b>
                                    Innovation - {" "}
                                </b>
                                    We embrace innovation and continuously seek new ways to improve our services and deliver better results for our clients.
                                </p>
                                <p>
                                <b>
                                    Collaboration - {" "}
                                </b>
                                    We believe in the power of collaboration. We work closely with our clients throughout the project to ensure we are aligned with their vision and goals.
                                </p>
                                <br/>
                                <br/>
                                <br/>
                                <p>
                                    Thank you for considering us for your digital needs. We look forward to partnering with you and helping your business thrive in the digital world.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Team*/}
            <section className="page-section bg-light" id="team">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Meet The Team Behind</h2>
                    <h3 className="section-subheading text-muted">
                        At ServiceAreaHub, our innovative technology team is the driving force behind our cutting-edge solutions. Each member brings a wealth of expertise in software development, cybersecurity, artificial intelligence, and IT consulting. Our team of experienced engineers, creative designers, and strategic thinkers collaborate seamlessly to deliver exceptional technology solutions. Together, we are committed to pushing the boundaries of innovation, enhancing digital transformation, and transforming the way technology impacts the world.
                    </h3>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="team-member">
                            <img className="mx-auto rounded-circle" src={NNH} alt="..." />
                            <h3>Nagendra Nath Hembram</h3>
                            <p className="text-muted">Technical Lead</p>
                            {/* <p className="text-muted">Chief Technology Officer (CTO)</p> */}
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Nagendra Nath Hembram Twitter Profile"><i className="fab fa-twitter"></i></a> */}
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Nagendra Nath Hembram Facebook Profile"><i className="fab fa-facebook-f"></i></a> */}
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Nagendra Nath Hembram LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a> */}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="team-member">
                            <img className="mx-auto rounded-circle" src={SC} alt="..." />
                            <h3>Sayantan Chakraborty</h3>
                            <p className="text-muted">Development Lead</p>
                            {/* <p className="text-muted">Chief Operating Officer (COO)</p> */}
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Sayantan Chakraborty Twitter Profile"><i className="fab fa-twitter"></i></a> */}
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Sayantan Chakraborty Facebook Profile"><i className="fab fa-facebook-f"></i></a> */}
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Sayantan Chakraborty LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a> */}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="team-member">
                            <img className="mx-auto rounded-circle" src={ARC} alt="..." />
                            <h3>Amitendu Roy Chowdhury</h3>
                            <p className="text-muted">Marketing Lead</p>
                            {/* <p className="text-muted">Chief Executive Officer (CEO)</p> */}
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Amitendu Roy Chowdhury Twitter Profile"><i className="fab fa-twitter"></i></a> */}
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Amitendu Roy Chowdhury Facebook Profile"><i className="fab fa-facebook-f"></i></a> */}
                            {/* <a className="btn btn-dark btn-social mx-2" href="#!" aria-label="Amitendu Roy Chowdhury LinkedIn Profile"><i className="fab fa-linkedin-in"></i></a> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 mx-auto text-center"><p className="large text-muted"></p></div>
                </div>
            </div>
        </section>
        </div>
    )
};

export default AboutPage;