import React from "react";
import '../style.css';

const TermsAndConditionPage = () => {
    return(
        <div style={{textAlign: 'left'}}>
            <section className="page-section bg-light" id="privacyPolicy">
                <div className="container">
                    <h1 className="section-heading text-center sectionHead">Terms And Conditions</h1>

                    <div style={{marginTop: "50px"}}>

                    <p><strong>By agreeing to these Terms and Conditions, and submitting the job application form to ServiceAreaHub, you (the applicant) agree to the following terms and conditions.</strong></p>

                    <p><strong>Please read carefully and ensure you understand these terms before checking the box to proceed.</strong></p>

                    <h2>1. Introduction</h2>
                    <p>ServiceAreaHub ("we," "our," "us") is a leading provider of IT services, including but not limited to Web Development, Graphic Designing, Content Writing, Digital Marketing, and SEO. We aim to collaborate with highly skilled applicants who can contribute to our projects. By applying for a freelance position, you acknowledge and agree to the terms and conditions set forth in this agreement.</p>

                    <h2>2. Eligibility and Application Process</h2>
                    <h3>2.1 Applicant Criteria:</h3>
                    <p>To apply as a applicant, you must meet the minimum eligibility requirements, including:</p>
                    <ol>
                        <li>Your age must be 18 years or older.</li>
                        <li>You must have relevant experience or expertise in Web Development, Graphic Designing, Content Writing, Digital Marketing, or SEO.</li>
                        <li>You must provide a valid and active email address.</li>
                        <li>You must provide accurate, complete, and up-to-date information during the application process.</li>
                        <li>You should provide the required details with the best of your knowledge.</li>
                    </ol>

                    <h3>2.2 Application Form Submission:</h3>
                    <p>By submitting the form, you confirm that the information you provide is true and correct to the best of your knowledge. You understand that any false or misleading information may result in the rejection of your application or termination of any future contracts.</p>

                    <h2>3. Applicant Responsibilities</h2>
                    <h3>3.1 Project Assignment:</h3>
                    <p>Upon acceptance of your application, you will be assigned specific projects based on your skills and expertise. Each project will have its own scope, deadlines, and deliverables, which will be communicated to you clearly.</p>

                    <h3>3.2 Quality of Work:</h3>
                    <p>You agree to provide work that meets the quality standards outlined by ServiceAreaHub. The work must be original, free of plagiarism, and adhere to the guidelines or specifications provided for each project. Any revisions or adjustments requested by the client must be completed promptly.</p>

                    <h3>3.3 Communication:</h3>
                    <p>You agree to maintain clear and timely communication with ServiceAreaHub and any clients involved in the project. Communication will typically be done through email, project management software, or other communication channels specified by ServiceAreaHub. You must be responsive to requests and queries regarding project updates.</p>

                    <h3>3.4 Deadlines:</h3>
                    <p>Applicants must commit to meeting the deadlines specified for each project. Failure to meet deadlines can result in loss of trust, penalties, delays in payments, or termination of the applicant’s involvement with ServiceAreaHub.</p>

                    <h2>4. Intellectual Property and Confidentiality</h2>
                    <h3>4.1 Ownership of Work:</h3>
                    <p>All intellectual property created as part of your work for ServiceAreaHub, including but not limited to written content, graphics, code, marketing strategies, and other deliverables, will remain the property of ServiceAreaHub or its clients unless otherwise agreed in writing. You acknowledge that ServiceAreaHub or its clients have the exclusive right to use, modify, and distribute the work.</p>

                    <h3>4.2 Confidentiality:</h3>
                    <p>You agree to keep any proprietary information, trade secrets, and sensitive data shared during the course of the project confidential. This includes but is not limited to client details, business strategies, and internal documentation. You may not disclose or use any confidential information for personal gain or the benefit of any third party.</p>

                    <h3>4.3 Project Copyright:</h3>
                    <p>Projects submitted must not have copyright of any third party, without appropriate permission.</p>

                    <h3>4.4 Project Privacy:</h3>
                    <p>Projects submitted by you can be made publicly available and shown to other applicants and clients for training and quality purposes.</p>

                    <h3>4.5 Non-Compete Clause:</h3>
                    <p>You agree that during the course of your contract and for a period of 12 months after the termination of your association with ServiceAreaHub, you will not engage in any direct competition with ServiceAreaHub in the same service areas (Web Development, Graphic Designing, Content Writing, Digital Marketing, SEO).</p>

                    <h2>5. Payment and Compensation</h2>
                    <h3>5.1 Payment Terms:</h3>
                    <p>Applicants will be compensated as per the terms specified in the project agreement. Payments will typically be made via bank transfer, PayPal, or other payment methods agreed upon by both parties. You agree to provide accurate payment details.</p>

                    <h3>5.2 Payment Schedule:</h3>
                    <p>Payments may be made on a project basis, hourly basis, or milestone-based, depending on the nature of the project. Applicants will be notified of the payment schedule and any specific milestones tied to the payment. You are required to submit invoices or timesheets as applicable for accurate processing.</p>

                    <h3>5.3 Full and Final Payment:</h3>
                    <p>The full and final payment will be made to you only after the Project is seen and approved by the client.</p>

                    <h3>5.4 Late Payments:</h3>
                    <p>If payments are not made within the agreed timeline, ServiceAreaHub will make reasonable efforts to resolve the issue. However, any delay in payment will not be grounds for immediate termination of the agreement unless specified otherwise.</p>

                    <h3>5.5 Taxes:</h3>
                    <p>You are responsible for any tax liabilities arising from payments made to you by ServiceAreaHub. ServiceAreaHub will not withhold taxes on your behalf.</p>

                    <h2>6. Term and Termination</h2>
                    <h3>6.1 Contract Duration:</h3>
                    <p>The freelance agreement may be for a specific project, a set period, or an ongoing relationship depending on the requirements. The duration of each project will be defined at the time of assignment.</p>

                    <h3>6.2 Termination by Applicant:</h3>
                    <p>Applicants can terminate the agreement at any time by providing written notice to ServiceAreaHub, provided that any ongoing projects are completed or handed over as per the terms of the agreement.</p>

                    <h3>6.3 Termination by ServiceAreaHub:</h3>
                    <p>ServiceAreaHub reserves the right to terminate the freelance agreement at any time, with or without cause. Common reasons for termination include failure to meet project deadlines, unsatisfactory work quality, breach of confidentiality, or failure to adhere to the terms outlined in this agreement.</p>

                    <h3>6.4 Effect of Termination:</h3>
                    <p>Upon termination of the agreement, applicants must return or destroy any confidential information and deliver all completed work or work in progress. Payments due will be made for any completed work up until the point of termination.</p>

                    <h2>7. Dispute Resolution</h2>
                    <h3>7.1 Negotiation:</h3>
                    <p>In the event of a dispute between the applicant and ServiceAreaHub, both parties agree to attempt to resolve the issue through negotiation and discussion before pursuing legal action.</p>

                    <h3>7.2 Arbitration:</h3>
                    <p>If a dispute cannot be resolved through negotiation, both parties agree to submit the matter to binding arbitration, conducted in accordance with the laws of the jurisdiction in which ServiceAreaHub is incorporated.</p>

                    <h2>8. Indemnification and Liability</h2>
                    <h3>8.1 Indemnification:</h3>
                    <p>You agree to indemnify and hold harmless ServiceAreaHub, its employees, agents, clients, and affiliates from any claims, damages, or liabilities arising from your work, including but not limited to intellectual property infringement, breach of confidentiality, or negligence.</p>

                    <h3>8.2 Limitation of Liability:</h3>
                    <p>ServiceAreaHub will not be held liable for any direct, indirect, incidental, or consequential damages arising out of or related to your work or this agreement, including but not limited to loss of revenue, reputation, or any other economic or non-economic loss.</p>

                    <h2>9. Force Majeure</h2>
                    <p>ServiceAreaHub will not be held liable for any failure to perform its obligations under this agreement due to circumstances beyond its control, including but not limited to natural disasters, acts of war, government regulations, or unforeseen technical issues.</p>

                    <h2>10. Amendments and Modifications</h2>
                    <h3>10.1 Changes to Terms:</h3>
                    <p>ServiceAreaHub reserves the right to amend these terms and conditions at any time. You will be notified of any material changes, and continued submission of applications or participation in projects will be considered acceptance of the revised terms.</p>

                    <h3>10.2 Specific Project Terms:</h3>
                    <p>In addition to these general terms, specific projects may have their own terms and conditions. You agree to review and adhere to the project-specific terms before accepting any job assignment.</p>

                    <h2>11. Privacy and Data Protection</h2>
                    <h3>11.1 Personal Information:</h3>
                    <p>By submitting the application form, you consent to the collection, storage, and processing of your personal information by ServiceAreaHub for the purpose of project assignment, payment processing, and communication.</p>

                    <h3>11.2 Data Security:</h3>
                    <p>ServiceAreaHub will make reasonable efforts to protect your personal and financial data. However, no method of transmission or storage is completely secure, and you acknowledge the inherent risks of sharing personal information online.</p>

                    {/* <h2>12. Miscellaneous</h2>
                    <h3>12.1 Entire Agreement:</h3>
                    <p>These terms and conditions constitute the entire agreement between you and ServiceAreaHub regarding your freelance work. Any prior agreements or understandings, whether written or verbal, are superseded by this agreement.</p>

                    <h3>12.2 Severability:</h3>
                    <p>If any provision of this agreement is deemed invalid or unenforceable by a court of competent jurisdiction, the remaining provisions will remain in full force and effect.</p>

                    <h3>12.3 Governing Law:</h3>
                    <p>This agreement will be governed by and construed in accordance with the laws of the jurisdiction in which ServiceAreaHub is incorporated.</p> */}

                    </div>
                </div>
            </section>
        </div>
    );
};

export default TermsAndConditionPage;