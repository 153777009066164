import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { useNavigate } from "react-router-dom";
import SAH from '../assets/ServiceAreaHub.png';
import MobileNavbar from '../home/mobilenavbar';
import '../style.css'


const BlogNav = () => {
    const navigate = useNavigate();
    const navigation = (url) => {
      window.scrollTo(0, 0);
      if(url === "home"){
        navigate("/");
      }
      else if(url === "services"){
        navigate("/services");
      }
      else if(url === "templates"){
        navigate("/templates");
      }
      else if(url === "blogs"){
        navigate("/blogs");
      }
      else if(url === "about"){
        navigate("/about");
      }
      else if(url === "contact"){
        navigate("/contact");
      }
    }
    return (
      <div>
        <nav className="navbar" id='SAHmainNav'>
          <Nav.Link className="text-white" href={'https://serviceareahub.com'}>
          <img src={SAH} alt="..." className="logo" width="70px" height="70px" />
          {/* <Nav.Link className="text-white" href="/chikadumpa" onClick={logOut}><p id="navMenu">Log Out</p> */}
          </Nav.Link>
          <MobileNavbar/>
          <div className="navbar-collapse" id="navbarResponsive">
          {/* <ul className="navbar-tabs text-uppercase ms-auto py-4 py-lg-0"> */}
          <ul className="navbar-tabs">
            <li
              className={`navbar-tab nav-item`}
              onClick={() => navigation("home")}
            >
              Home
            </li>
            <li
            className={`navbar-tab`}
            onClick={() => navigation("services")}
            >
              Services
            </li>
            <li
            className={`navbar-tab`}
            onClick={() => navigation("templates")}
            >
              Templates
            </li>
            <li
              className={`navbar-tab active`}
              onClick={() => navigation("blogs")}
            >
              Blogs
            </li>
            <li
            className={`navbar-tab`}
            onClick={() => navigation("about")}
            >
              About
            </li>
            <li
            className={`navbar-tab`}
            onClick={() => navigation("contact")}
            >
              Contact
            </li>
          </ul>
          <Nav.Link className="inquiryButton" onClick={() => navigate("/inquiry")}>
            <span id='inquiry'>Get a FREE quote</span>
          </Nav.Link>
        </div>
      </nav>
      <div id="content">
        
      </div>
    </div>
  );
}

export default BlogNav;