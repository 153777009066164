import React, { useEffect } from "react";
import Navbar from "./navbar";
import searchEngineOptimizationPic from "../assets/seo-digital.jpg";
import contentMarketingPic from "../assets/content-marketing-digital.jpg";
import socialMediaOptimizationPic from "../assets/smo-digital.jpg";
import emailMarketingPic from "../assets/email-marketing-digital.jpg";
import payPerClickPic from "../assets/pay-per-click-digital.jpg";
import affiliateMerketingPic from "../assets/affiliate-marketing-digital.jpg";
import influencerMarketingPic from "../assets/influencer-marketing-digital.jpg";
import displayAdvertisingPic from "../assets/display-advertising-digital.jpg";
import videoMarketingPic from "../assets/video-marketing-digital.jpg";
import mobileMarketingPic from "../assets/mobile-marketing-digital.jpg";
import webAnaliticsPic from "../assets/web-analitics-digital.jpg";
import Footer from "../footer/index";

const DigitalMarket = () => {
    useEffect(() => {
        document.title = 'Digital Marketing Services - Unleash Digital Reach with Advanced Stratigies | ServiceAreaHub';
    }, []);
    return(
        <div>
            <Navbar/>
            <div className="serviceContainer">
            <section className="page-section bg-light" id="services">
                    <div className="container">
                        <div className="text-center">
                            {/* <h2 className="section-heading text-uppercase sectionHead"> */}
                            <h2 className="section-heading sectionHead">
                                {/* <span style={{"fontWeight": "lighter"}}>Boost Your Brand with Our Professional</span> */}
                                {/* {' '} Services */}
                                Digital Marketing {' '}
                            </h2>
                            <span style={{fontSize: "30px", fontWeight: "bold"}}>Unleash Digital Reach with Advanced Strategies</span>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <p>
                                    Our digital marketing services are crafted to transform your online presence and drive sustainable growth for your business. In today's competitive digital landscape, having a robust online strategy is paramount to success. We offer a comprehensive range of services, including SEO, PPC, social media marketing, content marketing, and email marketing, all tailored to meet your unique needs and objectives.
                                </p>
                                <p>
                                    Our team of experts excels in SEO, ensuring your website ranks high on search engines, attracting organic traffic and increasing visibility. Through targeted PPC campaigns, we place your ads in front of the right audience at the right time, maximizing your return on investment. Our social media marketing strategies are designed to build strong connections with your audience, fostering engagement and loyalty through compelling content and active community management.
                                </p>
                                <p>
                                    Content is the cornerstone of any successful digital strategy, and our content marketing services provide high-quality, relevant content that resonates with your audience and drives action. Additionally, our email marketing services keep your audience engaged and informed with personalized, impactful messages. At ServiceAreaHub, we leverage the latest tools and techniques to deliver results-driven digital marketing solutions. Partner with us to elevate your brand, attract more customers, and achieve your business goals.
                                </p>
                            </h3>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <div style={{fontSize: "30px", fontWeight: "bold"}}>Why should you choose ServiceAreaHub as your digital marketing partner?</div>
                                <br/>
                                <p>
                                    Our unwavering commitment to excellence, innovation, and measurable results sets us apart in a crowded market. At ServiceAreaHub, we understand that every business is unique, and we tailor our strategies to meet your specific needs, ensuring your brand stands out and thrives in the digital landscape.
                                </p>
                                <p>
                                    Our team of seasoned professionals brings a wealth of experience and a deep understanding of the latest digital marketing trends and technologies. From SEO and content marketing to social media management and PPC campaigns, we deploy cutting-edge techniques to drive traffic, engagement, and conversions. We focus on data-driven decision-making, constantly analyzing and optimizing campaigns to maximize ROI and achieve your business goals.
                                </p>
                                <p>
                                    Choosing ServiceAreaHub means partnering with a company that prioritizes your success. We believe in transparent communication, providing regular updates and insights so you're always in the loop. Our personalized approach ensures that we align our strategies with your vision, delivering results that exceed expectations. Let ServiceAreaHub be the catalyst for your digital growth and take your business to new heights.
                                </p>
                            </h3>
                        </div>
                        <div>
                            {/* Search Engine Optimization (SEO) */}
                            <div className="serviceElements">
                                {/* <div className="serviceHeads">Website Creation</div> */}
                                <div className="serviceHeads">Search Engine Optimization (SEO)</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    {/* <div className="portfolio-item imageEffect"> */}
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={searchEngineOptimizationPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Search Engine Optimization (SEO) is a digital marketing strategy aimed at enhancing a website's visibility on search engine results pages (SERPs). By optimizing various elements of a website, including its content, structure, and technical aspects, SEO helps improve organic traffic. Key components of SEO include keyword research, on-page optimization, off-page optimization, and technical SEO. Keyword research involves identifying and targeting relevant search terms, while on-page optimization focuses on optimizing individual pages with those keywords. Off-page optimization, such as backlink building, enhances a site's authority and relevance, and technical SEO ensures that a website meets the technical requirements of search engines.
                                    </p>
                                    <p>
                                        Effective SEO requires an understanding of search engine algorithms, user behavior, and industry trends. Regularly updated content, a mobile-friendly design, and fast loading speeds are crucial for maintaining high rankings. SEO is an ongoing process that involves constant monitoring and adjustments to stay ahead of algorithm updates and competitors. When executed correctly, SEO can significantly boost a website's organic traffic, brand awareness, and overall online presence.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Content Marketing */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Content Marketing</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        Content marketing is a strategic approach focused on creating and distributing valuable, relevant, and consistent content to attract and engage a clearly defined audience. Unlike traditional advertising, which interrupts consumers with overt sales pitches, content marketing aims to provide useful information that addresses the audience's needs and interests. This method builds trust and loyalty over time, positioning the brand as a thought leader in its industry. Effective content marketing leverages various formats, including blog posts, videos, infographics, podcasts, and social media updates, to reach and resonate with the target audience.
                                    </p>
                                    <p>
                                        By delivering high-quality content, businesses can drive profitable customer actions, such as increased website traffic, lead generation, and conversions. Content marketing also enhances search engine optimization (SEO) efforts, improving visibility and ranking on search engines. This approach requires a deep understanding of the audience's preferences and behaviors, as well as a commitment to consistent and long-term content creation and distribution. Ultimately, successful content marketing fosters stronger customer relationships and drives sustainable business growth.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={contentMarketingPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Content marketing is a strategic approach focused on creating and distributing valuable, relevant, and consistent content to attract and engage a clearly defined audience. Unlike traditional advertising, which interrupts consumers with overt sales pitches, content marketing aims to provide useful information that addresses the audience's needs and interests. This method builds trust and loyalty over time, positioning the brand as a thought leader in its industry. Effective content marketing leverages various formats, including blog posts, videos, infographics, podcasts, and social media updates, to reach and resonate with the target audience.
                                    </p>
                                    <p>
                                        By delivering high-quality content, businesses can drive profitable customer actions, such as increased website traffic, lead generation, and conversions. Content marketing also enhances search engine optimization (SEO) efforts, improving visibility and ranking on search engines. This approach requires a deep understanding of the audience's preferences and behaviors, as well as a commitment to consistent and long-term content creation and distribution. Ultimately, successful content marketing fosters stronger customer relationships and drives sustainable business growth.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Social Media Optimization (SMO) */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Social Media Optimization (SMO)</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={socialMediaOptimizationPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Social Media Optimization (SMO) refers to the strategic use of social media platforms to manage and enhance an organization's online presence and increase engagement with its target audience. It involves creating compelling content, using relevant hashtags, and interacting with followers to foster a strong online community. SMO also includes optimizing profiles, scheduling posts at peak times, and analyzing performance metrics to refine strategies continuously. By leveraging the unique features of each platform, businesses can boost their visibility, drive traffic to their websites, and ultimately increase conversions.
                                    </p>
                                    <p>
                                        Effective SMO requires a deep understanding of the audience and the social media landscape. It involves staying up-to-date with trends, utilizing multimedia content, and employing data-driven tactics to maximize reach and impact. Collaboration with influencers and participation in trending conversations can also amplify a brand’s presence. Ultimately, SMO is about building authentic relationships and engaging with users in meaningful ways to create a loyal and active community around a brand.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Email Marketing */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Email Marketing</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        Email marketing is a powerful digital marketing strategy that involves sending targeted emails to a group of subscribers to promote products, services, or brand messages. It allows businesses to reach their audience directly, providing personalized content that can drive engagement and conversions. Effective email marketing campaigns include compelling subject lines, visually appealing designs, and clear calls to action. Segmentation and automation tools enable marketers to tailor messages based on customer behavior and preferences, increasing relevance and effectiveness. Regularly analyzing metrics such as open rates, click-through rates, and conversion rates helps optimize future campaigns. By fostering a direct line of communication with customers, email marketing can build brand loyalty, nurture leads, and generate significant ROI.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={emailMarketingPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Email marketing is a powerful digital marketing strategy that involves sending targeted emails to a group of subscribers to promote products, services, or brand messages. It allows businesses to reach their audience directly, providing personalized content that can drive engagement and conversions. Effective email marketing campaigns include compelling subject lines, visually appealing designs, and clear calls to action. Segmentation and automation tools enable marketers to tailor messages based on customer behavior and preferences, increasing relevance and effectiveness. Regularly analyzing metrics such as open rates, click-through rates, and conversion rates helps optimize future campaigns. By fostering a direct line of communication with customers, email marketing can build brand loyalty, nurture leads, and generate significant ROI.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Pay-Per-Click (PPC) */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Pay-Per-Click (PPC)</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={payPerClickPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Pay-Per-Click (PPC) is an online advertising model where advertisers pay a fee each time one of their ads is clicked. This method is primarily used to drive traffic to websites, where advertisers bid on keywords relevant to their target market. The most common platform for PPC is Google Ads, which displays ads in search engine results based on the keywords users search for. Other platforms like Bing Ads and social media sites such as Facebook and LinkedIn also utilize PPC. The goal of PPC is to convert visitors into customers, making it a cost-effective way to attract interested audiences.
                                    </p>
                                    <p>
                                        PPC campaigns require careful planning and management to be effective. Advertisers must conduct thorough keyword research to identify terms that potential customers are likely to use. They also need to create compelling ad copy and landing pages to maximize conversions. Analytics tools are essential for tracking the performance of PPC campaigns, enabling advertisers to adjust bids, keywords, and ad content to improve return on investment. With the right strategy, PPC can significantly boost online visibility and drive business growth.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Affiliate Merketing */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Affiliate Marketing</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        Affiliate marketing is a performance-based marketing strategy where businesses reward affiliates (partners) for driving traffic or sales to their products or services through the affiliate's marketing efforts. This model relies on affiliates promoting products via blogs, social media, websites, and other online platforms, earning a commission for each sale or lead generated. It's a mutually beneficial arrangement: businesses gain increased visibility and sales without upfront marketing costs, while affiliates earn money for their promotional efforts.
                                    </p>
                                    <p>
                                        The effectiveness of affiliate marketing hinges on the quality and reach of the affiliates' content and audience. Successful affiliates often possess a deep understanding of their niche, creating compelling and trustworthy content that resonates with their followers. This trust translates to higher conversion rates when followers click on affiliate links and make purchases. As digital marketing continues to evolve, affiliate marketing remains a dynamic and cost-effective strategy for businesses looking to expand their market reach.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={affiliateMerketingPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Affiliate marketing is a performance-based marketing strategy where businesses reward affiliates (partners) for driving traffic or sales to their products or services through the affiliate's marketing efforts. This model relies on affiliates promoting products via blogs, social media, websites, and other online platforms, earning a commission for each sale or lead generated. It's a mutually beneficial arrangement: businesses gain increased visibility and sales without upfront marketing costs, while affiliates earn money for their promotional efforts.
                                    </p>
                                    <p>
                                        The effectiveness of affiliate marketing hinges on the quality and reach of the affiliates' content and audience. Successful affiliates often possess a deep understanding of their niche, creating compelling and trustworthy content that resonates with their followers. This trust translates to higher conversion rates when followers click on affiliate links and make purchases. As digital marketing continues to evolve, affiliate marketing remains a dynamic and cost-effective strategy for businesses looking to expand their market reach.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Influencer Marketing */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Influencer Marketing</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={influencerMarketingPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Influencer marketing leverages individuals with a significant and engaged following on social media to promote products or services. Brands collaborate with influencers who align with their values and target audience to create authentic, relatable content that resonates with potential customers. This strategy capitalizes on the trust and credibility that influencers have built with their followers, leading to increased brand awareness, higher engagement rates, and improved conversion rates. By tapping into the influencer's established community, businesses can reach niche markets more effectively than through traditional advertising. Successful influencer marketing campaigns involve careful selection of influencers, clear communication of campaign goals, and allowing creative freedom to ensure content authenticity and relevance.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Display Advertising */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Display Advertising</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        Display advertising involves placing visual ads on websites, apps, or social media platforms to promote a product, service, or brand. These ads can take various forms, including banners, interstitials, and rich media ads, and they typically feature compelling visuals, text, and sometimes interactive elements. Display advertising aims to attract the attention of target audiences and drive traffic to a specific website or landing page.
                                    </p>
                                    <p>
                                        Key aspects of effective display advertising include strategic placement on high-traffic sites relevant to the target audience, precise targeting based on demographics, interests, and browsing behavior, and compelling calls-to-action (CTAs) to encourage user engagement. Advertisers often use display advertising as part of a broader digital marketing strategy to increase brand awareness, generate leads, and promote conversions. Analyzing performance metrics such as click-through rates (CTRs) and conversion rates helps optimize campaigns for better results and ROI in the competitive digital advertising landscape.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={displayAdvertisingPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Display advertising involves placing visual ads on websites, apps, or social media platforms to promote a product, service, or brand. These ads can take various forms, including banners, interstitials, and rich media ads, and they typically feature compelling visuals, text, and sometimes interactive elements. Display advertising aims to attract the attention of target audiences and drive traffic to a specific website or landing page.
                                    </p>
                                    <p>
                                        Key aspects of effective display advertising include strategic placement on high-traffic sites relevant to the target audience, precise targeting based on demographics, interests, and browsing behavior, and compelling calls-to-action (CTAs) to encourage user engagement. Advertisers often use display advertising as part of a broader digital marketing strategy to increase brand awareness, generate leads, and promote conversions. Analyzing performance metrics such as click-through rates (CTRs) and conversion rates helps optimize campaigns for better results and ROI in the competitive digital advertising landscape.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Video Marketing */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Video Marketing</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={videoMarketingPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Video marketing harnesses the power of video content to promote products, services, or brands effectively. It involves creating engaging videos that resonate with target audiences across various platforms like YouTube, social media, and websites. Video marketing leverages storytelling, demonstrations, tutorials, and testimonials to captivate viewers and convey messages persuasively. By incorporating SEO strategies, such as optimized titles and descriptions, it enhances discoverability and drives traffic. Metrics like views, watch time, and shares are used to measure success and refine future campaigns. Video marketing not only boosts brand awareness and engagement but also builds trust and credibility through authentic and visually compelling narratives. It caters to the growing consumer preference for video content, making it a powerful tool for businesses to connect with their audiences on a deeper level and achieve their marketing objectives effectively.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Mobile Marketing */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Mobile Marketing</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        Mobile marketing encompasses all marketing activities aimed at reaching consumers on their mobile devices, such as smartphones and tablets. It leverages the unique capabilities of mobile technology to deliver personalized, location-based, and time-sensitive information to users. Key strategies include mobile-optimized websites, mobile apps, SMS marketing, QR codes, and in-app advertisements. Mobile marketing capitalizes on the widespread use of mobile devices to target audiences effectively, whether through push notifications that alert users to special offers or geotargeted ads that reach consumers based on their location. With the increasing reliance on smartphones for everyday tasks, mobile marketing enables brands to engage with consumers in real-time, fostering direct and immediate interactions that enhance customer loyalty and drive conversions. As mobile technology continues to evolve, mobile marketing remains crucial for businesses seeking to stay competitive and connect with consumers on a more personal and accessible level.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={mobileMarketingPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Mobile marketing encompasses all marketing activities aimed at reaching consumers on their mobile devices, such as smartphones and tablets. It leverages the unique capabilities of mobile technology to deliver personalized, location-based, and time-sensitive information to users. Key strategies include mobile-optimized websites, mobile apps, SMS marketing, QR codes, and in-app advertisements. Mobile marketing capitalizes on the widespread use of mobile devices to target audiences effectively, whether through push notifications that alert users to special offers or geotargeted ads that reach consumers based on their location. With the increasing reliance on smartphones for everyday tasks, mobile marketing enables brands to engage with consumers in real-time, fostering direct and immediate interactions that enhance customer loyalty and drive conversions. As mobile technology continues to evolve, mobile marketing remains crucial for businesses seeking to stay competitive and connect with consumers on a more personal and accessible level.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Web Analitics */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Web Analitics</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={webAnaliticsPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Web Analytics is the process of collecting, measuring, analyzing, and reporting data about the usage and performance of a website. It encompasses tracking visitor behavior, understanding traffic sources, and evaluating the effectiveness of online marketing initiatives. Through tools like Google Analytics, businesses can gain valuable insights into user demographics, navigation paths, and conversion rates. These insights help in optimizing website content, improving user experience, and refining digital marketing strategies to achieve business objectives. By leveraging web analytics, organizations can make data-driven decisions, identify opportunities for growth, and enhance overall online performance, ensuring that their digital efforts align with audience preferences and behaviors effectively.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    )
};

export default DigitalMarket;