import React from "react";
import ContactImg from '../assets/contact.jpg';
import EmailVector from '../assets/email.png';
import "./contactstyle.css";

const ContactPage = () => {
    return(
        <div>
            {/* Contact Us */}
            <section className="page-section bg-light" id="" style={{paddingLeft: "10px", paddingRight: "10px"}}>
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading sectionHead">
                            Contact Us
                        </h2>
                        <div className="contactPara">
                            <span style={{fontSize: "30px", fontWeight: "bold"}}>
                                WE TALK LESS, SUPPORT MORE
                            </span>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <p>
                                    We at ServiceAreaHub do not want to keep you waiting for someone to answer your call. We believe that advanced means of communication through the internet which can resolve problems very easily.
                                </p>
                            </h3>
                           
                            <img src={ContactImg} alt="" className="contactImage"/>
                            <br/>
                            <br/>
                            <span style={{fontSize: "30px", fontWeight: "bold"}}>
                                Ready to Take Your Business to The Next Level?
                            </span>
                            <p>
                                Contact us today to learn more about how our digital solutions can help you achieve your goals. Whether you need a new website, a mobile app, a digital marketing strategy, or help with content writing, we are here to get the job done.
                            </p>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <span style={{fontSize: "30px", fontWeight: "bold"}}>
                                Get in Touch
                            </span>
                            <p>
                                <samp><img src={EmailVector} alt="" className="contactEmailImg"/></samp> 
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a href="mailto:support@serviceareahub.com?subject=Enquiry%20for%20services&body=Dear%20SAH%20team,%0D%0A%0D%0AI%20would%20like%20to%20avail%20some%20services%20from%20ServiceAreaHub%20regarding...%0D%0A%0D%0AThanks%20with%20regards,%0D%0A" className="contactEmail">
                                    support@serviceareahub.com
                                </a>
                            </p>
                            <br/>
                            <div style={{textAlign: "justify"}}>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default ContactPage;