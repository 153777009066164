import React, { useEffect } from "react";
import Templates from "./templets";
import "../services/servicestyle.css";
// import SAH from '../assets/ServiceAreaHub.png';

// import WebCreationPic from "../assets/s1.jpg";
// import MobileAppDevelopmentPic from "../assets/s2.jpg";
// import DigitalMarketingPic from "../assets/s3.jpg";
// import WebGraphicDesigningPic from "../assets/s4.jpg";
// import ContentWritingPic from "../assets/s5.jpg";
// import E_commerce from "../assets/s6.jpg";

const TemplatesPage = () => {
    useEffect(() => {
        document.title = 'Templates - Choose Your Professional Templet Website Demos | ServiceAreaHub';
    }, []);
    return(
        <div>
            {/* Templates */}
            <section className="page-section bg-light" id="services">
                <div className="container">
                    <div className="text-center">
                        {/* <h2 className="section-heading text-uppercase sectionHead"> */}
                        <h2 className="section-heading sectionHead">
                            {/* <span style={{"fontWeight": "lighter"}}>Boost Your Brand with Our Professional</span> */}
                            {/* {' '} Templates */}
                            Templates {' '}
                        </h2>
                        <span style={{fontSize: "30px", fontWeight: "bold"}}>Choose Your Professional Templet Website Demos</span>
                        <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                            <p>
                                Our Template Service offers a diverse range of web templates for startups, e-commerce, personal portfolios, corporate, educational, and WordPress websites. Each template is designed for modern functionality and ease of use. Our startup and e-commerce templates ensure a seamless user experience with secure, customizable features. Personal portfolio templates highlight creativity, while corporate templates enhance business operations. Our educational templates support course management and student portals. Specializing in WordPress, we offer solutions for blogs to complex business sites. All templates are fully responsive, ensuring optimal performance on any device, driving digital success.
                            </p>
                            {/* <p>
                                We also specialize in WordPress templates, designed for a range of purposes from blogs to complex business solutions, ensuring ease of use and robust features. Our educational templates support institutions and educators with course management and student portals. Each template is fully responsive, guaranteeing optimal performance on all devices. ServiceAreaHub is committed to empowering users with high-quality, customizable web templates that drive digital success.
                            </p> */}
                            {/* <p>
                                E-commerce templates from ServiceAreaHub are secure and user-friendly, ensuring a smooth shopping experience with integrated payment systems and efficient product management. Our WordPress templates are highly adaptable and easy to manage, suitable for a variety of applications from personal blogs to business sites. Utilizing the latest technologies, all our templates guarantee high performance, security, and responsiveness across all devices.
                            </p> */}
                        </h3>
                    </div>
                </div>
                <div>
                    <Templates/>
                </div>
            </section>
        </div>
    )
};

export default TemplatesPage;