import React, { useEffect } from "react";
import WebCreationPic from "../assets/s1.jpg";
import MobileAppDevelopmentPic from "../assets/s2.jpg";
import DigitalMarketingPic from "../assets/s3.jpg";
import WebGraphicDesigningPic from "../assets/s4.jpg";
import ContentWritingPic from "../assets/s5.jpg";
import E_commerce from "../assets/s6.jpg";
import "./servicestyle.css";

const ServicesPage = () => {

    useEffect(() => {
        document.title = 'Services - Boost Your Brand with Our Professional Expertise | ServiceAreaHub';
    }, []);

    return(
        <div>
            {/* Services */}
            <section className="page-section bg-light" id="services">
                <div className="container">
                    <div className="text-center">
                        {/* <h2 className="section-heading text-uppercase sectionHead"> */}
                        <h2 className="section-heading sectionHead">
                            {/* <span style={{"fontWeight": "lighter"}}>Boost Your Brand with Our Professional</span> */}
                            {/* {' '} Services */}
                            Services {' '}
                        </h2>
                        <span style={{fontSize: "30px", fontWeight: "bold"}}>Boost Your Brand with Our Professional Expertise</span>
                        <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                            <p>
                                ServiceAreaHub offer a comprehensive suite of services designed to propel your business forward in the digital landscape. Our expert team specializes in web development, mobile app development, digital marketing, web and graphic designing, and e-commerce solutions.
                            </p>
                            <p>
                                From creating visually stunning websites to developing intuitive mobile apps, our focus is on delivering exceptional user experiences that drive engagement and conversions. We understand the importance of SEO in today's competitive online market. That's why all our services are meticulously crafted to be SEO-friendly, ensuring your brand ranks high on search engine results pages and attracts targeted traffic.
                            </p>
                            <p>
                                Whether you're looking to revamp your website, launch a new mobile app, or enhance your digital marketing efforts, Templates has you covered. Our tailored solutions are designed to meet your unique business needs and help you achieve your goals. Partner with us to unlock the full potential of your brand and establish a strong online presence that sets you apart from the competition.
                            </p>
                        </h3>
                    </div>
                    <div>
                        {/* Website Creation */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Website Creation</div>
                            <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                {/* <div className="portfolio-item imageEffect"> */}
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid" src={WebCreationPic} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="mb-4 serviceDetailRight serviceBrief">
                                <p>
                                    Unlock the potential of your online presence with our expert website development services. We specialize in creating custom, responsive websites that cater to your unique business needs. Our skilled team ensures seamless user experiences, fast loading times, and stunning visuals to captivate your audience. Utilizing the latest technologies and best practices, we build websites that are not only visually appealing but also optimized for search engines, enhancing your online visibility. From e-commerce platforms to personal blogs, we provide end-to-end solutions, including design, development, and ongoing maintenance. Partner with us to transform your digital vision into reality, attract more visitors, and convert them into loyal customers. Discover the difference professional website development can make for your business. Contact us today to get started on your path to online success.
                                    {/* Our web development services create robust, user-friendly websites that are not only visually appealing but also SEO-optimized. We use the latest technologies and best practices to ensure your website loads quickly, functions smoothly, and ranks high on search engines. */}
                                </p>
                                <a className="service-link" href="/services/websitecreation">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* Mobile App Development */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Mobile App Development</div>
                            <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                <p>
                                    Elevate your business with our top-tier mobile app development services. We create custom, high-performance mobile applications designed to meet your specific business goals. Our experienced team uses cutting-edge technologies and best practices to develop powerful, scalable, and innovative apps for both iOS and Android devices. From initial concept to final launch, we ensure a smooth development process, emphasizing intuitive design and user-friendly functionality that captivates and retains users. Whether you need native, hybrid, or cross-platform apps, we offer comprehensive services including strategy, UI/UX design, development, testing, and ongoing support. Boost your brand visibility, enhance customer engagement, and drive revenue growth with our bespoke mobile app solutions. Partner with us to turn your vision into a compelling mobile experience that distinguishes you in the competitive digital market.
                                </p>
                                <a className="service-link" href="/services/mobileappdevelopment">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid" src={MobileAppDevelopmentPic} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                <p>
                                    Elevate your business with our top-tier mobile app development services. We create custom, high-performance mobile applications designed to meet your specific business goals. Our experienced team uses cutting-edge technologies and best practices to develop powerful, scalable, and innovative apps for both iOS and Android devices. From initial concept to final launch, we ensure a smooth development process, emphasizing intuitive design and user-friendly functionality that captivates and retains users. Whether you need native, hybrid, or cross-platform apps, we offer comprehensive services including strategy, UI/UX design, development, testing, and ongoing support. Boost your brand visibility, enhance customer engagement, and drive revenue growth with our bespoke mobile app solutions. Partner with us to turn your vision into a compelling mobile experience that distinguishes you in the competitive digital market.
                                </p>
                                <a className="service-link" href="/services/mobileappdevelopment">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* Digital Marketing */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Digital Marketing & SEO</div>
                            <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid" src={DigitalMarketingPic} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                <p>
                                    Maximize your online presence with our comprehensive digital marketing services. We specialize in driving targeted traffic, increasing conversions, and boosting your brand visibility across digital platforms. Our expert team uses data-driven strategies, combining SEO, social media marketing, PPC advertising, email marketing, and content marketing to deliver measurable results. We tailor our approach to meet your unique business goals, ensuring that every campaign is optimized for success. From keyword research and on-page optimization to engaging social media content and high-converting ad campaigns, we cover all aspects of digital marketing. Stay ahead of the competition with our innovative techniques and industry insights. Our end-to-end services include strategy development, execution, analytics, and continuous improvement. Partner with us to transform your digital presence, attract more customers, and achieve sustainable growth. Elevate your brand with our professional digital marketing solutions designed to deliver maximum ROI.
                                </p>
                                <a className="service-link" href="/services/digitalmarketing">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* Web & Graphic Design */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Web & Graphic Design</div>
                            <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                <p>
                                    Transform your online presence with our exceptional web and graphic design services. We specialize in creating visually stunning, user-friendly websites and captivating graphics that elevate your brand. Our expert designers blend creativity with functionality, ensuring your site not only looks great but also provides an intuitive user experience. Whether you need a complete website overhaul, a unique logo, or engaging marketing materials, we tailor our solutions to meet your specific business needs. Utilizing the latest design trends and technologies, we deliver responsive, mobile-friendly websites and eye-catching graphics that stand out in today’s competitive market. Our comprehensive services include web design, graphic design, UI/UX design, branding, and print design. Enhance your brand identity, engage your audience, and drive conversions with our custom design solutions. Partner with us to create a compelling online presence that captures attention and drives success. Boost your brand with our professional web and graphic design services.
                                </p>
                                <a className="service-link" href="/services/webgraphicdesign">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid" src={WebGraphicDesigningPic} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                <p>
                                    Transform your online presence with our exceptional web and graphic design services. We specialize in creating visually stunning, user-friendly websites and captivating graphics that elevate your brand. Our expert designers blend creativity with functionality, ensuring your site not only looks great but also provides an intuitive user experience. Whether you need a complete website overhaul, a unique logo, or engaging marketing materials, we tailor our solutions to meet your specific business needs. Utilizing the latest design trends and technologies, we deliver responsive, mobile-friendly websites and eye-catching graphics that stand out in today’s competitive market. Our comprehensive services include web design, graphic design, UI/UX design, branding, and print design. Enhance your brand identity, engage your audience, and drive conversions with our custom design solutions. Partner with us to create a compelling online presence that captures attention and drives success. Boost your brand with our professional web and graphic design services.
                                </p>
                                <a className="service-link" href="/services/webgraphicdesign">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* Content Writing */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Content Writing</div>
                            <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid" src={ContentWritingPic} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                <p>
                                    Enhance your brand’s voice with our expert content writing services. We specialize in creating high-quality, engaging, and SEO-optimized content that drives traffic and boosts conversions. Our team of skilled writers produces compelling blog posts, articles, website content, product descriptions, and more, tailored to your unique business needs. By conducting thorough research and understanding your target audience, we deliver content that resonates and captivates. Our services include keyword research, content strategy, copywriting, editing, and proofreading, ensuring each piece is polished and impactful. With a focus on clear, concise, and persuasive writing, we help improve your search engine rankings and establish your authority in the industry. From informative blogs to persuasive sales copy, we cover all aspects of content creation. Partner with us to elevate your online presence, engage your audience, and drive sustainable growth with our professional content writing solutions. Boost your brand with our exceptional content writing services.
                                </p>
                                <a className="service-link" href="/services/contentwriting">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* E-commerce */}
                        <div className="serviceElements">
                            <div className="serviceHeads">E-commerce</div>
                            <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                <p>
                                Boost your online sales with our specialized e-commerce services. We design and develop user-friendly, high-performance e-commerce platforms tailored to your unique business needs. Our expert team ensures your online store features secure payment gateways, seamless checkout processes, and intuitive navigation for an exceptional customer experience. Utilizing the latest technologies and design trends, we create responsive and visually appealing websites that engage customers and drive conversions. Our comprehensive e-commerce solutions include website design and development, product listing management, SEO optimization, and ongoing maintenance. We also offer integrated marketing strategies to enhance your online visibility and attract more customers. Whether you’re starting a new online store or looking to enhance an existing one, our end-to-end services ensure your success in the competitive e-commerce market. Partner with us to elevate your brand, increase sales, and achieve sustainable growth with our professional e-commerce solutions. Let us help you create an outstanding online shopping experience.
                                </p>
                                <a className="service-link" href="/services/ecommerce">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid" src={E_commerce} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                <p>
                                Boost your online sales with our specialized e-commerce services. We design and develop user-friendly, high-performance e-commerce platforms tailored to your unique business needs. Our expert team ensures your online store features secure payment gateways, seamless checkout processes, and intuitive navigation for an exceptional customer experience. Utilizing the latest technologies and design trends, we create responsive and visually appealing websites that engage customers and drive conversions. Our comprehensive e-commerce solutions include website design and development, product listing management, SEO optimization, and ongoing maintenance. We also offer integrated marketing strategies to enhance your online visibility and attract more customers. Whether you’re starting a new online store or looking to enhance an existing one, our end-to-end services ensure your success in the competitive e-commerce market. Partner with us to elevate your brand, increase sales, and achieve sustainable growth with our professional e-commerce solutions. Let us help you create an outstanding online shopping experience.
                                </p>
                                <a className="service-link" href="/services/ecommerce">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
};

export default ServicesPage;