import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { useNavigate } from "react-router-dom";
import SAH from '../assets/ServiceAreaHub.png';
import MobileNavbar from '../home/mobilenavbar';
import InquiryPage from './inquirypage';
import '../style.css' // Create a CSS file for styling


function Inquiry() {
  const navigate = useNavigate();

  const navigation = (url) => {
    window.scrollTo(0, 0);
    if(url === "home"){
      navigate("/");
    }
    else if(url === "services"){
      navigate("/services");
    }
    else if(url === "templates"){
      navigate("/templates");
    }
    else if(url === "blogs"){
      navigate("/blogs");
    }
    else if(url === "about"){
      navigate("/about");
    }
    else if(url === "contact"){
      navigate("/contact");
    }
  }
  return (
    <div>
      <div>
        <nav className="navbar" id='SAHmainNav'>
            <Nav.Link className="text-white" href={'https://serviceareahub.com'}>
            <img src={SAH} alt="..." className="logo" width="70px" height="70px" />
            {/* <Nav.Link className="text-white" href="/chikadumpa" onClick={logOut}><p id="navMenu">Log Out</p> */}
            </Nav.Link>
            <MobileNavbar/>
            <div className="navbar-collapse" id="navbarResponsive">
            {/* <ul className="navbar-tabs text-uppercase ms-auto py-4 py-lg-0"> */}
            <ul className="navbar-tabs">
                <li
                  className={`navbar-tab nav-item`}
                  onClick={() => navigation("home")}
                >
                  Home
                </li>
                <li
                className={`navbar-tab`}
                onClick={() => navigation("services")}
                >
                Services
                </li>
                <li
                className={`navbar-tab`}
                onClick={() => navigation("templates")}
                >
                Templates
                </li>
                <li
                  className={`navbar-tab nav-item`}
                  onClick={() => navigation("blogs")}
                >
                  Blogs
                </li>
                <li
                className={`navbar-tab`}
                onClick={() => navigation("about")}
                >
                About
                </li>
                {/* <li
                className={`navbar-tab`}
                onClick={() => navigation("career")}
                >
                Career
                </li> */}
                <li
                className={`navbar-tab`}
                onClick={() => navigation("contact")}
                >
                Contact
                </li>
                {/* <li
                className={`navbar-tab ${selectedTab === 'inquiryation' ? 'active' : ''}`}
                onClick={() => handleTabClick('inquiryation')}
                >
                Try FREE inquiryation
                </li> */}
            </ul>
            <Nav.Link className="inquiryButton" onClick={() => navigate("/inquiry")}>
                <span id='activeinquiry'>Get a FREE quote</span>
                {/* <span id='inquiry'>Try FREE inquiryation</span> */}
            {/* Get a FREE inquiryation */}
            </Nav.Link>
            </div>
        </nav>  
      </div>

      <div id='content1'>
        <InquiryPage/>
      </div>
    </div>
  );
}

export default Inquiry;