import React, { useState, useEffect, useRef } from "react";
import "../home/homestyle.css";

const InquiryPage = () => {
    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [domainLink, setDomainLink] = useState('');
    const [web, setWeb] = useState('');
    const [mbapp, setMbapp] = useState('');
    const [mrkt, setMrkt] = useState('');
    const [webgraph, setWebgraph] = useState('');
    const [cntnt, setCntnt] = useState('');
    const [ecom, setEcom] = useState('');
    const [briefDec, setBriefDec] = useState('');
    const [declaration, setDeclaration] = useState('');
    const [nameMsg, setNameMsg] = useState('');
    const [countryMsg, setCountryMsg] = useState('');
    const [phoneMsg, setContactMsg] = useState('');
    const [emailMsg, setEmailMsg] = useState('');
    const [workMsg, setWorkMsg] = useState('');
    const [msg, setMsg] = useState('');
    // const [formSubmitBtn, setFormSubmitBtn] = useState(true);  // Disabling the submit button till it filling up all the mandetory fields
    // const [formSubmissionTrigger, setFormSubmissionTrigger] = useState(false); // State to trigger useEffect

    // Use refs to access the input elements
    const nameRef = useRef("");
    const countryRef = useRef("");
    const phoneRef = useRef("");
    const emailRef = useRef("");
    const workRef = useRef("");

    useEffect(() => {

        document.title = 'Book a Free Quote | ServiceAreaHub';

        if (web || mbapp || mrkt || webgraph || cntnt || ecom) {
            setWorkMsg('');
        }
        
        if (declaration){
            setMsg("")
        }

        // if (
        //     name !== '' &&
        //     email !== '' &&
        //     country !== '' &&
        //     contact !== '' &&
        //     (web || mbapp || mrkt || webgraph || cntnt || ecom)
        // ) {
        //     setFormSubmitBtn(false);
        // } else {
        //     setFormSubmitBtn(true);
        // }
    
    }, [name, email, country, contact, web, mbapp, mrkt, webgraph, cntnt, ecom, briefDec, declaration]);
    
      
    const backDomain = "https://sahbackend-vusp.onrender.com";
    // const backDomain = "http://localhost:8080";

    const formSubmission = (e) => {
        e.preventDefault();

        if (!name) {
            setNameMsg('Please provide your name');
            nameRef.current.focus();
        }
        else if (!country) {
            setCountryMsg('Please select your country');
            countryRef.current.focus();
        }
        else if (!contact) {
            setContactMsg('Please provide your contact number');
            phoneRef.current.focus();
        }
        else if (!email) {
            setEmailMsg('Please provide your email ID');
            emailRef.current.focus();
        }
        else if (!web && !mbapp && !mrkt && !webgraph && !cntnt && !ecom) {
            setWorkMsg('Please select your project need');
            workRef.current.focus();
        }
        else if (!declaration) {
            setMsg('Please select the Privacy Policy checkbox');
            // workRef.current.focus();
        }

        else {
            let finalEmail = '';
            let splChr = 0;
            let atTheRate = 0;
            let str = email;
            let atTheRatePosition = 0;
            let dotCount = 0;

            for (let i = 0; i < str.length; ++i) {
                let ch = str.charCodeAt(i);
                if (
                    !(ch >= 65 && ch <= 90) && // A-Z
                    !(ch >= 97 && ch <= 122) && // a-z
                    !(ch >= 48 && ch <= 57) && // 0-9
                    !(ch === 64) && // @
                    !(ch === 46) && // .
                    !(ch === 95) && // _
                    !(ch === 45) // -
                ) {
                    splChr = 1;
                }
            }

            for (let j = 0; j < str.length; ++j) {
                let ch = str[j];
                if (ch === "@") {
                    atTheRate += 1;
                    atTheRatePosition = j;
                }
            }

            if (atTheRate === 1) {
                for (let j = atTheRatePosition + 2; j < str.length - 1; ++j) {
                    let ch = str[j];
                    if (ch === ".") {
                        dotCount += 1;
                    }
                }
            }

            if ((email.includes('@')) && (email.includes('.')) && (!email.includes(' '))) {
                if ((splChr === 0) && (atTheRate === 1) && (dotCount === 1)) {
                    setEmailMsg('');
                    finalEmail = email;
                } else {
                    setEmailMsg("Use the standard username format, such as name@example.com");
                    emailRef.current.focus();
                }
            } else {
                setEmailMsg("Use the standard username format, such as name@example.com");
                emailRef.current.focus();
            }

            let finalContact = '';
            if (!isNaN(contact)) {
                if (contact.length === 10) {
                    setContactMsg('');
                    finalContact = contact;
                } else {
                    setContactMsg("Use the standard 10 digit mobile number format, such as 9876543210");
                    phoneRef.current.focus();
                }
            } else if (contact.includes(' ')) {
                setContactMsg("Space is not allowed, use the standard 10 digit mobile number format, such as 9876543210");
                phoneRef.current.focus();
            } else {
                setContactMsg("Use the standard 10 digit mobile number format, such as 9876543210");
                phoneRef.current.focus();
            }

            const today = new Date();
            const day = today.toLocaleString('en-US', { weekday: 'long' });
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            const yyyy = today.getFullYear();
            const finalDate = `${dd}/${mm}/${yyyy} (${day})`;

            // let mulQueries = '';
            // let webQuery = web ? "web development" : "none";
            // let mrktQuery = mrkt ? "digital marketing" : "none";
            // let contentQuery = cntnt ? "content writing" : "none";

            // if (web && mrkt && cntnt) {
            //     mulQueries = "web development and digital marketing and content writing";
            // } else if (web && mrkt) {
            //     mulQueries = "web development and digital marketing";
            // } else if (web && cntnt) {
            //     mulQueries = "web development and content writing";
            // } else if (mrkt && cntnt) {
            //     mulQueries = "digital marketing and content writing";
            // } else if (web) {
            //     mulQueries = "web development";
            // } else if (mrkt) {
            //     mulQueries = "digital marketing";
            // } else if (cntnt) {
            //     mulQueries = "content writing";
            // }
                    
            let finalWeb = "none";
            let finalMbApp = "none";
            let finalMrkt = "none";
            let finalWebGraph = "none";
            let finalCntnt = "none";
            let finalEcom = "none";

            if (web === true){
                finalWeb = "web_development";
            }
            if (mbapp === true){
                finalMbApp = "mobile_app_development";
            }
            if (mrkt === true){
                finalMrkt = "digital_marketing_SEO";
            }
            if (webgraph === true){
                finalWebGraph = "web_graphic_design";
            }
            if (cntnt === true){
                finalCntnt = "content_writing";
            }
            if (ecom === true){
                finalEcom = "e_commerce";
            }

            const userData = {
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    name: name,
                    email: finalEmail,
                    country: country,
                    contact: finalContact,
                    domainLink: domainLink,
                    webQuery: finalWeb,
                    mbappQuery: finalMbApp,
                    mrktQuery: finalMrkt,
                    webgraphQuery: finalWebGraph,
                    cntntQuery: finalCntnt,
                    ecomQuery: finalEcom,
                    // mulQueries: mulQueries,
                    // webQuery: webQuery,
                    // mrktQuery: mrktQuery,
                    // contentQuery: contentQuery,
                    date: finalDate,
                    queryDesc: briefDec,
                }),
            };

            
            fetch(backDomain + "/form/submition", userData)
                .then((response) => response.json())
                .then((json) => {
                    if (json.message === "Thanks for showing interest. We will get back to you shortly!") {
                        alert("Thanks for showing interest. We will get back to you shortly!");

                        setMsg("");
                        setName("");
                        setEmail("");
                        setCountry("");
                        setContact("");
                        setDomainLink("");
                        setWeb(false);
                        setMbapp(false);
                        setMrkt(false);
                        setWebgraph(false);
                        setCntnt(false);
                        setEcom(false);
                        setBriefDec("");
                        setDeclaration(false);
                    } else {
                        setMsg(`${json.message}`);
                    }
                });

            return false;
        }
    };
    
    


    return(
        <section className="page-section" id="contact">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading sectionHead text-uppercase">Book a Free Quote</h2>
                    <p className="section-subheading">Fill the form to explain your project briefly. Or just mail us at{" "}
                        <a href="mailto:support@serviceareahub.com?subject=Request%20for%20a%20free%20quote&body=Dear%20SAH%20team,%0D%0A%0D%0AI%20would%20like%20to%20ask%20for%20a%20free%20quote%20from%20ServiceAreaHub%20regarding...%0D%0A%0D%0AThanks%20with%20regards,%0D%0A" className="section-subheading-mail"> 
                        support@serviceareahub.com
                        </a>
                        {" "}to share your business expectations, ideas, and needs with us.</p>
                    {/* <h3 className="section-subheading text-muted"></h3> */}
                </div>
                <div className="text-center" style={{paddingTop: "50px"}}>
                </div>
                {/* * * * * * * * * * * * * * * **/}
                {/* * * SB Forms Contact Form * **/}
                {/* * * * * * * * * * * * * * * **/}
                {/* This form is pre-integrated with SB Forms.*/}
                {/* To make this form functional, sign up at*/}
                {/* https://startbootstrap.com/solution/contact-forms*/}
                {/* to get an API token!*/}
                {/* <form onSubmit=formSubmition() id="contactForm" data-sb-form-api-token="API_TOKEN"> */}
                <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                    <div className="row align-items-stretch mb-5">
                        <div className="col-md-6">
                            <div className="form-group">
                                {/* Name input*/}
                                <input 
                                    className="form-control" 
                                    id="name" 
                                    type="text" 
                                    placeholder="Your Name *"  
                                    data-sb-validations="required" 
                                    autoFocus 
                                    required 
                                    value={name} 
                                    onChange={(e) => {
                                        setName(e.target.value);    // Update name state
                                        setNameMsg('');             // Clear nameMsg when name value changes
                                    }} 
                                    ref={nameRef} 
                                />
                                <p style={{"color": "whitesmoke", textAlign: 'left', textShadow: '0 0 5px #bf0a30, 0 0 10px #bf0a30, 0 0 15px #bf0a30, 0 0 20px #bf0a30, 0 0 25px #bf0a30'}} id="nameMsg">{nameMsg}</p>
                            </div>
                            
                            <div className="form-group">
                                {/* Country selection*/}
                                <select 
                                    id="country" 
                                    data-sb-validations="required" 
                                    required 
                                    style={{"width": "100%", "height": "65px", "textAlign": "left", "borderRadius": "5px", cursor: "pointer", "paddingLeft": "2.5%"}} 
                                    value={country} 
                                    onChange={(e) => {
                                        setCountry(e.target.value);    // Update country state
                                        setCountryMsg('');             // Clear countryMsg when country value changes
                                    }} 
                                    ref={countryRef} 
                                >
                                    <option value="">Select Country *</option>
                                    <option value="India (+91)">India (+91)</option>
                                    <option value="Afghanistan (+93)">Afghanistan (+93)</option>
                                    <option value="Albania (+355)">Albania (+355)</option>
                                    <option value="Algeria (+213)">Algeria (+213)</option>
                                    <option value="American Samoa (+1-684)">American Samoa (+1-684)</option>
                                    <option value="Andorra (+376)">Andorra (+376)</option>
                                    <option value="Angola (+244)">Angola (+244)</option>
                                    <option value="Anguilla (+1-264)">Anguilla (+1-264)</option>
                                    <option value="Antigua and Barbuda (+1-268)">Antigua and Barbuda (+1-268)</option>
                                    <option value="Argentina (+54)">Argentina (+54)</option>
                                    <option value="Armenia (+374)">Armenia (+374)</option>
                                    <option value="Aruba (+297)">Aruba (+297)</option>
                                    <option value="Australia (+61)">Australia (+61)</option>
                                    <option value="Austria (+43)">Austria (+43)</option>
                                    <option value="Azerbaijan (+994)">Azerbaijan (+994)</option>
                                    <option value="Bahamas (+1-242)">Bahamas (+1-242)</option>
                                    <option value="Bahrain (+973)">Bahrain (+973)</option>
                                    <option value="Bangladesh (+880)">Bangladesh (+880)</option>
                                    <option value="Barbados (+1-246)">Barbados (+1-246)</option>
                                    <option value="Belarus (+375)">Belarus (+375)</option>
                                    <option value="Belgium (+32)">Belgium (+32)</option>
                                    <option value="Belize (+501)">Belize (+501)</option>
                                    <option value="Benin (+229)">Benin (+229)</option>
                                    <option value="Bermuda (+1-441)">Bermuda (+1-441)</option>
                                    <option value="Bhutan (+975)">Bhutan (+975)</option>
                                    <option value="Bolivia (+591)">Bolivia (+591)</option>
                                    <option value="Bosnia and Herzegovina (+387)">Bosnia and Herzegovina (+387)</option>
                                    <option value="Botswana (+267)">Botswana (+267)</option>
                                    <option value="Brazil (+55)">Brazil (+55)</option>
                                    <option value="British Indian Ocean Territory (+246)">British Indian Ocean Territory (+246)</option>
                                    <option value="Brunei Darussalam (+673)">Brunei Darussalam (+673)</option>
                                    <option value="Bulgaria (+359)">Bulgaria (+359)</option>
                                    <option value="Burkina Faso (+226)">Burkina Faso (+226)</option>
                                    <option value="Burundi (+257)">Burundi (+257)</option>
                                    <option value="Cambodia (+855)">Cambodia (+855)</option>
                                    <option value="Cameroon (+237)">Cameroon (+237)</option>
                                    <option value="Canada (+1)">Canada (+1)</option>
                                    <option value="Cape Verde (+238)">Cape Verde (+238)</option>
                                    <option value="Cayman Islands (+1-345)">Cayman Islands (+1-345)</option>
                                    <option value="Central African Republic (+236)">Central African Republic (+236)</option>
                                    <option value="Chad (+235)">Chad (+235)</option>
                                    <option value="Chile (+56)">Chile (+56)</option>
                                    <option value="China (+86)">China (+86)</option>
                                    <option value="Christmas Island (+61)">Christmas Island (+61)</option>
                                    <option value="Cocos (Keeling) Islands (+61)">Cocos (Keeling) Islands (+61)</option>
                                    <option value="Colombia (+57)">Colombia (+57)</option>
                                    <option value="Comoros (+269)">Comoros (+269)</option>
                                    <option value="Congo (+242)">Congo (+242)</option>
                                    <option value="Congo, Democratic Republic of the Congo (+243)">Congo, Democratic Republic of the Congo (+243)</option>
                                    <option value="Cook Islands (+682)">Cook Islands (+682)</option>
                                    <option value="Costa Rica (+506)">Costa Rica (+506)</option>
                                    <option value="Cote d'Ivoire (+225)">Cote d'Ivoire (+225)</option>
                                    <option value="Croatia (+385)">Croatia (+385)</option>
                                    <option value="Cuba (+53)">Cuba (+53)</option>
                                    <option value="Cyprus (+357)">Cyprus (+357)</option>
                                    <option value="Czech Republic (+420)">Czech Republic (+420)</option>
                                    <option value="Denmark (+45)">Denmark (+45)</option>
                                    <option value="Djibouti (+253)">Djibouti (+253)</option>
                                    <option value="Dominica (+1-767)">Dominica (+1-767)</option>
                                    <option value="Dominican Republic (+1-809)">Dominican Republic (+1-809)</option>
                                    <option value="East Timor (+670)">East Timor (+670)</option>
                                    <option value="Ecuador (+593)">Ecuador (+593)</option>
                                    <option value="Egypt (+20)">Egypt (+20)</option>
                                    <option value="El Salvador (+503)">El Salvador (+503)</option>
                                    <option value="Equatorial Guinea (+240)">Equatorial Guinea (+240)</option>
                                    <option value="Eritrea (+291)">Eritrea (+291)</option>
                                    <option value="Estonia (+372)">Estonia (+372)</option>
                                    <option value="Ethiopia (+251)">Ethiopia (+251)</option>
                                    <option value="Falkland Islands (Malvinas) (+500)">Falkland Islands (Malvinas) (+500)</option>
                                    <option value="Faroe Islands (+298)">Faroe Islands (+298)</option>
                                    <option value="Fiji (+679)">Fiji (+679)</option>
                                    <option value="Finland (+358)">Finland (+358)</option>
                                    <option value="France (+33)">France (+33)</option>
                                    <option value="French Guiana (+594)">French Guiana (+594)</option>
                                    <option value="French Polynesia (+689)">French Polynesia (+689)</option>
                                    <option value="French Southern Territories (+262)">French Southern Territories (+262)</option>
                                    <option value="Gabon (+241)">Gabon (+241)</option>
                                    <option value="Gambia (+220)">Gambia (+220)</option>
                                    <option value="Georgia (+995)">Georgia (+995)</option>
                                    <option value="Germany (+49)">Germany (+49)</option>
                                    <option value="Ghana (+233)">Ghana (+233)</option>
                                    <option value="Gibraltar (+350)">Gibraltar (+350)</option>
                                    <option value="Greece (+30)">Greece (+30)</option>
                                    <option value="Greenland (+299)">Greenland (+299)</option>
                                    <option value="Grenada (+1-473)">Grenada (+1-473)</option>
                                    <option value="Guadeloupe (+590)">Guadeloupe (+590)</option>
                                    <option value="Guam (+1-671)">Guam (+1-671)</option>
                                    <option value="Guatemala (+502)">Guatemala (+502)</option>
                                    <option value="Guinea (+224)">Guinea (+224)</option>
                                    <option value="Guinea-Bissau (+245)">Guinea-Bissau (+245)</option>
                                    <option value="Guyana (+592)">Guyana (+592)</option>
                                    <option value="Haiti (+509)">Haiti (+509)</option>                                    
                                    <option value="Honduras (+504)">Honduras (+504)</option>
                                    <option value="Hong Kong (+852)">Hong Kong (+852)</option>
                                    <option value="Hungary (+36)">Hungary (+36)</option>
                                    <option value="Iceland (+354)">Iceland (+354)</option>
                                    <option value="Indonesia (+62)">Indonesia (+62)</option>
                                    <option value="Iran (+98)">Iran (+98)</option>
                                    <option value="Iraq (+964)">Iraq (+964)</option>
                                    <option value="Ireland (+353)">Ireland (+353)</option>
                                    <option value="Israel (+972)">Israel (+972)</option>
                                    <option value="Italy (+39)">Italy (+39)</option>
                                    <option value="Jamaica (+1-876)">Jamaica (+1-876)</option>
                                    <option value="Japan (+81)">Japan (+81)</option>
                                    <option value="Jordan (+962)">Jordan (+962)</option>
                                    <option value="Kazakhstan (+7)">Kazakhstan (+7)</option>
                                    <option value="Kenya (+254)">Kenya (+254)</option>
                                    <option value="Kiribati (+686)">Kiribati (+686)</option>
                                    <option value="Kuwait (+965)">Kuwait (+965)</option>
                                    <option value="Kyrgyzstan (+996)">Kyrgyzstan (+996)</option>
                                    <option value="Laos (+856)">Laos (+856)</option>
                                    <option value="Latvia (+371)">Latvia (+371)</option>
                                    <option value="Lebanon (+961)">Lebanon (+961)</option>
                                    <option value="Lesotho (+266)">Lesotho (+266)</option>
                                    <option value="Liberia (+231)">Liberia (+231)</option>
                                    <option value="Libya (+218)">Libya (+218)</option>
                                    <option value="Liechtenstein (+423)">Liechtenstein (+423)</option>
                                    <option value="Lithuania (+370)">Lithuania (+370)</option>
                                    <option value="Luxembourg (+352)">Luxembourg (+352)</option>
                                    <option value="Macau (+853)">Macau (+853)</option>
                                    <option value="Madagascar (+261)">Madagascar (+261)</option>
                                    <option value="Malawi (+265)">Malawi (+265)</option>
                                    <option value="Malaysia (+60)">Malaysia (+60)</option>
                                    <option value="Maldives (+960)">Maldives (+960)</option>
                                    <option value="Mali (+223)">Mali (+223)</option>
                                    <option value="Malta (+356)">Malta (+356)</option>
                                    <option value="Marshall Islands (+692)">Marshall Islands (+692)</option>
                                    <option value="Martinique (+596)">Martinique (+596)</option>
                                    <option value="Mauritania (+222)">Mauritania (+222)</option>
                                    <option value="Mauritius (+230)">Mauritius (+230)</option>
                                    <option value="Mayotte (+262)">Mayotte (+262)</option>
                                    <option value="Mexico (+52)">Mexico (+52)</option>
                                    <option value="Micronesia (+691)">Micronesia (+691)</option>
                                    <option value="Moldova (+373)">Moldova (+373)</option>
                                    <option value="Monaco (+377)">Monaco (+377)</option>
                                    <option value="Mongolia (+976)">Mongolia (+976)</option>
                                    <option value="Montserrat (+1-664)">Montserrat (+1-664)</option>
                                    <option value="Morocco (+212)">Morocco (+212)</option>
                                    <option value="Mozambique (+258)">Mozambique (+258)</option>
                                    <option value="Myanmar (+95)">Myanmar (+95)</option>
                                    <option value="Namibia (+264)">Namibia (+264)</option>
                                    <option value="Nauru (+674)">Nauru (+674)</option>
                                    <option value="Nepal (+977)">Nepal (+977)</option>
                                    <option value="Netherlands (+31)">Netherlands (+31)</option>
                                    <option value="Netherlands Antilles (+599)">Netherlands Antilles (+599)</option>
                                    <option value="New Caledonia (+687)">New Caledonia (+687)</option>
                                    <option value="New Zealand (+64)">New Zealand (+64)</option>
                                    <option value="Nicaragua (+505)">Nicaragua (+505)</option>
                                    <option value="Niger (+227)">Niger (+227)</option>
                                    <option value="Nigeria (+234)">Nigeria (+234)</option>
                                    <option value="Niue (+683)">Niue (+683)</option>
                                    <option value="Norfolk Island (+672)">Norfolk Island (+672)</option>
                                    <option value="North Korea (+850)">North Korea (+850)</option>
                                    <option value="North Macedonia (+389)">North Macedonia (+389)</option>
                                    <option value="Northern Mariana Islands (+1-670)">Northern Mariana Islands (+1-670)</option>
                                    <option value="Norway (+47)">Norway (+47)</option>
                                    <option value="Oman (+968)">Oman (+968)</option>
                                    <option value="Pakistan (+92)">Pakistan (+92)</option>
                                    <option value="Palau (+680)">Palau (+680)</option>
                                    <option value="Panama (+507)">Panama (+507)</option>
                                    <option value="Papua New Guinea (+675)">Papua New Guinea (+675)</option>
                                    <option value="Paraguay (+595)">Paraguay (+595)</option>
                                    <option value="Peru (+51)">Peru (+51)</option>
                                    <option value="Philippines (+63)">Philippines (+63)</option>
                                    <option value="Pitcairn (+870)">Pitcairn (+870)</option>
                                    <option value="Poland (+48)">Poland (+48)</option>
                                    <option value="Portugal (+351)">Portugal (+351)</option>
                                    <option value="Puerto Rico (+1-787)">Puerto Rico (+1-787)</option>
                                    <option value="Qatar (+974)">Qatar (+974)</option>
                                    <option value="Reunion (+262)">Reunion (+262)</option>
                                    <option value="Romania (+40)">Romania (+40)</option>
                                    <option value="Russia (+7)">Russia (+7)</option>
                                    <option value="Rwanda (+250)">Rwanda (+250)</option>
                                    <option value="Saint Kitts and Nevis (+1-869)">Saint Kitts and Nevis (+1-869)</option> 
                                    <option value="Saint Lucia (+1-758)">Saint Lucia (+1-758)</option>
                                    <option value="Saint Vincent and the Grenadines (+1-784)">Saint Vincent and the Grenadines (+1-784)</option>
                                    <option value="Samoa (+685)">Samoa (+685)</option>
                                    <option value="San Marino (+378)">San Marino (+378)</option>
                                    <option value="Sao Tome and Principe (+239)">Sao Tome and Principe (+239)</option> 
                                    <option value="Saudi Arabia (+966)">Saudi Arabia (+966)</option>
                                    <option value="Senegal (+221)">Senegal (+221)</option>
                                    <option value="Seychelles (+248)">Seychelles (+248)</option>
                                    <option value="Sierra Leone (+232)">Sierra Leone (+232)</option>
                                    <option value="Singapore (+65)">Singapore (+65)</option>
                                    <option value="Slovakia (+421)">Slovakia (+421)</option>
                                    <option value="Slovenia (+386)">Slovenia (+386)</option>
                                    <option value="Solomon Islands (+677)">Solomon Islands (+677)</option>
                                    <option value="Somalia (+252)">Somalia (+252)</option>
                                    <option value="South Africa (+27)">South Africa (+27)</option>
                                    <option value="South Georgia and the South Sandwich Islands (+500)">South Georgia and the South Sandwich Islands (+500)</option>
                                    <option value="South Korea (+82)">South Korea (+82)</option>
                                    <option value="Spain (+34)">Spain (+34)</option>
                                    <option value="Sri Lanka (+94)">Sri Lanka (+94)</option>
                                    <option value="St. Helena (+290)">St. Helena (+290)</option>
                                    <option value="St. Pierre and Miquelon (+508)">St. Pierre and Miquelon (+508)</option>
                                    <option value="Sudan (+249)">Sudan (+249)</option>
                                    <option value="Suriname (+597)">Suriname (+597)</option>
                                    <option value="Svalbard and Jan Mayen Islands (+47)">Svalbard and Jan Mayen Islands (+47)</option>
                                    <option value="Swaziland (+268)">Swaziland (+268)</option>
                                    <option value="Sweden (+46)">Sweden (+46)</option>
                                    <option value="Switzerland (+41)">Switzerland (+41)</option>
                                    <option value="Syria (+963)">Syria (+963)</option>
                                    <option value="Taiwan (+886)">Taiwan (+886)</option>
                                    <option value="Tajikistan (+992)">Tajikistan (+992)</option>
                                    <option value="Tanzania (+255)">Tanzania (+255)</option>
                                    <option value="Thailand (+66)">Thailand (+66)</option>
                                    <option value="Togo (+228)">Togo (+228)</option>
                                    <option value="Tokelau (+690)">Tokelau (+690)</option>
                                    <option value="Tonga (+676)">Tonga (+676)</option>
                                    <option value="Trinidad and Tobago (+1-868)">Trinidad and Tobago (+1-868)</option>
                                    <option value="Tunisia (+216)">Tunisia (+216)</option>
                                    <option value="Turkey (+90)">Turkey (+90)</option>
                                    <option value="Turkmenistan (+993)">Turkmenistan (+993)</option>
                                    <option value="Turks and Caicos Islands (+1-649)">Turks and Caicos Islands (+1-649)</option>
                                    <option value="Tuvalu (+688)">Tuvalu (+688)</option>
                                    <option value="Uganda (+256)">Uganda (+256)</option>
                                    <option value="Ukraine (+380)">Ukraine (+380)</option>
                                    <option value="United Arab Emirates (+971)">United Arab Emirates (+971)</option>
                                    <option value="United Kingdom (+44)">United Kingdom (+44)</option>
                                    <option value="United States (+1)">United States (+1)</option>
                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                    <option value="Uruguay (+598)">Uruguay (+598)</option>
                                    <option value="Uzbekistan (+998)">Uzbekistan (+998)</option>
                                    <option value="Vanuatu (+678)">Vanuatu (+678)</option>
                                    <option value="Vatican City (+379)">Vatican City (+379)</option>
                                    <option value="Venezuela (+58)">Venezuela (+58)</option>
                                    <option value="Vietnam (+84)">Vietnam (+84)</option>
                                    <option value="Virgin Islands (British) (+1-284)">Virgin Islands (British) (+1-284)</option>
                                    <option value="Virgin Islands (U.S.) (+1-340)">Virgin Islands (U.S.) (+1-340)</option>
                                    <option value="Wallis and Futuna Islands (+681)">Wallis and Futuna Islands (+681)</option>
                                    <option value="Western Sahara (+212)">Western Sahara (+212)</option>
                                    <option value="Yemen (+967)">Yemen (+967)</option>
                                    <option value="Serbia (+381)">Serbia (+381)</option>
                                    <option value="Zambia (+260)">Zambia (+260)</option>
                                    <option value="Zimbabwe (+263)">Zimbabwe (+263)</option>
                                </select>
                                <p style={{"color": "whitesmoke", textAlign: 'left', textShadow: '0 0 5px #bf0a30, 0 0 10px #bf0a30, 0 0 15px #bf0a30, 0 0 20px #bf0a30, 0 0 25px #bf0a30'}} id="countryMsg">{countryMsg}</p>
                            </div>

                            
                            <div className="form-group mb-md-0">
                                {/* Contact number input*/}
                                <input 
                                    className="form-control" 
                                    id="contact" 
                                    type="tel"  
                                    placeholder="Your Mobile Number *" 
                                    pattern="[0-9]{10}" 
                                    maxLength="10" 
                                    required 
                                    data-sb-validations="required" 
                                    value={contact} 
                                    onChange={(e) => {
                                        setContact(e.target.value);    // Update contact state
                                        setContactMsg('');             // Clear contactMsg when contact value changes
                                    }} 
                                    ref={phoneRef}
                                />
                                <p style={{"color": "whitesmoke", textAlign: 'left', textShadow: '0 0 5px #bf0a30, 0 0 10px #bf0a30, 0 0 15px #bf0a30, 0 0 20px #bf0a30, 0 0 25px #bf0a30'}} id="phoneMsg">{phoneMsg}</p>
                                {/* <div className="invalid-feedback" data-sb-feedback="contact:required">A contact number is required.</div> */}
                            </div>

                            <div className="form-group" style={{paddingTop: "8px"}}>
                                {/* Email address input*/}
                                <input 
                                    className="form-control" 
                                    id="email" 
                                    type="email" 
                                    placeholder="Your Email *"  
                                    data-sb-validaions="required, email" 
                                    required 
                                    value={email} 
                                    onChange={(e) => {
                                        setEmail(e.target.value);    // Update email state
                                        setEmailMsg('');             // Clear emailMsg when email value changes
                                    }} 
                                    ref={emailRef}
                                />
                                <p style={{"color": "whitesmoke", textAlign: 'left', textShadow: '0 0 5px #bf0a30, 0 0 10px #bf0a30, 0 0 15px #bf0a30, 0 0 20px #bf0a30, 0 0 25px #bf0a30'}} id="emailMsg">{emailMsg}</p>
                            </div>
                            
                            {/* checkbox of services */}
                            {/* <form action="/submit" method="post"> */}
                            <div className="freeconsultcheck" required>
                                <div style={{color: 'whitesmoke', fontSize: 33, fontWeight: 'bold', textDecoration: 'underline', textAlign: 'left'}}>
                                    Select your need*:
                                </div>
                                <label className="freeconsultcheckfont">
                                    <input style={{"transform": "scale(2)", "marginRight": "10px", accentColor: '#e34a50'}}  type="checkbox" id="web" checked={web} onChange={(e) => setWeb(e.target.checked)} ref={workRef} />
                                    {/* <input style="transform: scale(2); marginRight: 15px;" type="checkbox" name="option1" id="web" value="option1" onclick="webDevChange()"> */}
                                    Website Creation
                                </label><br/>
                                <label className="freeconsultcheckfont">
                                    <input style={{"transform": "scale(2)", "marginRight": "10px", accentColor: '#e34a50'}}  type="checkbox" id="mbapp" checked={mbapp} onChange={(e) => setMbapp(e.target.checked)}/>
                                    {/* <input style="transform: scale(2); marginRight: 15px;" type="checkbox" name="option1" id="web" value="option1" onclick="webDevChange()"> */}
                                    Mobile App Development
                                </label><br/>
                                <label className="freeconsultcheckfont">
                                    <input style={{"transform": "scale(2)", "marginRight": "10px", accentColor: '#e34a50'}}  type="checkbox" id="mrkt" checked={mrkt} onChange={(e) => setMrkt(e.target.checked)}/>
                                    {/* <input style="transform: scale(2); marginRight: 15px;" type="checkbox" name="option2" id="mrkt" value="option2" onclick="digMarkChange()"> */}
                                    Digital Marketing & SEO
                                </label><br/>
                                <label className="freeconsultcheckfont">
                                    <input style={{"transform": "scale(2)", "marginRight": "10px", accentColor: '#e34a50'}}  type="checkbox" id="webgraph" checked={webgraph} onChange={(e) => setWebgraph(e.target.checked)}/>
                                    {/* <input style="transform: scale(2); marginRight: 15px;" type="checkbox" name="option2" id="mrkt" value="option2" onclick="digMarkChange()"> */}
                                    Web & Graphic Design
                                </label>
                                <br />
                                <label className="freeconsultcheckfont">
                                    <input style={{"transform": "scale(2)", "marginRight": "10px", accentColor: '#e34a50'}}  type="checkbox" id="cntnt" checked={cntnt} onChange={(e) => setCntnt(e.target.checked)}/>
                                    {/* <input style={{"transform": "scale(2)", "marginRight": "15px"}}  type="checkbox" id="content" value="content"/> */}
                                    {/* <input style="transform: scale(2); marginRight: 15px;" type="checkbox" name="option3" id="content" value="option3" onclick="cntWrtChange()"> */}
                                    Content Writing
                                </label><br/>
                                <label className="freeconsultcheckfont">
                                    <input style={{"transform": "scale(2)", "marginRight": "10px", accentColor: '#e34a50'}}  type="checkbox" id="ecom" checked={ecom} onChange={(e) => setEcom(e.target.checked)}/>
                                    {/* <input style="transform: scale(2); marginRight: 15px;" type="checkbox" name="option2" id="mrkt" value="option2" onclick="digMarkChange()"> */}
                                    E-commerce
                                </label><br/>
                                <p className="freeconsultcheckfont" style={{"color": "whitesmoke", textAlign: 'left', fontSize: '18px', textShadow: '0 0 5px #bf0a30, 0 0 10px #bf0a30, 0 0 15px #bf0a30, 0 0 20px #bf0a30, 0 0 25px #bf0a30'}} id="workMsg">{workMsg}</p>
                            </div>
                            <br/>
                        </div>


                        <div className="col-md-6 domain-message-div">
                            <div className="form-group">
                                {/* Domain Link */}
                                <input className="form-control" id="domain" type="text"  placeholder="Your Website Address [https://] (Optional)" value={domainLink} onChange={(e) => setDomainLink(e.target.value)} />
                            </div>
                            {/* className="form-group-textarea" */}
                            <div className="form-group mb-md-0 messageDiv">
                                {/* Message input*/}
                                <textarea className="form-control" id="message" placeholder="Describe Your Need (Optional)" value={briefDec} onChange={(e) => setBriefDec(e.target.value)}></textarea>
                                {/* <div className="invalid-feedback">A message is required.</div> */}
                            </div>
                        </div>
                    </div>
                    {/* Submit success message*/}
                    {/**/}
                    {/* This is what your users will see when the form*/}
                    {/* has successfully submitted*/}
                    <div className="d-none" id="submitSuccessMessage">
                        <div className="text-center text-white mb-3">                        
                            <br />
                            <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                        </div>
                    </div>
                   
                    

                    {/* Declaration Check Box */}
                    <label className="declarationcheckfont" style={{ display: 'inline', alignItems: 'center', width: '100%' }}>
                        <input 
                            style={{ 
                                transform: "scale(2)", 
                                color: "whitesmoke", 
                                marginRight: "15px", 
                                accentColor: '#e34a50',
                            }} 
                            type="checkbox" 
                            id="declaration" 
                            name="declaration" 
                            checked={declaration} 
                            onChange={(e) => setDeclaration(e.target.checked)}
                            // onChange={handleChange} 
                            // ref={declarationRef} 
                        />
                        <span 
                        className="declarationcheckfont"
                        style={{
                            color: "whitesmoke", 
                            cursor: "pointer", 
                            margin: 0, 
                            padding: 0,
                        }}
                        >
                            I give my consent for ServiceAreaHub to reach out to me using the details I've shared above. I agree to the handling of my personal information as described in the 
                        </span>
                    </label>
                    <span 
                        className="declarationcheckfont"
                        style={{
                            color: "#eb2639", 
                            cursor: "pointer", 
                            margin: 0, 
                            padding: 0,
                        }} 
                        onClick={() => { window.open("/privacy-policy/", "_blank"); }}
                    >
                        ServiceAreaHub's Privacy Policy
                    </span>
                    <span 
                        className="declarationcheckfont"
                        style={{
                            color: "whitesmoke", 
                            cursor: "pointer", 
                            margin: 0, 
                            padding: 0,
                        }}
                        >.</span>


                    <br/>
                    <div style={{"color": "#eb2639", "borderColor": "transparent"}} className="btn-primary btn-xl text-uppercase" id="msg">{msg}</div>
                    <br/>

                    <div className="text-center">
                        <button 
                            style={{"backgroundColor": "#bf0a30", "borderColor": "transparent", width: "170px", height: "50px", borderRadius: "10px"}} 
                            // className="btn btn-primary btn-xl text-uppercase" 
                            className="btn btn-primary text-uppercase" 
                            id="formSubmitButton"
                            // disabled={formSubmitBtn}
                            // onClick={setFormSubmissionTrigger(true)}
                            onClick={formSubmission}
                        >
                                CONFIRM QUOTE
                        </button>
                    </div>
                    {/* <div className="text-center"><button style={{"backgroundColor": "#eb2639", "borderColor": "transparent"}} className="btn btn-primary btn-xl text-uppercase" id="formSubmitButton" onclick={formSubmition()}>Seal the Deal</button></div> */}

                    {/* <div className="text-center"><button style="backgroundColor: #eb2639; borderColor: transparent;" className="btn btn-primary btn-xl text-uppercase" id="formSubmitButton" type="submit">Send Message</button></div> */}
                </form>
            </div>
            {/* <div className="freeQuoteFooterBottom">
                <a style={{ color: "whitesmoke" }} className="text-decoration-none me-3" href="#!">Privacy Policy</a>
                <a style={{ color: "whitesmoke" }} className="text-decoration-none me-3" href="/career">Career</a>
                <a style={{ color: "whitesmoke" }} className="text-decoration-none" href="#!">Terms of Use</a>
                <p>Copyright &copy; ServiceAreaHub 2024</p>
            </div> */}
        </section>
    )
};

export default InquiryPage;