import React, { useEffect } from "react";
import Navbar from "./navbar";
import startUp from "../assets/start-up.jpg";
import personalPortfolio from "../assets/personal-portfolio.jpg";
import educationWebsite from "../assets/education-website.jpg";
import newsWebsite from "../assets/news-website.jpg";
import corporateWebsite from "../assets/corporate-website.jpg";
import governmentWebsite from "../assets/government-website.jpg";
import E_commerceWebsites from "../assets/e-commerce-websites.jpg";
import customWebsites from "../assets/custom-websites.jpg";
import Footer from "../footer/index";
import "./servicestyle.css";

const Website = () => {
    useEffect(() => {
        document.title = "Website Creation Services - Crafting Tomorrow's Web Today | ServiceAreaHub";
    }, []);
    return(
        <div>
            <Navbar/>
            <div className="serviceContainer">
                <section className="page-section bg-light" id="services">
                    <div className="container">
                        <div className="text-center">
                            {/* <h2 className="section-heading text-uppercase sectionHead"> */}
                            <h2 className="section-heading sectionHead">
                                {/* <span style={{"fontWeight": "lighter"}}>Boost Your Brand with Our Professional</span> */}
                                {/* {' '} Services */}
                                Website Creation {' '}
                            </h2>
                            <span style={{fontSize: "30px", fontWeight: "bold"}}>Crafting Tomorrow's Web Today</span>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <p>
                                    At ServiceAreaHub, our website creation service is more than just building a digital presence—it's about crafting a powerful online identity that drives your business forward. We specialize in designing and developing custom websites that are not only visually stunning but also strategically aligned with your brand's goals and values. Our approach begins with a deep understanding of your business objectives and target audience, allowing us to create tailored solutions that resonate and engage.
                                </p>
                                <p>
                                    We leverage the latest technologies and industry best practices to ensure your website is not only attractive but also functional and user-friendly across all devices. From intuitive navigation to seamless integration of multimedia elements, we prioritize user experience to maximize engagement and conversions.
                                </p>
                                <p>
                                    Moreover, our commitment extends beyond the initial launch. We provide ongoing support and maintenance to keep your website secure, up-to-date, and optimized for performance. Whether you're a startup looking to establish your online presence or an established business seeking to enhance your digital footprint, ServiceAreaHub is your trusted partner in achieving success in the digital landscape. Let us transform your vision into reality and empower your business to thrive in the competitive online marketplace.
                                </p>
                            </h3>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <div style={{fontSize: "30px", fontWeight: "bold"}}>Why should you choose us for your Website Development?</div>
                                <br/>
                                <p>
                                    If you are looking for more than just a website, choose ServiceAreaHub for your development needs and discover a partner dedicated to transforming your digital presence into a powerful asset. We go beyond the basics of design and coding, crafting bespoke websites that resonate with your audience and drive tangible results for your business. Our team combines creativity with technical expertise, ensuring every site we create is not only visually compelling but also functional and user-friendly.
                                </p>
                                <p>
                                    What makes us stand out—it's our commitment to innovation and excellence. We leverage the latest technologies and industry insights to deliver cutting-edge solutions tailored to your specific goals. Whether you're aiming to boost online sales, enhance brand visibility, or improve customer engagement, we have the skills and experience to make it happen.
                                </p>
                                <p>
                                    At ServiceAreaHub, we believe in partnership and collaboration. We listen to your needs, communicate openly throughout the process, and provide ongoing support to ensure your website evolves with your business. Join countless satisfied clients who have chosen us to elevate their online presence and achieve lasting success in the digital world. Let's embark on this journey together and create a website that not only impresses but also drives your business forward.
                                </p>
                            </h3>
                        </div>
                        <div>
                            {/* Start-Up Websites */}
                            <div className="serviceElements">
                                {/* <div className="serviceHeads">Website Creation</div> */}
                                <div className="serviceHeads">Start-Up Websites</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    {/* <div className="portfolio-item imageEffect"> */}
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={startUp} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Creating a website is crucial for start-ups to establish an online presence and reach potential customers. A well-designed start-up website serves as a digital storefront, showcasing products or services and building brand credibility. It should be user-friendly, mobile-responsive, and visually appealing to captivate visitors. Key features include clear navigation, engaging content, and strong calls to action that guide users towards desired actions, such as signing up for newsletters or making purchases. Integrating social media and blogging capabilities can enhance engagement and SEO. For start-ups, affordability and scalability are important, so choosing platforms that allow easy updates and future expansions is essential. Additionally, incorporating analytics tools helps track visitor behavior and refine marketing strategies. Investing in professional website development ensures a robust online foundation, attracting customers and driving business growth. A compelling start-up website not only boosts visibility but also builds trust, setting the stage for long-term success.
                                        {/* Our web development services create robust, user-friendly websites that are not only visually appealing but also SEO-optimized. We use the latest technologies and best practices to ensure your website loads quickly, functions smoothly, and ranks high on search engines. */}
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Blogs, Personal and Portfolio Websites */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Blogs, Personal and Portfolio Websites</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        Blogs, personal, and portfolio websites are essential tools for showcasing individual creativity, expertise, and personal brand. Blogs serve as dynamic platforms for sharing insights, experiences, and knowledge on various topics, fostering engagement and community building. Personal websites offer a comprehensive space to highlight one's professional background, achievements, and personal interests, providing a personalized touch beyond social media profiles. Portfolio websites are vital for creatives such as designers, photographers, writers, and artists, allowing them to present their work in a visually appealing and organized manner for potential clients and employers. These websites often include galleries, project descriptions, and testimonials, enhancing credibility and professionalism. In an increasingly digital world, maintaining a blog or personal website is invaluable for personal branding and career advancement, demonstrating initiative, creativity, and a proactive approach to online presence.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={personalPortfolio} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Blogs, personal, and portfolio websites are essential tools for showcasing individual creativity, expertise, and personal brand. Blogs serve as dynamic platforms for sharing insights, experiences, and knowledge on various topics, fostering engagement and community building. Personal websites offer a comprehensive space to highlight one's professional background, achievements, and personal interests, providing a personalized touch beyond social media profiles. Portfolio websites are vital for creatives such as designers, photographers, writers, and artists, allowing them to present their work in a visually appealing and organized manner for potential clients and employers. These websites often include galleries, project descriptions, and testimonials, enhancing credibility and professionalism. In an increasingly digital world, maintaining a blog or personal website is invaluable for personal branding and career advancement, demonstrating initiative, creativity, and a proactive approach to online presence.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Educational Websites */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Educational Websites</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={educationWebsite} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Educational websites play a pivotal role in modern learning, offering accessible, flexible, and interactive resources for students and educators. These platforms provide a wealth of materials, from video tutorials and interactive quizzes to comprehensive courses and virtual classrooms. They cater to a wide range of subjects and educational levels, making learning more engaging and effective. Features such as forums, chat support, and collaborative tools foster a community atmosphere, enabling students to connect with peers and instructors globally. Additionally, educational websites often include progress tracking and personalized learning paths, helping users to monitor their development and focus on areas needing improvement. By integrating multimedia elements and gamification, these websites enhance motivation and retention. They are invaluable for lifelong learning, supporting formal education and self-directed study. Overall, educational websites democratize education, breaking down geographical barriers and making high-quality learning resources available to anyone with internet access.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* News and Media Websites */}
                            <div className="serviceElements">
                                <div className="serviceHeads">News and Media Websites</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        News and media websites are pivotal in today’s digital age, offering instant access to information and shaping public discourse. These platforms provide up-to-the-minute news, in-depth analysis, and multimedia content such as videos, podcasts, and interactive graphics. Designed to be user-friendly and responsive, they cater to diverse audiences, ensuring accessibility across various devices. Key features include breaking news alerts, personalized content feeds, and social media integration to enhance user engagement. Robust search functionalities and categorized sections—such as politics, sports, entertainment, and business—allow users to find relevant information quickly. Advertising, subscriptions, and sponsored content are common revenue streams for these websites. Ethical journalism and credibility are paramount, with many sites employing rigorous fact-checking processes and offering transparent corrections. As digital news consumption grows, news and media websites continue to evolve, leveraging new technologies like AI for personalized experiences and blockchain for secure content distribution.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={newsWebsite} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        News and media websites are pivotal in today’s digital age, offering instant access to information and shaping public discourse. These platforms provide up-to-the-minute news, in-depth analysis, and multimedia content such as videos, podcasts, and interactive graphics. Designed to be user-friendly and responsive, they cater to diverse audiences, ensuring accessibility across various devices. Key features include breaking news alerts, personalized content feeds, and social media integration to enhance user engagement. Robust search functionalities and categorized sections—such as politics, sports, entertainment, and business—allow users to find relevant information quickly. Advertising, subscriptions, and sponsored content are common revenue streams for these websites. Ethical journalism and credibility are paramount, with many sites employing rigorous fact-checking processes and offering transparent corrections. As digital news consumption grows, news and media websites continue to evolve, leveraging new technologies like AI for personalized experiences and blockchain for secure content distribution.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Corporate Websites */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Corporate Websites</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={corporateWebsite} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Corporate websites are vital tools for businesses to establish their online presence, enhance brand identity, and communicate with various stakeholders. Designed with professionalism and functionality in mind, these websites serve as comprehensive platforms that showcase a company's products, services, achievements, and values. A well-crafted corporate website not only attracts potential clients but also builds credibility and trust. Key features include a clean, user-friendly interface, responsive design, and seamless navigation. They often incorporate advanced functionalities such as customer portals, blogs, news sections, and integration with social media channels.
                                    </p>
                                    <p>
                                        Additionally, corporate websites are optimized for search engines (SEO), ensuring higher visibility and reach. Content is strategically crafted to engage visitors and convert leads into customers, providing detailed information about the company's history, mission, and team, along with contact details and customer support options. These websites also highlight the company's unique selling points and industry expertise. Ultimately, an effective corporate website is a powerful asset that drives business growth and enhances online reputation, positioning the company as a leader in its field.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Government Websites */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Government Websites</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        Government websites serve as crucial portals for public information, services, and civic engagement. These platforms provide citizens with access to essential resources such as laws, regulations, public policies, and official announcements. They often feature interactive tools for tasks like tax filing, permit applications, and accessing public records, promoting transparency and efficiency in governance.
                                    </p>
                                    <p>
                                        Moreover, government websites play a pivotal role in enhancing civic participation by offering forums for public feedback, consultations on policy decisions, and updates on community initiatives. They also serve as reliable sources of information during emergencies, disseminating critical alerts and safety guidelines. Ensuring accessibility and usability is key, as these websites cater to diverse audiences including individuals with disabilities and those unfamiliar with digital technologies. Overall, government websites are instrumental in fostering accountability, empowering citizens with information, and facilitating a more inclusive and informed society.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={governmentWebsite} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Government websites serve as crucial portals for public information, services, and civic engagement. These platforms provide citizens with access to essential resources such as laws, regulations, public policies, and official announcements. They often feature interactive tools for tasks like tax filing, permit applications, and accessing public records, promoting transparency and efficiency in governance.
                                    </p>
                                    <p>
                                        Moreover, government websites play a pivotal role in enhancing civic participation by offering forums for public feedback, consultations on policy decisions, and updates on community initiatives. They also serve as reliable sources of information during emergencies, disseminating critical alerts and safety guidelines. Ensuring accessibility and usability is key, as these websites cater to diverse audiences including individuals with disabilities and those unfamiliar with digital technologies. Overall, government websites are instrumental in fostering accountability, empowering citizens with information, and facilitating a more inclusive and informed society.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* E-commerce Websites */}
                            <div className="serviceElements">
                                <div className="serviceHeads">E-commerce Websites</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={E_commerceWebsites} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        E-commerce websites have revolutionized the way businesses and consumers interact in the digital age. These platforms enable businesses to sell products and services online, reaching a global audience 24/7. By eliminating geographical constraints, e-commerce websites offer convenience and accessibility, allowing consumers to browse, compare prices, and make purchases from the comfort of their homes or on the go.
                                    </p>
                                    <p>
                                        Moreover, e-commerce websites enhance customer experiences through personalized recommendations, secure payment gateways, and efficient order fulfillment processes. They also empower small businesses and entrepreneurs to compete on a level playing field with larger corporations, leveraging digital marketing strategies and analytics to optimize sales and customer retention. As technology continues to evolve, e-commerce platforms evolve with it, integrating AI, mobile responsiveness, and social commerce features to adapt to changing consumer behaviors and preferences.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/ecommerce"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Custom Websites */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Custom Websites</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        "Custom websites" refer to tailor-made online platforms designed to meet specific needs or preferences of individuals or businesses. Unlike template-based websites, which offer standardized designs, custom websites are built from scratch or extensively customized to reflect unique branding, functionality, and user experience requirements.
                                    </p>
                                    <p>
                                        These websites are crafted to align closely with the goals and identity of the client, offering flexibility in design, layout, and features. Businesses often opt for custom websites to differentiate themselves in competitive markets, enhance user engagement, and optimize for specific business processes or customer interactions. This approach allows for the integration of specialized functionalities such as e-commerce systems, interactive elements, or complex databases, tailored precisely to meet operational demands or user expectations. Custom websites not only offer a distinct online presence but also enable scalability and future-proofing by accommodating evolving business needs and technological advancements.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={customWebsites} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        "Custom websites" refer to tailor-made online platforms designed to meet specific needs or preferences of individuals or businesses. Unlike template-based websites, which offer standardized designs, custom websites are built from scratch or extensively customized to reflect unique branding, functionality, and user experience requirements.
                                    </p>
                                    <p>
                                        These websites are crafted to align closely with the goals and identity of the client, offering flexibility in design, layout, and features. Businesses often opt for custom websites to differentiate themselves in competitive markets, enhance user engagement, and optimize for specific business processes or customer interactions. This approach allows for the integration of specialized functionalities such as e-commerce systems, interactive elements, or complex databases, tailored precisely to meet operational demands or user expectations. Custom websites not only offer a distinct online presence but also enable scalability and future-proofing by accommodating evolving business needs and technological advancements.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/templates/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <span style={{fontSize: "30px", fontWeight: "bold"}}>Why should you choose us for your website development?</span>
                        <p>
                            Choose us for your website development because we offer a wealth of expertise and a proven track record in crafting tailored solutions that exceed client expectations. With extensive experience across diverse industries, we specialize in creating visually appealing and functional websites that align closely with your business objectives. Our commitment to innovation ensures we integrate cutting-edge technologies to enhance user experience and site performance. We prioritize client satisfaction, providing dedicated support from project inception through post-launch maintenance. Whether you're launching a new site or revitalizing an existing one, trust us to deliver a compelling online presence that drives growth and achieves your business goals effectively.
                        </p> */}
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    )
};

export default Website;