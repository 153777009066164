import React, { useEffect } from "react";
import Blog1Img from "../assets/blog1Img.jpg";
import Blog2Img from "../assets/blog2Img.jpg";
import Blog3Img from "../assets/blog3Img.jpeg";
import Blog4Img from "../assets/blog4Img.jpg";
import Blog5Img from "../assets/blog5Img.jpg";
import Blog6Img from "../assets/blog6Img.jpg";
import Blog7Img from "../assets/blog7Img.jpg";
import Blog8Img from "../assets/blog8Img.jpg";
import "./blogstyle.css";

const BlogsPage = () => {
    useEffect(() => {
        document.title = 'Blogs - Read SAH Blogs and Get in Touch with Us | ServiceAreaHub';
    }, []);
    return(
        <div>
            {/* Blogs */}
            {/* <section className="blog-page-section bg-light" id="blogs"> */}
            <section className="page-section bg-light" id="blogs">
                <div className="container">
                    <div className="text-center">
                        {/* <h2 className="section-heading text-uppercase sectionHead"> */}
                        <h2 className="section-heading sectionHead">
                            {/* <span style={{"fontWeight": "lighter"}}>Boost Your Brand with Our Professional</span> */}
                            {/* {' '} Blogs */}
                            Blogs {' '}
                        </h2>
                        <span style={{fontSize: "30px", fontWeight: "bold"}}>Read SAH Blogs and Get in Touch with Us</span>
                        <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                            <p>
                                We specialize in creating compelling, SEO-optimized blogs that captivate your audience and boost your online visibility. Our team of expert writers crafts informative and engaging content tailored to your brand’s voice and industry. At ServiceAreaHub, we ensure that whether you aim to educate, entertain, or inspire, our blogs are designed to drive traffic, enhance customer engagement, and establish your authority in the market. Let us help you transform your blog into a powerful tool for growth and success.
                            </p>
                        </h3>
                    </div>
                    <div>
                        {/* How to start a business */}
                        <div className="serviceElements">
                            <div className="serviceHeads">How to Start a Business</div>
                            <div className="col-lg-4 col-sm-6 mb-4 blogDetailLeft servicePic">
                                {/* <div className="portfolio-item imageEffect"> */}
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid shadow" src={Blog1Img} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="mb-4 blogDetailRight adjustCompressText serviceBrief">
                                <p>
                                    We need to research in a few different fields, like what you are good at, what is your aspiration, what is your hobby, what is your passion, or what you want to be in life. One thing to keep in mind that in any field you can start a business and you can earn money easily from it, just you need to know the business model, and the way to earn money from that field.
                                    {/* Our web development blogs create robust, user-friendly websites that are not only visually appealing but also SEO-optimized. We use the latest technologies and best practices to ensure your website loads quickly, functions smoothly, and ranks high on search engines. */}
                                </p>
                                <a className="service-link" href="/blogs/blog1">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* Artifitial Intelligence and Mechine Learning */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Artifitial Intelligence and Mechine Learning</div>
                            <div className="col-sm-6 mb-4 blogDetailLeft serviceBrief serviceDecHideUnhide">
                                <p>
                                    Artificial Intelligence (AI) and machine learning (ML) have become pivotal in the advancement of technology, transforming industries and everyday life. Their applications span across various sectors including healthcare, finance, transportation, and entertainment. This essay delves into the fundamentals of AI and ML, their historical context, key concepts, applications, challenges, and future prospects.
                                </p>
                                <a className="service-link" href="/blogs/blog2">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4 blogDetailRight servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid adjustCompressImage shadow" src={Blog2Img} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 blogDetailLeft serviceBrief serviceBriefHideUnhide">
                                <p>
                                    Artificial Intelligence (AI) and machine learning (ML) have become pivotal in the advancement of technology, transforming industries and everyday life. Their applications span across various sectors including healthcare, finance, transportation, and entertainment. This essay delves into the fundamentals of AI and ML, their historical context, key concepts, applications, challenges, and future prospects.
                                </p>
                                <a className="service-link" href="/blogs/blog2">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* Networking and Telecommunication */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Networking and Telecommunication</div>
                            <div className="col-lg-4 col-sm-6 mb-4 blogDetailLeft servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid shadow" src={Blog3Img} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 blogDetailRight adjustCompressText serviceBrief">
                                <p>
                                    Network and telecommunications are fundamental to modern society, enabling communication, commerce, and the exchange of information across the globe. This essay will explore the concepts, technologies, and applications of networks and telecommunications, providing an in-depth understanding of their evolution, current state, and future prospects.
                                </p>
                                <a className="service-link" href="/blogs/blog3">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* Work-Life Balance */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Work-Life Balance</div>
                            <div className="col-sm-6 mb-4 blogDetailLeft serviceBrief serviceDecHideUnhide">
                                <p>
                                    Work-life balance refers to the equilibrium between an individual's professional responsibilities and personal life. Striking a healthy balance is crucial for overall well-being, productivity, and job satisfaction. With the fast pace of modern life and the increasing demands of the workplace, maintaining this balance can be challenging but is essential for long-term health and happiness.
                                </p>
                                <a className="service-link" href="/blogs/blog4">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4 blogDetailRight servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid adjustCompressImage shadow" src={Blog4Img} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 blogDetailLeft serviceBrief serviceBriefHideUnhide">
                                <p>
                                    Work-life balance refers to the equilibrium between an individual's professional responsibilities and personal life. Striking a healthy balance is crucial for overall well-being, productivity, and job satisfaction. With the fast pace of modern life and the increasing demands of the workplace, maintaining this balance can be challenging but is essential for long-term health and happiness.
                                </p>
                                <a className="service-link" href="/blogs/blog4">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* Quantum Computing */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Quantum Computing</div>
                            <div className="col-lg-4 col-sm-6 mb-4 blogDetailLeft servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid shadow" src={Blog5Img} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 blogDetailRight adjustCompressText serviceBrief">
                                <p>
                                    Quantum computing is a fascinating and rapidly evolving field that uses the principles of quantum mechanics to perform calculations that would be infeasible for classical computers. Here's a brief overview with some key points and images to help illustrate the concepts:
                                </p>
                                <a className="service-link" href="/blogs/blog5">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* Health and fitness */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Health and Fitness</div>
                            <div className="col-sm-6 mb-4 blogDetailLeft serviceBrief serviceDecHideUnhide">
                                <p>
                                    Health and fitness are integral components of a balanced and fulfilling life. Maintaining good health and fitness can significantly enhance one's quality of life by boosting physical, mental, and emotional well-being. This essay explores the importance of health and fitness, the benefits they offer, and practical strategies to incorporate them into daily life.
                                </p>
                                <a className="service-link" href="/blogs/blog6">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4 blogDetailRight servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid adjustCompressImage shadow" src={Blog6Img} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 blogDetailLeft serviceBrief serviceBriefHideUnhide">
                                <p>
                                    Health and fitness are integral components of a balanced and fulfilling life. Maintaining good health and fitness can significantly enhance one's quality of life by boosting physical, mental, and emotional well-being. This essay explores the importance of health and fitness, the benefits they offer, and practical strategies to incorporate them into daily life.
                                </p>
                                <a className="service-link" href="/blogs/blog6">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* Cybersecurity and Data Privacy */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Cybersecurity and Data Privacy</div>
                            <div className="col-lg-4 col-sm-6 mb-4 blogDetailLeft servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid shadow" src={Blog7Img} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 blogDetailRight adjustCompressText serviceBrief">
                                <p>
                                    In an era where digital transformation touches every aspect of our lives, cybersecurity and data privacy have emerged as critical concerns. As individuals, businesses, and governments increasingly rely on digital technologies, protecting sensitive information from cyber threats and ensuring data privacy are paramount. This essay explores the importance of cybersecurity and data privacy, the challenges faced, and strategies to enhance protection.
                                </p>
                                <a className="service-link" href="/blogs/blog7">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                        {/* Technologies You Need to Know to Stay Updated */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Technologies You Need to Know to Stay Updated</div>
                            <div className="col-sm-6 mb-4 blogDetailLeft serviceBrief serviceDecHideUnhide">
                                <p>
                                    In today's world, technological advancements are profoundly shaping various aspects of life, from how we communicate and work to how we manage our health and environment. To navigate this rapidly evolving landscape, it is crucial to understand the key technologies driving change. This essay explores the essential technologies of today, including Artificial Intelligence (AI) and Machine Learning (ML), Quantum Computing, 5G and beyond, Blockchain and Cryptocurrencies, Internet of Things (IoT), Extended Reality (XR), Edge Computing, Cybersecurity, Sustainable Technologies, and Biotechnology.
                                </p>
                                <a className="service-link" href="/blogs/blog8">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4 blogDetailRight servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid adjustCompressImage shadow" src={Blog8Img} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 blogDetailLeft serviceBrief serviceBriefHideUnhide">
                                <p>
                                    In today's world, technological advancements are profoundly shaping various aspects of life, from how we communicate and work to how we manage our health and environment. To navigate this rapidly evolving landscape, it is crucial to understand the key technologies driving change. This essay explores the essential technologies of today, including Artificial Intelligence (AI) and Machine Learning (ML), Quantum Computing, 5G and beyond, Blockchain and Cryptocurrencies, Internet of Things (IoT), Extended Reality (XR), Edge Computing, Cybersecurity, Sustainable Technologies, and Biotechnology.
                                </p>
                                <a className="service-link" href="/blogs/blog8">
                                    <p className="readDetail">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
};

export default BlogsPage;