import React, { useState } from "react";
import welcomeToTheWorld from "../assets/welcom-to-the-world.png";
import whyWorkWithUs from "../assets/why-work-with-us.png";
import coreValue from "../assets/core-value.png";
import workCulture from "../assets/work-culture.jpg";
import FullTimerCareerForm from "./fulltimercareerform";
import FreelancerCareerForm from "./freelancercareerform";
import "./careersstyle.css";
import "../services/servicestyle.css";

const CareersPage = () => {
    const [careerForm, setCareerForm] = useState(<FullTimerCareerForm/>);

    const careerSelection = (event, careerType) => {
        event.preventDefault();

        // Remove 'active' class from all elements
        document.querySelectorAll('.careerSelection').forEach(element => {
            element.classList.remove('active');
        });

        // Add 'active' class to the clicked element
        event.target.classList.add('active');

        // Display the appropriate form based on careerType
        if (careerType === "fullTime") {
            setCareerForm(<FullTimerCareerForm />);
        } else if (careerType === "freelance") {
            setCareerForm(<FreelancerCareerForm />);
        }
    };

    return(
        <div>
            {/* Careers Page */}
            <section className="page-section bg-light" id="careers">
                <div className="container">
                    <div className="text-center">
                        {/* <h2 className="section-heading text-uppercase sectionHead"> */}
                        <h2 className="section-heading sectionHead">
                            {/* <span style={{"fontWeight": "lighter"}}>Boost Your Brand with Our Professional</span> */}
                            {/* {' '} Services */}
                            Careers {' '}
                        </h2>
                        <span style={{fontSize: "30px", fontWeight: "bold"}}>Join Our Dynamic Team and Shape the Future</span>
                        {/* <span style={{fontSize: "30px", fontWeight: "bold"}}>Forge Ahead and Bring the World Along</span> */}
                        <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                            <p>
                                We are dedicated to empowering communities by connecting them with top ITservices. Join our dynamic team and be part of an innovative environment where your contributions significantly impact how people access and enjoy essential services. We prioritize professional growth, offering continuous learning opportunities and clear career advancement paths. Our collaborative culture fosters creativity, diversity, and inclusivity, ensuring every voice is heard and valued.
                            </p>
                            <p>
                                Working at ServiceAreaHub means being part of a mission-driven organization committed to excellence and community well-being. Whether you specialize in technology, customer service, or marketing, you'll find a place where your skills can thrive. We offer competitive benefits, flexible working conditions, and a supportive team that celebrates your achievements. Hence, join ServiceAreaHub and help shape the future of ITservices, enhancing the lives of people and businesses in your community. Grow your career with us and make a real difference.
                            </p>
                        </h3>
                    </div>
                    <div>
                        {/* Welcome to Our Family */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Welcome to Our Family</div>
                            <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                {/* <div className="portfolio-item imageEffect"> */}
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid" src={welcomeToTheWorld} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="mb-4 serviceDetailRight serviceBrief">
                                <p>
                                    At ServiceAreaHub, our mission is to create groundbreaking solutions while delivering exceptional IT services tailored to meet the unique needs of each client. We pride ourselves on being at the forefront of technological advancements, constantly evolving to provide innovative and effective solutions that drive success and growth for businesses across various industries.
                                    {/* Our web development services create robust, user-friendly websites that are not only visually appealing but also SEO-optimized. We use the latest technologies and best practices to ensure your website loads quickly, functions smoothly, and ranks high on search engines. */}
                                </p>
                                <p>
                                    Our approach is deeply rooted in a commitment to excellence and a passion for technology. We strive to understand the distinct challenges and goals of our clients, enabling us to craft customized strategies that ensure optimal outcomes. Our team of experts are dedicated to maintaining the highest standards of quality and service, consistently exceeding expectations and building a ever lasting partnerships.
                                </p>
                                <p>
                                    Central to our philosophy is the belief in fostering a collaborative and inclusive work environment. We recognize that the strength of our company lies in the diversity and talent of our team members. By promoting a culture of collaboration, respect, and continuous learning, we empower our employees to thrive both personally and professionally.
                                </p>
                                <p>
                                    We are more than just a service provider; we are a trusted partner committed to helping our clients navigate the ever-changing landscape of technology with confidence and success.
                                </p>
                            </div>
                        </div>
                        {/* Why Work With Us? */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Why Work With Us?</div>
                            <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                <p>
                                    Choosing to work with us means joining a team that values innovation, collaboration, and personal growth. We understand that our people are our greatest asset, and we are dedicated to creating an environment where everyone can thrive.
                                </p>
                                <p>
                                    Our workplace culture is built on mutual respect and open communication, ensuring that every team member feels valued and heard. We encourage creativity and out-of-the-box thinking, believing that the best ideas come from diverse perspectives and collaborative efforts. This approach allows us to consistently deliver cutting-edge solutions and stay ahead in the dynamic IT services industry.
                                </p>
                                <p>
                                    Professional development is a key focus for us. We offer a range of learning and growth opportunities, from ongoing training programs to mentorship and career advancement paths. We want you to not only succeed in your current role but also prepare for future challenges and opportunities.
                                </p>
                                <p>
                                    Additionally, we prioritize work-life balance, understanding that personal well-being is crucial for professional success. Our flexible policies and supportive environment help you to maintain a healthy balance between your work and personal life.
                                </p>
                                <p>
                                    By joining us, you become part of a forward-thinking, dynamic team that’s committed to making a difference in the world of IT services. Together, we can achieve extraordinary things and shape the future.
                                </p>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid" src={whyWorkWithUs} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                <p>
                                    Choosing to work with us means joining a team that values innovation, collaboration, and personal growth. We understand that our people are our greatest asset, and we are dedicated to creating an environment where everyone can thrive.
                                </p>
                                <p>
                                    Our workplace culture is built on mutual respect and open communication, ensuring that every team member feels valued and heard. We encourage creativity and out-of-the-box thinking, believing that the best ideas come from diverse perspectives and collaborative efforts. This approach allows us to consistently deliver cutting-edge solutions and stay ahead in the dynamic IT services industry.
                                </p>
                                <p>
                                    Professional development is a key focus for us. We offer a range of learning and growth opportunities, from ongoing training programs to mentorship and career advancement paths. We want you to not only succeed in your current role but also prepare for future challenges and opportunities.
                                </p>
                                <p>
                                    Additionally, we prioritize work-life balance, understanding that personal well-being is crucial for professional success. Our flexible policies and supportive environment help you to maintain a healthy balance between your work and personal life.
                                </p>
                                <p>
                                    By joining us, you become part of a forward-thinking, dynamic team that’s committed to making a difference in the world of IT services. Together, we can achieve extraordinary things and shape the future.
                                </p>
                            </div>
                        </div>
                        {/* Our Values */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Our Values</div>
                            <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid" src={coreValue} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                <p>
                                    Our values form the foundation of our organization, guiding every decision and action we take. We believe in fostering a culture that emphasizes integrity, innovation, and excellence. Integrity is at the heart of everything we do, ensuring transparency and trust in all our interactions. Innovation drives us to constantly seek better solutions, staying ahead in the ever-evolving IT landscape. Excellence means we strive for the highest standards in our work, delivering outstanding results for our clients.
                                </p>
                                <p>
                                    Collaboration is another core value we uphold. By working together, we harness diverse perspectives and skills, creating a synergy that leads to superior outcomes. Mutual respect is fundamental in our interactions, promoting a positive and inclusive work environment where every team member feels valued and heard.
                                </p>
                                <p>
                                    Customer-centricity is central to our values. We are committed to understanding and exceeding our clients’ needs, ensuring their success is our success. Continuous learning is also a key value, as we encourage our team members to grow and develop their skills, staying at the forefront of industry advancements.
                                </p>
                                <p>
                                    By embedding these values into our daily operations, we create a dynamic, supportive, and forward-thinking workplace. Join us and experience a company where values are more than just words—they are the essence of who we are.
                                </p>
                            </div>
                        </div>
                        {/* Our Culture */}
                        <div className="serviceElements">
                            <div className="serviceHeads">Our Culture</div>
                            <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                <p>
                                    At our core, we believe that a great workplace is built by great people. Our commitment to fostering an environment where collaboration, creativity, and mutual respect thrive is unwavering. We understand that every team member brings unique skills and perspectives, and it's this diversity that fuels our innovation and success.
                                </p>
                                <p>
                                    We encourage a culture where ideas are freely exchanged, and every voice is heard. By working together, we create solutions that not only meet but exceed the expectations of our clients. Our dynamic team is constantly pushing the boundaries of what’s possible in IT services, and we’re proud to be at the forefront of technological advancements.
                                </p>
                                <p>
                                    Joining us means becoming part of a forward-thinking organization that values your contributions and supports your professional growth. We invest in our people, providing opportunities for learning and development to ensure that you can reach your full potential.
                                </p>
                                <p>
                                    We are more than just a workplace; we are a community driven by a shared vision of excellence and a passion for making a difference. If you’re ready to be part of a team that’s not just keeping up with the future of IT services but actively shaping it, we invite you to join us. Together, we can achieve extraordinary things.
                                </p>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                <div className="portfolio-item">
                                    
                                        <img className="img-fluid" src={workCulture} alt="..." />
                                    
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                <p>
                                    At our core, we believe that a great workplace is built by great people. Our commitment to fostering an environment where collaboration, creativity, and mutual respect thrive is unwavering. We understand that every team member brings unique skills and perspectives, and it's this diversity that fuels our innovation and success.
                                </p>
                                <p>
                                    We encourage a culture where ideas are freely exchanged, and every voice is heard. By working together, we create solutions that not only meet but exceed the expectations of our clients. Our dynamic team is constantly pushing the boundaries of what’s possible in IT services, and we’re proud to be at the forefront of technological advancements.
                                </p>
                                <p>
                                    Joining us means becoming part of a forward-thinking organization that values your contributions and supports your professional growth. We invest in our people, providing opportunities for learning and development to ensure that you can reach your full potential.
                                </p>
                                <p>
                                    We are more than just a workplace; we are a community driven by a shared vision of excellence and a passion for making a difference. If you’re ready to be part of a team that’s not just keeping up with the future of IT services but actively shaping it, we invite you to join us. Together, we can achieve extraordinary things.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Career Form */}
                    <div className="careerContaint">
                        <div>
                            <div className="careerPrefix">{"Apply as a  "}</div>
                            <span className="careerSelection active" style={{textDecoration: 'none'}} onClick={(e) => careerSelection(e, "fullTime")}>Full Timer</span>
                            <span className="careerElement">{" | "}</span>
                            <span className="careerSelection" style={{textDecoration: 'none'}} onClick={(e) => careerSelection(e, "freelance")}>Freelancer</span>
                        </div>
                        {/* <div>
                            <span className="careerSelection active" onClick={(e) => careerSelection(e, "fullTime")}>Apply as a Full Timer</span>
                            <span className="careerSelection">{" | "}</span>
                            <span className="careerSelection" onClick={(e) => careerSelection(e, "freelance")}>Apply as a Freelancer</span>
                        </div> */}
                        <br/>
                        <div>
                            {careerForm}
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
};

export default CareersPage;