// import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import SAHNavbar from './components/navbar/index';
import Home from './components/home/index';
import Services from './components/services/index';
import Website from './components/services/website';
import MobileApp from './components/services/mobileapp';
import DigitalMarket from './components/services/digitalmarket';
import WebGraphicDesign from './components/services/webgraphicdesign';
import ContentWriting from './components/services/contentwriting';
import Ecommerce from './components/services/ecommerce';
import ServiceRequestForm from './components/services/servicerequestform';
import Templates from './components/templates/index';
import About from './components/about/index';
import Blogs from './components/blogs/index';
import Blog1 from './components/blogs/blog1';
import Blog2 from './components/blogs/blog2';
import Blog3 from './components/blogs/blog3';
import Blog4 from './components/blogs/blog4';
import Blog5 from './components/blogs/blog5';
import Blog6 from './components/blogs/blog6';
import Blog7 from './components/blogs/blog7';
import Blog8 from './components/blogs/blog8';
import Contact from './components/contact/index';
import Inquiry from './components/inquiry/index';
import Careers from './components/careers/index';
import PrivacyPolicy from './components/privacypolicy/index';
import TermsAndConditions from './components/termsandconditions/index';
import TermsOfUse from './components/termsofuse/index';
import ErrorPage from './components/error/index';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/services/websitecreation' element={<Website />} />
          <Route path='/services/mobileappdevelopment' element={<MobileApp />} />
          <Route path='/services/digitalmarketing' element={<DigitalMarket />} />
          <Route path='/services/webgraphicdesign' element={<WebGraphicDesign />} />
          <Route path='/services/contentwriting' element={<ContentWriting />} />
          <Route path='/services/ecommerce' element={<Ecommerce />} />
          <Route path='/services/servicerequest' element={<ServiceRequestForm />} />
          <Route path='/templates' element={<Templates />} />
          <Route path='/about' element={<About />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blogs/blog1' element={<Blog1 />} />
          <Route path='/blogs/blog2' element={<Blog2 />} />
          <Route path='/blogs/blog3' element={<Blog3 />} />
          <Route path='/blogs/blog4' element={<Blog4 />} />
          <Route path='/blogs/blog5' element={<Blog5 />} />
          <Route path='/blogs/blog6' element={<Blog6 />} />
          <Route path='/blogs/blog7' element={<Blog7 />} />
          <Route path='/blogs/blog8' element={<Blog8 />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/inquiry' element={<Inquiry />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='/terms-of-use' element={<TermsOfUse />} />
          <Route path='*' element={<ErrorPage />} />

          {/* <Route path='/' element={<SAHNavbar />}> */}
            {/* <Route index element={<All />} /> */}
            {/* <Route path="blogs" element={<Blogs />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} /> */}
          {/* </Route> */}

        </Routes>
      </BrowserRouter>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
