import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import '../style.css';

const MobileNavbar = () => {
    const navigate = useNavigate();
    // const [menuText, setMenuText] = useState("Menu ☰");
    const [navHidden, setNavHidden] = useState(true);
    const [vertNavHome, setVertNavHome] = useState({
        color: "whitesmoke", // Initial color
    });
    const [vertNavServices, setVertNavServices] = useState({
        color: "whitesmoke", // Initial color
    });
    const [vertNavTemplets, setVertNavTemplets] = useState({
        color: "whitesmoke", // Initial color
    });
    const [vertNavBlogs, setVertNavBlogs] = useState({
        color: "whitesmoke", // Initial color
    });
    const [vertNavAbout, setVertNavAbout] = useState({
        color: "whitesmoke", // Initial color
    });
    const [vertNavContact, setVertNavContact] = useState({
        color: "whitesmoke", // Initial color
    });
    // const [vertNavInquiry, setVertNavInquiry] = useState({
    //     backgroundColor: "#BF0A30", // Initial background color
    //     borderRadius: "8px",
    //     color: "whitesmoke", // Initial color
    // });
    const closeRef = useRef();
    const menuRef = useRef();
    const navContainerRef = useRef();

    useEffect(() => {
        // Scroll to top when the component mounts
        window.scrollTo(0, 0);
    
        // Get the current URL
        let url = window.location.href;
    
        // Set vertical navigation styles based on the current URL
        if (url.includes('services')) {
            setVertNavServices({ color: "#dc3545" });
        } else if (url.includes('templates')) {
            setVertNavTemplets({ color: "#dc3545" });
        } else if (url.includes('blogs')) {
            setVertNavBlogs({ color: "#dc3545" });
        } else if (url.includes('about')) {
            setVertNavAbout({ color: "#dc3545" });
        } else if (url.includes('contact')) {
            setVertNavContact({ color: "#dc3545" });
        } else if (url.includes('inquiry')) {
            // Handle inquiry case if needed
        } else if (url.includes('career')) {
            // Handle career case if needed
        } else {
            setVertNavHome({ color: "#dc3545" });
        }
    
        // Handle click events on the window
        const handleClick = (event) => {
            if (closeRef.current && closeRef.current.contains(event.target)) {
                // Clicked in the Close(X)
                setNavHidden(true);
            } else if (menuRef.current && menuRef.current.contains(event.target)) {
                // Clicked in the Menu ☰
                setNavHidden(false);
            } else if (navContainerRef.current && !navContainerRef.current.contains(event.target)) {
                // Clicked outside the Nav Container
                setNavHidden(true);
            }
        };
    
        // Attach the click event listener
        window.addEventListener('click', handleClick);
    
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);  // Empty dependency array means this effect runs once on mount and cleanup on unmount
    

    const menuHandeler = () => {
        if(navHidden === true){
            setNavHidden(false);
        }
        else{
            setNavHidden(true);
        }
        // if(menuText === "Menu ☰") {
        //     setNavHidden(false);
        //     setMenuText("X");
        // }
        // else{
        //     setMenuText("Menu ☰");
        //     setNavHidden(true);
        //     // document.getElementsByClassName("virticalNavContainer").hidden = true;
        // }
    };

    // AS ITS NOT A REACT PRACTICE IT HAS BEEN DONE IN "USEEFFECT"
    // window.onclick = (event) => {
    //     if(closeRef.current.contains(event.target)){
    //         // Clicked in the Close(X)
    //         setNavHidden(true);
    //     }
    //     else if (menuRef.current.contains(event.target)) {
    //         // Clicked in the Menu ☰
    //         setNavHidden(false);
    //     }
    //     else if (!navContainerRef.current.contains(event.target)) {
    //         // Clicked outside the Nav Container
    //         setNavHidden(true);
    //     }
    // };

    return(
        <div>
            <button className="navbar-toggler" style={{color: 'transparent'}} type="button" onClick={menuHandeler} data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="virticalNavHandeler" style={{'color':'white', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px', fontSize: '25px'}} ref={menuRef}>☰</span>
                {/* <span className="virticalNavHandeler">{menuText}</span> */}
                {/* <i className="fas fa-bars ms-1"></i> */}
            </button>
            <div className="virticalNavContainer" hidden={navHidden} ref={navContainerRef}>
                    <p className="virticalNavClose" onClick={menuHandeler} ref={closeRef}>X</p>
                <ul className="virticalNavBar">
                    <li
                    className={`virticalNavItems`}
                    onClick={() => navigate("/")}
                    style={vertNavHome}
                    >
                    Home
                    </li>
                    <li
                    className={`virticalNavItems`}
                    onClick={() => navigate("/services")}
                    style={vertNavServices}
                    >
                    Services
                    </li>
                    <li
                    className={`virticalNavItems`}
                    onClick={() => navigate("/templates")}
                    style={vertNavTemplets}
                    >
                    Templates
                    </li>
                    <li
                    className={`virticalNavItems`}
                    onClick={() => navigate("/blogs")}
                    style={vertNavBlogs}
                    >
                    Blogs
                    </li>
                    <li
                    className={`virticalNavItems`}
                    onClick={() => navigate("/about")}
                    style={vertNavAbout}
                    >
                    About
                    </li>
                    {/* <li
                    className={`virticalNavItems`}
                    onClick={() => navigate("/career")}
                    style={vertNavCarrer}
                    >
                    Career
                    </li> */}
                    <li
                    className={`virticalNavItems`}
                    onClick={() => navigate("/contact")}
                    style={vertNavContact}
                    >
                    Contact
                    </li>
                </ul>
                <Nav.Link className="virticalNavInquiry" onClick={() => navigate("/inquiry")}>
                    <span id='inquiry'
                    // style={vertNavInquiry}
                    >
                        Get a FREE quote
                    </span>
                </Nav.Link>
            </div>
        </div>
    )
};

export default MobileNavbar;