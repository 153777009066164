import React, { useEffect } from "react";
import Navbar from "./navbar";
import AndroidPic from "../assets/android-app-dev.jpg";
import iOSPic from "../assets/ios-app-dev.jpg";
import CrossPlatformPic from "../assets/cross-platform-app-dev.jpg";
import Footer from "../footer/index";

const MobileApp = () => {
    useEffect(() => {
        document.title = 'Mobile Application Development Services - Empowering Innovation Through Mobile App Development | ServiceAreaHub';
    }, []);
    return(
        <div>
            <Navbar/>
            <div className="serviceContainer">
            <section className="page-section bg-light" id="services">
                    <div className="container">
                        <div className="text-center">
                            {/* <h2 className="section-heading text-uppercase sectionHead"> */}
                            <h2 className="section-heading sectionHead">
                                {/* <span style={{"fontWeight": "lighter"}}>Boost Your Brand with Our Professional</span> */}
                                {/* {' '} Services */}
                                Mobile Application Development {' '}
                            </h2>
                            <span style={{fontSize: "30px", fontWeight: "bold"}}>Empowering Innovation Through Mobile App Development</span>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <p>
                                    We are specialize in creating cutting-edge mobile app development solutions tailored to meet the unique needs of your business. Our team of experienced developers is dedicated to transforming your vision into reality by designing intuitive, user-friendly, and highly functional mobile applications. Whether you need a robust e-commerce platform, a dynamic social networking app, or a custom enterprise solution, we have the expertise to deliver exceptional results.
                                </p>
                                <p>
                                    Our comprehensive development process ensures that your app is not only visually appealing but also optimized for performance and scalability. We leverage the latest technologies and industry best practices to build apps that run seamlessly on both iOS and Android platforms. From initial concept and design to development, testing, and deployment, we provide end-to-end services to ensure a smooth and hassle-free experience.
                                </p>
                                <p>
                                    Partnering with ServiceAreaHub means gaining a trusted technology partner committed to your success. We prioritize communication and collaboration, working closely with you at every stage to ensure your app meets your business objectives and exceeds your expectations. Let us help you stay ahead of the competition with innovative mobile solutions that engage your customers and drive growth. Choose ServiceAreaHub for mobile app development excellence.
                                </p>
                            </h3>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <div style={{fontSize: "30px", fontWeight: "bold"}}>Why should you choose us for your Mobile Application Development?</div>
                                <br/>
                                <p>
                                    When you choose ServiceAreaHub for Mobile Application Development, you're selecting a team that is committed to bringing your app idea to life with precision and creativity. We start by deeply understanding your business needs and goals, ensuring that the final product is perfectly aligned with your vision. Our focus on user experience means we create apps that are not only functional but also intuitive and engaging, fostering higher customer satisfaction and retention.
                                </p>
                                <p>
                                    Our development process leverages the latest technologies and industry best practices to deliver robust, scalable mobile applications. From the initial concept through to deployment and ongoing support, we ensure a seamless experience. Our team is dedicated to maintaining the app’s performance with regular updates and maintenance, ensuring it stays ahead of the competition.
                                </p>
                                <p>
                                    We value open communication and partnership and we involve you at every step of the development process, providing regular updates and incorporating your feedback to refine the app. Our history of successful projects and happy clients reflects our expertise and commitment to excellence. Choose ServiceAreaHub for your Mobile Application Development needs and see how our dedication to quality and innovation can transform your business.
                                </p>
                            </h3>
                        </div>
                        <div>
                            {/* Android Applications Development */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Android Applications Development</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    {/* <div className="portfolio-item imageEffect"> */}
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={AndroidPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Developing Android applications involves creating software designed for devices running the Android operating system. This process begins with understanding the Android ecosystem, including its architecture, components, and the Android Software Development Kit (SDK). Developers use programming languages like Java, Kotlin, or C++ and tools such as Android Studio, an integrated development environment (IDE) tailored for Android development. Key components include activities, services, broadcast receivers, and content providers, which help manage the user interface, background processes, and data sharing.
                                    </p>
                                    <p>
                                        A crucial part of Android app development is designing a user-friendly interface using XML for layouts and leveraging the Material Design guidelines to ensure consistency and aesthetic appeal. Developers must also consider different screen sizes and device capabilities, testing their applications on various simulators and real devices. Additionally, integrating APIs and libraries can enhance app functionality, while thorough testing and debugging ensure a smooth user experience. Finally, publishing the app on the Google Play Store involves adhering to Google's policies and guidelines, ensuring the app meets quality and security standards.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* iOS Applications Development */}
                            <div className="serviceElements">
                                <div className="serviceHeads">iOS Applications Development</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        iOS application development involves creating software applications that run on Apple's iOS operating system, which powers iPhones, iPads, and iPod Touch devices. Developers use Apple's integrated development environment (IDE) Xcode, along with programming languages Swift or Objective-C. Swift, introduced by Apple in 2014, has quickly become the preferred language for iOS development due to its modern syntax, safety features, and performance efficiency. Xcode provides various tools, including Interface Builder, which allows developers to design app interfaces using a drag-and-drop interface, and simulators for testing applications on different devices.
                                    </p>
                                    <p>
                                        The iOS development process typically involves several stages: conceptualization, design, development, testing, and deployment. Developers must adhere to Apple's Human Interface Guidelines to ensure apps provide a consistent and intuitive user experience. Testing is crucial to identify and fix bugs, ensuring the app runs smoothly across various devices and iOS versions. Once the app is ready, it is submitted to the App Store, where it undergoes a review process before being made available to users worldwide. Continuous updates and maintenance are essential to keep the app compatible with the latest iOS updates and user expectations.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={iOSPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        iOS application development involves creating software applications that run on Apple's iOS operating system, which powers iPhones, iPads, and iPod Touch devices. Developers use Apple's integrated development environment (IDE) Xcode, along with programming languages Swift or Objective-C. Swift, introduced by Apple in 2014, has quickly become the preferred language for iOS development due to its modern syntax, safety features, and performance efficiency. Xcode provides various tools, including Interface Builder, which allows developers to design app interfaces using a drag-and-drop interface, and simulators for testing applications on different devices.
                                    </p>
                                    <p>
                                        The iOS development process typically involves several stages: conceptualization, design, development, testing, and deployment. Developers must adhere to Apple's Human Interface Guidelines to ensure apps provide a consistent and intuitive user experience. Testing is crucial to identify and fix bugs, ensuring the app runs smoothly across various devices and iOS versions. Once the app is ready, it is submitted to the App Store, where it undergoes a review process before being made available to users worldwide. Continuous updates and maintenance are essential to keep the app compatible with the latest iOS updates and user expectations.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Cross-Platform Applications Development */}
                            <div className="serviceElements">
                                {/* <div className="serviceHeads">Website Creation</div> */}
                                <div className="serviceHeads">Cross-Platform Applications Development</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    {/* <div className="portfolio-item imageEffect"> */}
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={CrossPlatformPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Cross-platform application development involves creating software that can run on multiple operating systems and devices with minimal modification. This approach allows developers to write a single codebase and deploy it across platforms such as Windows, macOS, iOS, Android, and web browsers. Popular frameworks for cross-platform development include React Native, Xamarin, Flutter, and Ionic. These frameworks provide tools and libraries that abstract platform-specific details, enabling developers to focus on the core functionality and user experience of the application.
                                    </p>
                                    <p>
                                        The primary advantages of cross-platform development are cost efficiency and faster time-to-market. By leveraging a single codebase, development teams can reduce the resources needed for maintaining separate native applications for each platform. Additionally, cross-platform frameworks often have robust community support and extensive documentation, which can streamline the development process. However, challenges such as performance issues and limited access to platform-specific features may arise, necessitating careful consideration of the project's requirements and goals.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <span style={{fontSize: "30px", fontWeight: "bold"}}>Why should you choose us for your website development?</span>
                        <p>
                            Boost your online sales with our specialized e-commerce services. We design and develop user-friendly, high-performance e-commerce platforms tailored to your unique business needs. Our expert team ensures your online store features secure payment gateways, seamless checkout processes, and intuitive navigation for an exceptional customer experience. Utilizing the latest technologies and design trends, we create responsive and visually appealing websites that engage customers and drive conversions. Our comprehensive e-commerce solutions include website design and development, product listing management, SEO optimization, and ongoing maintenance. We also offer integrated marketing strategies to enhance your online visibility and attract more customers. Whether you’re starting a new online store or looking to enhance an existing one, our end-to-end services ensure your success in the competitive e-commerce market. Partner with us to elevate your brand, increase sales, and achieve sustainable growth with our professional e-commerce solutions. Let us help you create an outstanding online shopping experience.
                        </p> */}
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    )
};

export default MobileApp;