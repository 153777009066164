import React, { useEffect } from "react";
import Navbar from "./navbar";
import blogPostsPic from "../assets/blog-post.jpg";
import articlePic from "../assets/article.jpg";
import webSitecontentPic from "../assets/website-content.jpg";
import copywritingPic from "../assets/copywriting-content.jpg";
import socialMediaPosts from "../assets/social-media-posts-content.jpg";
import emailNewsLetersPic from "../assets/email-news-letter-content.jpg";
import pressReleases from "../assets/press-releases.jpg";
import technicalWriting from "../assets/technical-writing-content.jpg";
import ebooks from "../assets/ebook-content.jpg";
import caseStudies from "../assets/case-study-content.jpg";
import reviews from "../assets/review-content.jpg";
import scripts from "../assets/script-writing.jpg";
import Footer from "../footer/index";

const ContentWriting = () => {
    useEffect(() => {
        document.title = 'Content Writing Services - Crafting Content that Drives Your Success | ServiceAreaHub';
    }, []);
    return(
        <div>
            <Navbar/>
            <div className="serviceContainer">
                <section className="page-section bg-light" id="services">
                    <div className="container">
                        <div className="text-center">
                            {/* <h2 className="section-heading text-uppercase sectionHead"> */}
                            <h2 className="section-heading sectionHead">
                                {/* <span style={{"fontWeight": "lighter"}}>Boost Your Brand with Our Professional</span> */}
                                {/* {' '} Services */}
                                Content Writing {' '}
                            </h2>
                            <span style={{fontSize: "30px", fontWeight: "bold"}}>Crafting Content that Drives Your Success</span>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <p>
                                    ServiceAreaHub specializes in crafting engaging and effective content that enhances brand visibility and drives audience engagement. Whether you require persuasive website content, informative blog posts, impactful social media updates, or SEO-optimized articles, our team of skilled content writers is dedicated to delivering tailored solutions that align with your business objectives. We combine creativity with strategic insights to ensure each piece of content not only communicates your brand message clearly but also resonates with your target audience.
                                </p>
                                <p>
                                    We prioritize quality and precision in every project we undertake. From initial ideation to final delivery, our meticulous approach ensures that your content is compelling, informative, and optimized for maximum impact. By partnering with us, you benefit from our commitment to understanding your unique brand identity and crafting content that speaks directly to your audience's needs and interests.
                                </p>
                                <p>
                                    Transform your content strategy with ServiceAreaHub and unlock the potential to connect with your audience on a deeper level. Whether you're aiming to increase brand awareness, drive conversions, or establish thought leadership in your industry, our comprehensive content solutions are designed to help you achieve your goals effectively. Trust ServiceAreaHub to elevate your online presence through compelling storytelling and strategic content creation.
                                </p>
                            </h3>
                            <h3 className="section-subheading text-muted" style={{marginTop: "20px"}}>
                                <div style={{fontSize: "30px", fontWeight: "bold"}}>Why should you choose ServiceAreaHub for your content writing needs?</div>
                                <br/>
                                <p>
                                    We understand the power of words in shaping your brand’s identity and driving engagement. Our team of skilled content writers is dedicated to crafting compelling, high-quality content that resonates with your target audience. Whether you need blog posts, website copy, social media content, or product descriptions, we ensure each piece is tailored to reflect your brand's unique voice and objectives.
                                </p>
                                <p>
                                    Our content writers are not only proficient in creating engaging narratives but are also well-versed in SEO best practices. This means that the content we produce is designed to improve your search engine rankings and increase your online visibility. By integrating relevant keywords seamlessly into well-structured content, we help attract more traffic to your site, ultimately driving conversions and growth.
                                </p>
                                <p>
                                    Choosing ServiceAreaHub means partnering with a team that values collaboration and communication. We take the time to understand your business goals, audience, and industry trends, ensuring that every piece of content aligns with your overall strategy. Our commitment to quality and consistency guarantees that your brand's message is conveyed clearly and effectively, setting you apart from the competition. Trust ServiceAreaHub to be your reliable partner in delivering content that not only informs and engages but also inspires action.
                                </p>
                            </h3>
                        </div>
                        <div>
                            {/* Blog Posts */}
                            <div className="serviceElements">
                                {/* <div className="serviceHeads">Website Creation</div> */}
                                <div className="serviceHeads">Blog Posts</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    {/* <div className="portfolio-item imageEffect"> */}
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={blogPostsPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Blog posts are essential tools for sharing knowledge, experiences, and insights on the internet. They serve as a platform for individuals, businesses, and organizations to communicate their ideas to a global audience. Typically structured with a headline, introduction, body, and conclusion, blog posts can cover a wide range of topics from personal reflections to technical tutorials or industry news. They often incorporate multimedia elements like images, videos, and infographics to enhance engagement and comprehension.
                                    </p>
                                    <p>
                                        Effective blog posts are not only informative but also engaging and well-researched. They cater to the reader's interests while providing valuable information or entertainment. Blogs also contribute to SEO efforts, driving organic traffic to websites through relevant keywords and regular updates. Overall, blog posts play a crucial role in digital communication strategies, fostering community engagement, building authority, and establishing a brand's online presence.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Articles */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Articles</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        Our "Articles" service offers tailored content solutions designed to meet diverse needs across industries. Whether you require engaging blog posts, informative articles, or SEO-optimized content, we deliver high-quality writing that captivates and informs your audience. Our team of experienced writers ensures each piece is meticulously researched, ensuring accuracy and relevance to your target audience.
                                    </p>
                                    <p>
                                        At "Articles," we understand the importance of content that not only educates but also drives results. From crafting compelling narratives to incorporating industry-specific insights, we strive to enhance your brand's online presence and authority. Whether you're looking to attract more traffic to your website or establish thought leadership in your field, our customizable article solutions are crafted to meet your unique objectives. Trust "Articles" to elevate your content strategy with professionalism and creativity, ensuring each article resonates with your readers and reinforces your brand message effectively.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={articlePic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Our "Articles" service offers tailored content solutions designed to meet diverse needs across industries. Whether you require engaging blog posts, informative articles, or SEO-optimized content, we deliver high-quality writing that captivates and informs your audience. Our team of experienced writers ensures each piece is meticulously researched, ensuring accuracy and relevance to your target audience.
                                    </p>
                                    <p>
                                        At "Articles," we understand the importance of content that not only educates but also drives results. From crafting compelling narratives to incorporating industry-specific insights, we strive to enhance your brand's online presence and authority. Whether you're looking to attract more traffic to your website or establish thought leadership in your field, our customizable article solutions are crafted to meet your unique objectives. Trust "Articles" to elevate your content strategy with professionalism and creativity, ensuring each article resonates with your readers and reinforces your brand message effectively.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Website Content */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Website Content</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={webSitecontentPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        At ServiceAreaHub, we specialize in crafting compelling website content tailored to elevate your online presence. Our dedicated team of skilled writers understands the nuances of digital communication, ensuring every word resonates with your target audience. From engaging homepage copy that captivates visitors from the first click to informative product descriptions that drive conversions, we offer comprehensive content solutions.
                                    </p>
                                    <p>
                                        Whether you're launching a new website or refreshing existing content, our services are designed to enhance SEO effectiveness and user engagement. We meticulously research your industry, brand voice, and competitive landscape to deliver content that not only informs but also inspires action. Let us transform your website into a dynamic platform that communicates your brand's unique story effectively.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Copywriting */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Copywriting</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        We are specialize in delivering compelling copywriting solutions that resonate with your audience and drive results. Whether you need persuasive sales copy, engaging website content, or captivating social media posts, our team of skilled copywriters is dedicated to crafting content that stands out. We understand the power of words in shaping perceptions and influencing decisions.
                                    </p>
                                    <p>
                                        Our approach combines creativity with strategic insights to ensure your message not only reaches but also connects with your target audience effectively. With a keen understanding of various industries and a commitment to excellence, we tailor each piece of content to reflect your brand's voice and meet your specific objectives. Trust us to transform your ideas into impactful narratives that leave a lasting impression.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={copywritingPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        We are specialize in delivering compelling copywriting solutions that resonate with your audience and drive results. Whether you need persuasive sales copy, engaging website content, or captivating social media posts, our team of skilled copywriters is dedicated to crafting content that stands out. We understand the power of words in shaping perceptions and influencing decisions.
                                    </p>
                                    <p>
                                        Our approach combines creativity with strategic insights to ensure your message not only reaches but also connects with your target audience effectively. With a keen understanding of various industries and a commitment to excellence, we tailor each piece of content to reflect your brand's voice and meet your specific objectives. Trust us to transform your ideas into impactful narratives that leave a lasting impression.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Social Media Posts */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Social Media Posts</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={socialMediaPosts} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Unlock the power of captivating content with our expertly crafted Social Media Posts service. In today's digital landscape, engaging and relevant social media content is crucial for connecting with your audience and enhancing brand presence. Whether you're aiming to increase brand awareness, drive traffic, or foster community engagement, our team specializes in creating dynamic posts tailored to your unique goals.
                                    </p>
                                    <p>
                                        We offer a comprehensive range of social media content solutions designed to elevate your online presence across platforms such as Facebook, Instagram, Twitter, and more. From eye-catching visuals to compelling copywriting, each post is meticulously crafted to resonate with your target audience and reflect your brand's identity. Let us help you navigate the ever-evolving social media landscape with content that inspires, informs, and drives results. Elevate your social media strategy today with our tailored Social Media Posts service.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Email News Leters */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Email News Leters</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        Enhance your customer engagement with our expertly crafted Email Newsletters service. Delivering impactful content directly to your subscribers' inboxes, we specialize in creating newsletters that captivate and inform. Our tailored approach ensures each newsletter reflects your brand's voice while delivering relevant updates, promotions, and insights.
                                    </p>
                                    <p>
                                        Whether you're aiming to boost sales, increase website traffic, or strengthen customer relationships, our team ensures every newsletter is designed to achieve your goals effectively. From compelling subject lines to engaging content and enticing calls-to-action, we prioritize quality and performance. Join numerous satisfied clients who rely on our comprehensive Email Newsletters service to stay connected and drive meaningful results. Let us help you transform your email marketing efforts into a powerful tool for growth and engagement.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={emailNewsLetersPic} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Enhance your customer engagement with our expertly crafted Email Newsletters service. Delivering impactful content directly to your subscribers' inboxes, we specialize in creating newsletters that captivate and inform. Our tailored approach ensures each newsletter reflects your brand's voice while delivering relevant updates, promotions, and insights.
                                    </p>
                                    <p>
                                        Whether you're aiming to boost sales, increase website traffic, or strengthen customer relationships, our team ensures every newsletter is designed to achieve your goals effectively. From compelling subject lines to engaging content and enticing calls-to-action, we prioritize quality and performance. Join numerous satisfied clients who rely on our comprehensive Email Newsletters service to stay connected and drive meaningful results. Let us help you transform your email marketing efforts into a powerful tool for growth and engagement.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Press Releases */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Press Releases</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={pressReleases} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Our press release service is designed to elevate your brand's visibility and credibility through compelling storytelling. We specialize in crafting press releases that captivate media attention, driving engagement and enhancing your brand's reputation. Whether you're launching a new product, announcing a milestone, or sharing industry insights, our experienced team ensures your message resonates with journalists and stakeholders alike.
                                    </p>
                                    <p>
                                        We understand the power of a well-crafted narrative. Our press releases are strategically written to convey key messages effectively, leveraging SEO best practices to maximize online reach and impact. With a commitment to quality and timely delivery, we help you navigate the media landscape, ensuring your news stands out amidst the noise.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/ecommerce"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Technical Writing */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Technical Writing</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                            Our Technical Writing service offers expertly crafted documentation tailored to meet the diverse needs of your audience. From user manuals and technical guides to API documentation and white papers, we specialize in creating clear, concise, and informative content that simplifies complex concepts. Our team of experienced technical writers ensures accuracy and clarity, employing industry best practices to deliver documents that meet regulatory standards and enhance user experience. Whether you're launching a new product, updating existing systems, or need comprehensive technical documentation, we provide customized solutions to effectively communicate your technical information.
                                    </p>
                                    <p>
                                        We know the importance of documentation in facilitating seamless operations and fostering user confidence. Our approach combines technical expertise with a focus on usability, ensuring that your documentation not only informs but also empowers your users to maximize the value of your products or services. With a commitment to quality and a keen understanding of your industry-specific requirements, we deliver documentation that is not only comprehensive but also accessible. Partner with us to transform complex technical information into clear, actionable content that drives success for your organization.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={technicalWriting} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Our Technical Writing service offers expertly crafted documentation tailored to meet the diverse needs of your audience. From user manuals and technical guides to API documentation and white papers, we specialize in creating clear, concise, and informative content that simplifies complex concepts. Our team of experienced technical writers ensures accuracy and clarity, employing industry best practices to deliver documents that meet regulatory standards and enhance user experience. Whether you're launching a new product, updating existing systems, or need comprehensive technical documentation, we provide customized solutions to effectively communicate your technical information.
                                    </p>
                                    <p>
                                        We know the importance of documentation in facilitating seamless operations and fostering user confidence. Our approach combines technical expertise with a focus on usability, ensuring that your documentation not only informs but also empowers your users to maximize the value of your products or services. With a commitment to quality and a keen understanding of your industry-specific requirements, we deliver documentation that is not only comprehensive but also accessible. Partner with us to transform complex technical information into clear, actionable content that drives success for your organization.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* E-books */}
                            <div className="serviceElements">
                                <div className="serviceHeads">E-books</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={ebooks} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Discover a world of knowledge with our comprehensive E-books service. At [Your Company Name], we offer a diverse collection of E-books spanning genres from literature and fiction to technical guides and self-help. Whether you're an avid reader or a professional seeking specialized knowledge, our platform caters to all interests and needs.
                                    </p>
                                    <p>
                                        With a user-friendly interface accessible on various devices, including tablets, smartphones, and computers, accessing your favorite titles has never been easier. Our E-books are carefully curated to ensure high quality and relevance, providing readers with a seamless experience from browsing to purchase and download.
                                    </p>
                                    <p>
                                        Join our community of readers today and embark on a journey of learning and entertainment with our extensive E-books collection. Stay updated with new releases and bestsellers, all at your fingertips. Experience the convenience and flexibility of digital reading with ServiceAreaHub.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Case Studies */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Case Studies</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        Explore our comprehensive "Case Studies" service designed to illuminate success stories and strategies across various industries. Our dedicated team meticulously crafts detailed narratives that delve into real-world challenges and innovative solutions. Each case study is meticulously researched, providing actionable insights and practical lessons that can be applied to similar contexts.
                                    </p>
                                    <p>
                                        From healthcare to technology, finance to education, our case studies showcase how organizations have overcome obstacles, implemented effective strategies, and achieved remarkable outcomes. We focus on highlighting not just the results, but the journey and decision-making processes that led to success. Whether you're seeking inspiration for your next project or validation for your strategies, our case studies offer valuable perspectives and proven methodologies that can inform and empower your business decisions.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={caseStudies} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        Explore our comprehensive "Case Studies" service designed to illuminate success stories and strategies across various industries. Our dedicated team meticulously crafts detailed narratives that delve into real-world challenges and innovative solutions. Each case study is meticulously researched, providing actionable insights and practical lessons that can be applied to similar contexts.
                                    </p>
                                    <p>
                                        From healthcare to technology, finance to education, our case studies showcase how organizations have overcome obstacles, implemented effective strategies, and achieved remarkable outcomes. We focus on highlighting not just the results, but the journey and decision-making processes that led to success. Whether you're seeking inspiration for your next project or validation for your strategies, our case studies offer valuable perspectives and proven methodologies that can inform and empower your business decisions.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Reviews */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Reviews</div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailLeft servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={reviews} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailRight serviceBrief">
                                    <p>
                                        Looking for impeccable reviews to enhance your product or service visibility? Look no further. Our content writing service specializes in crafting compelling reviews that captivate and inform. Whether you need detailed analyses or concise summaries, our team of skilled writers ensures each review is tailored to resonate with your audience.
                                    </p>
                                    <p>
                                        From tech gadgets to fashion trends, we meticulously research and articulate the unique features and benefits of your offerings. Our reviews are not just informative but also persuasive, driving engagement and encouraging consumer confidence. Trust us to deliver reviews that not only meet but exceed your expectations, helping you stand out in a competitive market.
                                    </p>
                                    <p>
                                        Let us transform your products into compelling stories that consumers trust. Contact us today to see how our review writing service can elevate your brand.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/ecommerce"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                            {/* Scripts */}
                            <div className="serviceElements">
                                <div className="serviceHeads">Scripts</div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceDecHideUnhide">
                                    <p>
                                        In the realm of content writing services, crafting effective scripts holds a pivotal role in engaging and compelling audiences across various mediums. A well-crafted script seamlessly blends creativity with strategic communication goals, whether for videos, podcasts, or presentations. It serves as the backbone, guiding the narrative flow and ensuring each element contributes to the overarching message.
                                    </p>
                                    <p>
                                        At its core, a script not only outlines dialogue but also shapes the tone, pacing, and emotional appeal crucial for audience connection. It encapsulates the essence of storytelling, leveraging language to evoke desired responses and resonate with viewers or listeners. Professional scriptwriting goes beyond mere words; it involves meticulous attention to detail, understanding of audience demographics, and adherence to brand voice. By harnessing the power of scripting, content creators can effectively convey information, entertain, and inspire action, ultimately enhancing the impact and memorability of any content-driven initiative.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButtonLeft" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-4 serviceDetailRight servicePic">
                                    <div className="portfolio-item">
                                        
                                            <img className="img-fluid" src={scripts} alt="..." />
                                        
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-4 serviceDetailLeft serviceBrief serviceBriefHideUnhide">
                                    <p>
                                        In the realm of content writing services, crafting effective scripts holds a pivotal role in engaging and compelling audiences across various mediums. A well-crafted script seamlessly blends creativity with strategic communication goals, whether for videos, podcasts, or presentations. It serves as the backbone, guiding the narrative flow and ensuring each element contributes to the overarching message.
                                    </p>
                                    <p>
                                        At its core, a script not only outlines dialogue but also shapes the tone, pacing, and emotional appeal crucial for audience connection. It encapsulates the essence of storytelling, leveraging language to evoke desired responses and resonate with viewers or listeners. Professional scriptwriting goes beyond mere words; it involves meticulous attention to detail, understanding of audience demographics, and adherence to brand voice. By harnessing the power of scripting, content creators can effectively convey information, entertain, and inspire action, ultimately enhancing the impact and memorability of any content-driven initiative.
                                    </p>
                                    <br/>
                                    <div className="buttonCointainer">
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase firstButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/blogs/"; }}
                                        >
                                                View Samples
                                        </button>
                                        <samp className="positionUpDown"></samp>
                                        <button 
                                            style={{"background-color": "#bf0a30", "border-color": "transparent", width: "150px", height: "40px", borderRadius: "10px"}} 
                                            className="btn btn-primary text-uppercase secondButton" 
                                            id="formSubmitButton"
                                            // disabled={formSubmitBtn}
                                            onClick={() => { window.location.href = "/services/servicerequest"; }}
                                        >
                                                Proceed
                                        </button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    )
};

export default ContentWriting;