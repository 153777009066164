import React, { useEffect } from "react";
import { CCarousel, CCarouselItem, CImage, CCarouselCaption } from '@coreui/react';
import SAHheader from "../assets/header-bg.jpg";
import serviceHome from "../assets/serviceHome-bg.jpg";
// import templetHome from "../assets/templetHome-bg.jpg";
import templetHome from "../assets/templateHome-bg.jpg";
import blogHome from "../assets/blogHome-bg.jpg";
import WebCreationPic from '../assets/1.jpg';
import MobileAppDevelopmentPic from '../assets/2.jpg';
import DigitalMarketingPic from '../assets/3.jpg';
import WebGraphicDesigningPic from '../assets/4.jpg';
import ContentWritingPic from '../assets/5.jpg';
import E_commerce from '../assets/6.jpg';
import Template1 from '../assets/storez.png';
import Template2 from '../assets/organic-store.png';
import Template3 from '../assets/food.png';
import Template4 from '../assets/office.png';
// import Client1 from '../assets/logos/client1.svg';
// import Client2 from '../assets/logos/client2.svg';
// import Client3 from '../assets/logos/client3.svg';
// import Client4 from '../assets/logos/client4.svg';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './homestyle.css';

const HomePage = () => {
    useEffect(() => {
        document.title = 'ServiceAreaHub | IT Services with Digital Supports, Business Solutions and Consulting';
    }, []);
    return(
        <div>
            {/* Masthead*/}
            {/* <header className="masthead"/> */}
            {/* Carousel */}
            <CCarousel 
                controls   // controlling arrows 
                indicators 
                transition="crossfade" 
                className="full-screen-carousel"
                interval={5000} // Set the interval to 5000 milliseconds (5 seconds)
                ride="carousel" // Enable auto-changing
            >
                <CCarouselItem>
                    <CImage className="carouselImage" src={SAHheader} alt="slide 1" />
                    {/* <CCarouselCaption className="d-none d-md-block carouselText"> */}
                    <CCarouselCaption className="d-md-block carouselText">
                    <h5>Welcome To ServiceAreaHub!</h5>
                    <p><b>YOUR GATEWAY TO DIGITAL SUCCESS!</b></p>
                    {/* <a className="btn btn-primary btn-xl text-uppercase" href="#services">Explore</a> */}
                    <a className="text-uppercase carouselBtn" href="#srvs">Explore</a>
                    </CCarouselCaption>
                </CCarouselItem>
                <CCarouselItem>
                    <CImage className="carouselImage" src={serviceHome} alt="slide 2" />
                    <CCarouselCaption className="d-md-block carouselText">
                    <h5>IT Services that Set You Apart</h5>
                    <p><b>TOP-NOTCH EXPERTISE, INNOVATIVE SUPPORT</b></p>
                    <a className="text-uppercase carouselBtn" href="/services">Explore</a>
                    </CCarouselCaption>
                </CCarouselItem>
                <CCarouselItem>
                    <CImage className="carouselImage" src={templetHome} alt="slide 3" />
                    <CCarouselCaption className="d-md-block carouselText">
                    <h5>Streamline with Versatile Templates</h5>
                    <p><b>VERSATILE SOLUTIONS FOR YOUR NEEDS</b></p>
                    <a className="text-uppercase carouselBtn" href="/templates">Explore</a>
                    </CCarouselCaption>
                </CCarouselItem>
                <CCarouselItem>
                    <CImage className="carouselImage" src={blogHome} alt="slide 3" />
                    <CCarouselCaption className="d-md-block carouselText">
                    <h5>Elevate Your Brand with SEO Blogging</h5>
                    <p><b>INSPIRING INSIGHTS, STORIES, ACTIONABLE TIPS</b></p>
                    <a className="text-uppercase carouselBtn" href="/blogs">Explore</a>
                    </CCarouselCaption>
                </CCarouselItem>
            </CCarousel>
                

        {/* Services */}
        <section className="page-section bg-light" id="srvs">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase sectionHeadHome">
                        {/* <span style={{"fontWeight": "lighter"}}>Boost Your Brand with Our Professional</span> */}
                        {/* {' '} Services */}
                        Services {' '}
                        <span style={{"fontWeight": "lighter"}}>to Boost Your Brand 
                            {/* with Our Professional Expertise */}
                        </span>
                    </h2>
                    <h3 className="section-subheading text-muted">At ServiceAreaHub, we specialize in crafting captivating online experiences that propel businesses to new heights. From mesmerizing website creations to strategic digital marketing campaigns and compelling content creation, we're your one-stop destination for all things digital.</h3>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item shadow">
                            <a className="portfolio-link" href="/services/websitecreation">
                                <div className="portfolio-hover">
                                </div>
                                <img className="img-fluid" src={WebCreationPic} alt="..." />
                            
                                <div className="portfolio-caption">
                                    <div className="portfolio-caption-heading textFormat">Website Creation</div>
                                    <div className="portfolio-caption-subheading text-muted textFormat">Make premium design websites with us.</div>
                                    <p className="textFormat readMore">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item shadow">
                            <a className="portfolio-link" href="/services/mobileappdevelopment">
                                <div className="portfolio-hover">
                                </div>
                                <img className="img-fluid" src={MobileAppDevelopmentPic} alt="..." />
                            
                                <div className="portfolio-caption">
                                    <div className="portfolio-caption-heading textFormat">Mobile App Development</div>
                                    <div className="portfolio-caption-subheading text-muted textFormat">Top-ranking apps for your brand's success.</div>
                                    <p className="textFormat readMore">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item shadow">
                            <a className="portfolio-link" href="/services/digitalmarketing">
                                <div className="portfolio-hover">
                                </div>
                                <img className="img-fluid" src={DigitalMarketingPic} alt="..." />
                                
                                <div className="portfolio-caption">
                                    <div className="portfolio-caption-heading textFormat">Digital Marketing & SEO</div>
                                    <div className="portfolio-caption-subheading text-muted textFormat">Elevate Your Brand, Dominate the Digital Realm.</div>
                                    <p className="textFormat readMore">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item shadow">
                            <a className="portfolio-link" href="/services/webgraphicdesign">
                                <div className="portfolio-hover">
                                </div>
                                <img className="img-fluid" src={WebGraphicDesigningPic} alt="..." />
                                
                                <div className="portfolio-caption">
                                    <div className="portfolio-caption-heading textFormat">Web & Graphic Design</div>
                                    <div className="portfolio-caption-subheading text-muted textFormat">Stunning designs that captivate and convert.</div>
                                    <p className="textFormat readMore">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item shadow">
                            <a className="portfolio-link" href="/services/contentwriting">
                                <div className="portfolio-hover">
                                </div>
                                <img className="img-fluid" src={ContentWritingPic} alt="..." />
                                
                                <div className="portfolio-caption">
                                    <div className="portfolio-caption-heading textFormat">Content Writing</div>
                                    <div className="portfolio-caption-subheading text-muted textFormat">Words that Wow, Stories that Sell.</div>
                                    <p className="textFormat readMore">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item shadow">
                            <a className="portfolio-link" href="/services/ecommerce">
                                <div className="portfolio-hover">
                                </div>
                                    <img className="img-fluid" src={E_commerce} alt="..." />
                                <div className="portfolio-caption">
                                    <div className="portfolio-caption-heading textFormat">E-commerce</div>
                                    <div className="portfolio-caption-subheading text-muted textFormat">Empowering growth with seamless e-commerce.</div>
                                    <p className="textFormat readMore">Read More <span className="textFormat readMore">{'>>'}</span></p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        {/* Templates */}
        <section className="page-section bg-light" id="templates">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase sectionHeadHome">
                        Templates {' '}
                        <span style={{"fontWeight": "lighter"}}>to Elevate Your Business with SEO-Friendly Designs</span>
                    </h2>
                    <h3 className="section-subheading text-muted">Discover our premium, customizable templates designed to elevate your business. Our SEO-friendly website templates, stunning presentation designs, and professional resumes are crafted to impress and perform. With responsive and mobile-friendly options, our templates ensure your content looks great on any device. Affordable and easy to use, our templates save you time and enhance your brand’s image. Browse our collection today and take your business to the next level.</h3>
                </div>
                <div>
                    <div className="row">
                        <div className="">
                            <div className="templet-item-long templet-itemDesktop">
                                <a className="templet-link" target="blank" href="https://demo-storez.serviceareahub.com/">
                                    {/* <div className="templet-hover">
                                    </div> */}
                                    <img className="templetImage shadow" src={Template1} alt="..." />

                                    <p className="templetLongTitel">| Storez</p>
                                    {/* <p className="templetLongText" style={{left: '16%'}}>storez.com</p> */}
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <div className="templet-item">
                                <a className="templet-link" target="blank" href="https://demo-wordpress1.serviceareahub.com/">
                                    {/* <div className="templet-hover"> */}
                                    {/* </div> */}
                                    <img className="img-fluid shadow" src={Template2} alt="..." />

                                    <p className="templetTitel" style={{left: '30%'}}>| Organic Store</p>
                                    {/* <p className="templetText" style={{left: '30%'}}>organicstore.com</p> */}
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <div className="templet-item">
                                <a className="templet-link" target="blank" href="https://demo-foodking.serviceareahub.com/">
                                    {/* <div className="templet-hover">
                                    </div> */}
                                    <img className="img-fluid shadow" src={Template3} alt="..." />
                                
                                    <p className="templetTitel" style={{left: '30%'}}>| Food King</p>
                                    {/* <p className="templetText" style={{left: '30%'}}>foodking.com</p> */}
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <div className="templet-item">
                                <a className="templet-link" target="blank" href="https://demo-office.serviceareahub.com/">
                                    {/* <div className="templet-hover">
                                    </div> */}
                                    <img className="img-fluid shadow" src={Template4} alt="..." />
                                    
                                    <p className="templetTitel" style={{left: '20%'}}>| Office</p>
                                    {/* <p className="templetText" style={{left: '25%'}}>office.com</p> */}
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <div className="templet-item templet-itemMobile">
                            <a className="templet-link" target="blank" href="https://demo-storez.serviceareahub.com/">
                                    {/* <div className="templet-hover">
                                    </div> */}
                                    <img className="img-fluid shadow" src={Template1} alt="..." />
                                    
                                    <p className="templetTitel" style={{left: '20%'}}>| Storez</p>
                                    {/* <p className="templetText" style={{left: '25%'}}>storez.com</p> */}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="templates-link" href="/templates">
                    <p className="textFormat checkMoreTemplets">Click To Viev More <span className="textFormat readMore">{'>>'}</span></p>
                </a>
            </div>
        </section>


        {/* About*/}
        {/* <section className="page-section" id="about">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">About</h2>
                    <h3 className="section-subheading text-muted">
                        ServiceAreaHub is a dynamic company specializing in comprehensive digital solutions, offering expertise in website creation, digital marketing, and content writing. With a keen understanding of the evolving digital landscape, ServiceAreaHub crafts tailored strategies to enhance online presence and drive business growth for its clients.</h3>
                </div>
                <ul className="timeline">
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/1.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4 className="subheading">Our Humble Beginnings</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">From concept to keystrokes, our journey begins with a vision to revolutionize website creation, digital marketing, and content writing under one innovative hub.</p></div>
                        </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/2.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4 className="subheading">An Agency is Born</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">ServiceAreaHub emerges as the nexus of digital prowess, fusing website creation, digital marketing, and content writing. With a vision to redefine industry standards, we unite creativity and strategy to empower businesses in their online endeavors. Our journey begins with a commitment to excellence and innovation, propelling brands towards unparalleled success in the digital realm.</p></div>
                        </div>
                    </li>
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4 className="subheading">Transition to Full Service</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">ServiceAreaHub evolves into a comprehensive solution provider, expanding beyond its roots to offer a full suite of digital services. From website creation and digital marketing to content writing and beyond, our transformation signifies a commitment to meet every client's needs under one roof. With expertise and versatility, we navigate the ever-changing digital landscape, empowering businesses to thrive in the online sphere.</p></div>
                        </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4 className="subheading">Phase Two Expansion</h4>
                            </div>
                            <div className="timeline-body"><p className="text-muted">Scaling our offerings, diversifying expertise. From augmented digital marketing to AI-driven content solutions, our growth reflects a commitment to innovation and client success. Embracing new technologies and strategies, we propel businesses forward in an ever-evolving digital ecosystem.</p></div>
                        </div>
                    </li>
                    <li className="timeline-inverted">
                        <div style={{"background-color": "#eb2639"}} className="timeline-image">
                            <h4>
                                Be Part
                                <br />
                                Of Our
                                <br />
                                Story!
                            </h4>
                        </div>
                    </li>
                </ul>
            </div>
        </section> */}
        

        {/* Clients*/}
        {/* <div className="py-5">

            <div className="container">
                <div className="row align-items-center">
                    <h2 className="section-heading text-uppercase sectionHeadHome">
                        Clients {' '}
                        <span style={{"fontWeight": "lighter"}}>are our valuable partners in digital solutions</span>
                    </h2> */}

                    {/* Client Carousel */}
                    {/* <CCarousel 
                        controls   // controlling arrows 
                        transition="crossfade" 
                        className="clientCarouselContainer"
                        interval={5000} // Set the interval to 5000 milliseconds (5 seconds)
                        ride="carousel" // Enable auto-changing
                    >
                        <CCarouselItem>
                            <div className="clientCarouselContent">
                                <div className="col-md-3 col-sm-6 my-3 clientLogoContainer">
                                    <a href="#!"><img className="clientLogo" src={Client1} alt="..." aria-label="Client1 Logo" /></a>
                                </div>
                                <div className="clientReviewsContainer">
                                    <h5>Review:</h5>
                                    <p>
                                        ServiceAreaHub transformed our digital landscape with SEO-driven web and mobile app development, digital marketing, 
                                        graphic design, content writing, and eCommerce solutions. Their expertise boosted our online presence, streamlined sales, 
                                        and enhanced customer engagement. For unparalleled digital solutions and exceptional results, we highly recommend 
                                        ServiceAreaHub.
                                    </p>
                                </div>
                            </div>
                        </CCarouselItem>
                        <CCarouselItem>
                            <div className="clientCarouselContent">
                                <div className="col-md-3 col-sm-6 my-3 clientLogoContainer">
                                    <a href="#!"><img className="clientLogo" src={Client2} alt="..." aria-label="Client2 Logo" /></a>
                                </div>
                                <div className="clientReviewsContainer">
                                    <h5>Review:</h5>
                                    <p>
                                        Partnering with ServiceAreaHub revolutionized our online presence. Their expertise in SEO-driven web and mobile app development, 
                                        digital marketing, graphic design, content writing, and eCommerce solutions significantly boosted our sales and customer 
                                        engagement. Their comprehensive digital services deliver impactful results, making ServiceAreaHub a highly recommended choice 
                                        for anyone seeking top-tier digital solutions.
                                    </p>
                                </div>
                            </div>
                        </CCarouselItem>
                        <CCarouselItem>
                            <div className="clientCarouselContent">
                                <div className="col-md-3 col-sm-6 my-3 clientLogoContainer">
                                    <a href="#!"><img className="clientLogo" src={Client3} alt="..." aria-label="Client3 Logo" /></a>
                                </div>
                                <div className="clientReviewsContainer">
                                    <h5>Review:</h5>
                                    <p>
                                        Collaborating with ServiceAreaHub revolutionized our digital presence. Their expertise in web and mobile app development, 
                                        digital marketing, graphic design, content writing, and eCommerce solutions streamlined our sales and boosted customer 
                                        engagement. For top-notch digital solutions and transformative results, we highly recommend ServiceAreaHub.
                                    </p>
                                </div>
                            </div>
                        </CCarouselItem>
                        <CCarouselItem>
                            <div className="clientCarouselContent">
                                <div className="col-md-3 col-sm-6 my-3 clientLogoContainer">
                                    <a href="#!"><img className="clientLogo" src={Client4} alt="..." aria-label="Client4 Logo" /></a>
                                </div>
                                <div className="clientReviewsContainer">
                                    <h5>Review:</h5>
                                    <p>
                                        Our organization collaborated with ServiceAreaHub for web and mobile app development, 
                                        digital marketing, graphic design, content writing, and ecommerce solutions. Their 
                                        exceptional services enhanced our online presence, streamlined sales, and improved customer 
                                        engagement. We highly recommend ServiceAreaHub for top-notch digital solutions and transformative 
                                        results.
                                    </p>
                                </div>
                            </div>
                        </CCarouselItem>
                    </CCarousel> */}

                {/* </div>
            </div>
        </div> */}
        

                            
                              
        {/* Footer*/}
        {/* <footer className="footer py-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 text-lg-start">Mail us at: support@serviceareahub.com</div>
                    <div className="col-lg-4 my-3 my-lg-0">
                        <a className="btn btn-dark btn-social mx-2" href="https://www.kooapp.com/profile/serviceareahub" aria-label="Koo"><i className="fab fa-koo"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="https://x.com/ServiceAreaHub?t=qT4PVZhIf6gnIwPxqjgtLg&s=09" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="https://www.client3.com/serviceareahub?mibextid=ZbWKwL" aria-label="Facebook"><i className="fab fa-client3-f"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/serviceareahub/" aria-label="LinkedIn"><i className="fab fa-linkedin-in"></i></a>
                    </div>
                    <div className="col-lg-4 text-lg-end">Copyright &copy; ServiceAreaHub 2024
                        <p className="link-dark text-decoration-none me-3">Mail us at:</p>
                        <p className="link-dark text-decoration-none me-3">Mail us at:    support@serviceareahub.com</p>
                        <a className="link-dark text-decoration-none me-3" href="#!">Privacy Policy</a>
                        <a className="link-dark text-decoration-none" href="#!">Terms of Use</a>
                    </div>
                </div>
            </div>
        </footer> */}
       
        </div>
    )
};

export default HomePage;