import React, { useState } from "react";
import emailjs from "emailjs-com";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import PropTypes from 'prop-types';
import Linkedin from "../assets/linkedin.png";
import X from "../assets/X.png";
import FB from "../assets/fb.png";
import Instagram from "../assets/instagram.png";
import './footerstyle.css';

// Add all brand icons to the library
library.add(fab);

const initialState = {
  name: "",
  email: "",
  message: "",
};

const Footer = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [msg, setMsg] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Replace with your own Service ID, Template ID and Public Key from your EmailJS account 
    emailjs
      .sendForm("service_g09lknl", "template_mewam2g", e.target, "uAKq8j0r1JXi3WS99")
      .then(
        (result) => {
          // console.log(result.text);
          if (result.text === "OK") {
            setMsg("Message sent successfully. We will revert within 2 working days.");
            clearState();
          } else {
            setMsg("Sorry, something went wrong.");
          }
        },
        (error) => {
          console.error("Failed to send email:", error.text);
          setMsg("Sorry, something went wrong.");
        }
      );
  };


  return (
    <div id="footer">
      <div id="contact">
        <div className="footerContainer">
          <div className="col-md-8 footerLeft">
            <div className="row">
              <div className="section-title">
                <h2>Tell us about your need</h2>
                <p>We'll build it for you</p>
              </div>
              <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                        value={name}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        value={email}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="footerMsgBtn">
                  SEND MESSAGE
                </button>
                <p style={{ textAlign: "center", fontSize: "22px" }}><i>{msg}</i></p>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info footerRight">
            <div className="rightContainer">
              {/* <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address:
                  </span>{" "}
                  {props.data ? props.data.address : "104 Salt Lake, Sector V, Kolkata, WB, Republic of India"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone:
                  </span>{" "}
                  {props.data ? props.data.phone : "+91 98765 43210"}
                </p>
              </div> */}
              {/* <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email:
                  </span>{" "}
                  {props.data ? props.data.email : "support@serviceareahub.com"}
                </p>
              </div> */}
              {/* <h4>We are available on:</h4> */}
              <div className="socialMediaDiv">
                <h4>Follow us at:</h4>
                <div className="contact-item">
                  <a href={props.data ? props.data.linkedin : "https://www.linkedin.com/company/serviceareahub/"}>
                  <img src={Linkedin} alt="" className="socialmediaLogo"/>
                  </a>
                  &nbsp;&nbsp;
                  <a href={props.data ? props.data.twitter : "https://x.com/serviceareahub"}>
                    <img src={X} alt="" className="socialmediaLogo"/>
                  </a>
                  &nbsp;&nbsp;
                  <a href={props.data ? props.data.facebook : "https://www.facebook.com/serviceareahub/"}>
                    <img src={FB} alt="" className="socialmediaLogo"/>
                  </a>
                  &nbsp;&nbsp;
                  {/* <a href={props.data ? props.data.instagram : "https://www.instagram.com/serviceareahub/"}> */}
                  <a href={props.data ? props.data.instagram : "https://www.instagram.com/serviceareahub?igsh=MWNmN3JwNzV0aDUzNw=="}>
                    <img src={Instagram} alt="" className="socialmediaLogo"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="footerBottom">
          <a style={{ color: "whitesmoke" }} className="text-decoration-none me-3" href="/careers">Careers</a>
          <a style={{ color: "whitesmoke" }} className="text-decoration-none me-3" href="/privacy-policy">Privacy Policy</a>
          {/* <a style={{ color: "whitesmoke" }} className="text-decoration-none me-3" href="#!">Payment</a> */}
          {/* <a style={{ color: "whitesmoke" }} className="text-decoration-none" href="/terms-of-use">Terms of Use</a> */}
          <p>Copyright &copy; ServiceAreaHub 2024</p>
        </div>
      </div>
    </div>
  );
};

// Add this at the end of your file before exporting the component

Footer.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    linkedin: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
  }),
};

export default Footer;
